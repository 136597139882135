
import { config } from "../../helpers";
import {
    SUPER_ADMIN, NETWORK_MANAGER, ACCOUNT_MANAGER, 
    BIG_MOBILE_SALESPERSON, AFFILIATE, SALES_DIRECTOR, C_LEVEL,
    CREATIVE_MANAGER, ADMIN, ESG_TEAM as ESG,
    COMMERCIAL_MANAGER,
    INTERNAL_USERS

} from "../../helpers/roles"
// import { gotoPrevOfssetEmission } from "../utilities/customRoutes";


const ALL_ADMINS = [SUPER_ADMIN, ADMIN];
const ALL_SALES = [BIG_MOBILE_SALESPERSON, AFFILIATE, SALES_DIRECTOR, C_LEVEL];
const ESG_TEAM = [CREATIVE_MANAGER, ESG]
const ALL_USERS = [...ALL_ADMINS, ...ALL_SALES, ACCOUNT_MANAGER, NETWORK_MANAGER]

export const bud_enterprise_items = [
    {
        id: 1077,
        elId: "rewards",
        link: "rewards",
        isLinkClickable: true,
        name: "My Rewards",
        color: "pink",
        model: [2],
        icon: "ad_group",
        access: [COMMERCIAL_MANAGER]
    },
    {
        id: 1177,
        elId: "savings",
        link: "savings",
        isLinkClickable: true,
        name: "My Savings",
        color: "pink",
        model: [3],
        icon: "ad_group",
        access: [COMMERCIAL_MANAGER]
    },
    {
        id: 1277,
        elId: "margin",
        link: "margin",
        isLinkClickable: true,
        name: "My Margin",
        color: "pink",
        model: [3],
        icon: "ad_group",
        access:  [COMMERCIAL_MANAGER]
    },
    {
        id: 1188,
        elId: "fees",
        link: "fees",
        isLinkClickable: true,
        name: "Platform Fees",
        model: [1],
        color: "pink",
        icon: "ad_group",
        access:  [COMMERCIAL_MANAGER]
    },
    {
        id: 1377,
        elId: "spend",
        link: "spend",
        isLinkClickable: true,
        name: "Spend Review",
        color: "pink",
        model: [1, 2, 3],
        icon: "ad_group",
        access:  [COMMERCIAL_MANAGER]
    }
]

export const items =
    [
        {
            id: 100,
            elId: "networks",
            link: "networks",
            isLinkClickable: true,
            name: "Networks",
            color: "pink",
            icon: "apartment",
            access: [...ALL_ADMINS]
        },
        {
            id: 101,
            elId: "accounts",
            link: "accounts",
            isLinkClickable: true,
            name: "Accounts",
            color: "pink",
            icon: "group",
            access: [...ALL_ADMINS, NETWORK_MANAGER]
        },
        {
            id: 116,
            elId: "ccm",
            link: "contextual/create-deal",
            isLinkClickable: true,
            name: "Start New",
            color: "pink",
            icon: "add_to_queue",
            access: [...ALL_USERS],
        },
        {
            id: 118,
            elId: "my-creatives",
            link: "my-creatives",
            isLinkClickable: true,
            name: "My Creatives",
            color: "pink",
            icon: "ad",
            access: [...ALL_ADMINS, NETWORK_MANAGER, ACCOUNT_MANAGER],
        },
        {
            id: 119,
            elId: "my-creatives-demo",
            link: "my-creatives-demo",
            isLinkClickable: true,
            name: "My Creatives",
            color: "pink",
            icon: "ad",
            access: [...ALL_SALES],
        },
        {
            id: 104,
            elId: "my-deals",
            link: "my-deals",
            isLinkClickable: true,
            name: "My Deals",
            color: "pink",
            icon: "ad_group",
            access: [...ALL_USERS],
        },        
        (config.isBudEnterprise ? 
            {
                id: 115,
                elId: "commercials",
                link: "commercials",
                isLinkClickable: true,
                name: "Commercials",
                color: "pink",
                icon: "ad_group",
                access: [...INTERNAL_USERS, COMMERCIAL_MANAGER]
            }
            : null),
         {
            id: 107,
            elId: "emissions-reporting",
            link: "emissions-reporting",
            isLinkClickable: false,
            name: "Emissions Reporting",
            color: "green",
            icon: "eco",
            access: [...ALL_USERS, ...ESG_TEAM],
            image: "/images/green-project/wind.gif",
            submenu:
                [
                    // {
                    //     id: 1079,
                    //     elId: "offset-emissions",
                    //     link: "offset-emissions",
                    //     name: "Offset Emissions",
                    //     goto: gotoPrevOfssetEmission,
                    //     access: [...ALL_USERS],
                    // },
                    // {
                    //     id: 1071,
                    //     elId: "measurement",
                    //     link: "measurement",
                    //     name: "Measurement",
                    //     access: [...ALL_SALES, ...ALL_ADMINS, ...ESG_TEAM],
                    // },
                    // {
                    //     id: 1072,
                    //     elId: "collaborate",
                    //     link: "collaborate",
                    //     name: "Collaborate",
                    //     access: ["ALL"],
                    // },
                    // {
                    //     id: 1074,
                    //     elId: "status",
                    //     link: "status",
                    //     name: "My Status",
                    //     access: [...ALL_SALES, ...ALL_ADMINS, ...ESG_TEAM],
                    // },
                    {
                        id: 1090,
                        elId: "benchmark",
                        link: "benchmark",
                        name: "Scope 3 Benchmark",
                        access: [...ALL_ADMINS],
                    },
                    {
                        id: 1077,
                        elId: "scope-3-reporting",
                        link: "scope-3-reporting",
                        name: "Scope 3 Reporting",
                        access: [...ALL_ADMINS, NETWORK_MANAGER, ACCOUNT_MANAGER, COMMERCIAL_MANAGER],
                    },
                    {
                        id: 1078,
                        elId: "scope3-reporting",
                        link: "scope3-reporting",
                        name: "Scope 3 Reporting",
                        access: [...ALL_SALES, ...ESG_TEAM],
                    },
                    // {
                    //     id: 1075,
                    //     elId: "transparency-policy",
                    //     link: "transparency-policy",
                    //     name: "Transparency Policy",
                    //     access: [...ALL_SALES, ...ALL_ADMINS, ...ESG_TEAM],
                    // },
                    // {
                    //     id: 1076,
                    //     elId: "settings",
                    //     link: "settings",
                    //     name: "Settings",
                    //     access: [...ALL_SALES, ...ALL_ADMINS, ...ESG_TEAM],
                    // },
                    {
                        id: 10711,
                        elId: "emissions-reporting",
                        link: "emissions-reporting",
                        name: "Emissions Reporting",
                        access: [SUPER_ADMIN],
                    },
                ]
        },
        // {
        //     id: 114,
        //     elId: "performance-programs",
        //     link: "performance-programs",
        //     isLinkClickable: true,
        //     name: "Performance Programs",
        //     color: "pink",
        //     icon: "Monitoring",
        //     access: [...ALL_USERS],
        //     accessCheck: "performance",
        // },
        {
            id: 112,
            elId: "optimisation",
            link: "optimisation",
            isLinkClickable: false,
            name: "Optimisation",
            color: "pink",
            icon: "fact_check",
            access: [...ALL_ADMINS],
            submenu:
                [
                    {
                        id: 1121,
                        elId: "amplified-intelligence",
                        link: "amplified-intelligence",
                        name: "Amplified Intelligence",
                        access: [...ALL_ADMINS],
                    },
                    {
                        id: 1122,
                        elId: "creative",
                        link: "creative",
                        name: "Creative",
                        access: [...ALL_ADMINS],
                    },
                    // {
                    //     id: 1123,
                    //     elId: "lumen",
                    //     link: "lumen",
                    //     name: "Lumen",
                    //     access: [...ALL_ADMINS],
                    // }
                ]
        },
        {
            id: 105,
            elId: "services",
            link: "services",
            isLinkClickable: false,
            name: "Orders",
            color: "pink",
            icon: "view_list",
            access: [...ALL_ADMINS],
            submenu:
                [
                    {
                        id: 1051,
                        elId: "ad-builder-bm",
                        link: "ad-builder-bm",
                        name: "Creative Builder",
                        access: [...ALL_ADMINS],
                    },
                    {
                        id: 1052,
                        elId: "creative-requests",
                        link: "creative-requests",
                        name: "Jobs Pending",
                        access: [...ALL_ADMINS],
                    }
                ]
        },
       
        {
            id: 106,
            elId: "settings",
            link: "settings",
            isLinkClickable: false,
            name: "Manage",
            color: "pink",
            icon: "settings",
            access: [SUPER_ADMIN, ADMIN],
            submenu:
                [
                    {
                        id: 1061,
                        elId: "benchmarks",
                        link: "benchmarks",
                        name: "Benchmarks",
                        access: [SUPER_ADMIN],
                    },
                    {
                        id: 1062,
                        elId: "dimensions",
                        link: "dimensions",
                        name: "Dimensions",
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    {
                        id: 1064,
                        elId: "formats",
                        link: "formats",
                        name: "Formats",
                        access: [SUPER_ADMIN, ADMIN],
                    },                    
                    {
                        id: 1068,
                        elId: "custom-format",
                        link: "custom-format",
                        name: "Custom Formats",
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    {
                        id: 1065,
                        elId: "all-users",
                        link: "all-users",
                        name: "Users",
                        access: [SUPER_ADMIN],
                    },
                    // {
                    //     id: 1066,
                    //     elId: "user-roles",
                    //     link: "user-roles",
                    //     name: "User Roles",
                    //     access: [SUPER_ADMIN],
                    // },
                    {
                        id: 1067,
                        elId: "faqs",
                        link: "faqs",
                        name: "FAQS",
                        access: [SUPER_ADMIN],
                    },
                    {
                        id: 1069,
                        elId: "daily-test-results",
                        link: "daily-test-results",
                        name: "Daily Test Results",
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    // {
                    //     id: 1070,
                    //     elId: "dominos-basket-size",
                    //     link: "dominos-basket-size",
                    //     name: "Domino's Basket Size",
                    //     access: [SUPER_ADMIN],
                    // },
                    // {
                    //     id: 1061,
                    //     elId: "dominos-master-campaign",
                    //     link: "dominos-master-campaign",
                    //     name: "Domino's Master Campaign",
                    //     access: [SUPER_ADMIN],
                    // },
                ]
        },
        {
            id: 113,
            elId: "data-provider",
            link: "data-provider",
            isLinkClickable: false,
            name: "Data Provider",
            color: "pink",
            icon: "database",
            access: [...ALL_ADMINS],
            submenu:
                [
                    {
                        id: 1131,
                        elId: "segment-category",
                        link: "segment-category",
                        name: "Segment Category",
                        access: [...ALL_ADMINS],
                    },
                    {
                        id: 1132,
                        elId: "segment-pricing",
                        link: "segment-pricing",
                        name: "Segment Sell Pricing",
                        access: [...ALL_ADMINS],
                    }
                ]
        },
        {
            id: 108,
            elId: "contextual",
            link: "contextual",
            isLinkClickable: false,
            name: "Contextual",
            color: "pink",
            icon: "folder",
            access: [SUPER_ADMIN, ADMIN],
            submenu:
                [
                    /* {
                        id: 1081,
                        elId: "curated-media",
                        link: "curated-media",
                        name: "Curated Media",
                        isLinkClickable: true,
                        access: [SUPER_ADMIN, ADMIN],
                    }, */
                    {
                        id: 1088,
                        elId: "format_category_tier",
                        link: "format_category_tier",
                        name: "Format Category Tier",
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    {
                        id: 1084,
                        elId: "format_category",
                        link: "format_category",
                        name: "Format Category",
                        isLinkClickable: true,
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    {
                        id: 1087,
                        elId: "format_carousel",
                        link: "format_carousel",
                        name: "Format Carousel",
                        access: [SUPER_ADMIN, ADMIN],
                    },              
                    {
                        id: 10813,
                        elId: "bespoke-category",
                        link: "bespoke-category",
                        name: "Bespoke Category",
                        access: [SUPER_ADMIN, ADMIN],
                    },              
                    {
                        id: 10812,
                        elId: "master-sitelist",
                        link: "master-sitelist",
                        name: "Master Sitelist",
                        isLinkClickable: true,
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    // {
                    //     id: 1082,
                    //     elId: "category",
                    //     link: "category",
                    //     name: "Category",
                    //     isLinkClickable: true,
                    //     access: [SUPER_ADMIN, ADMIN],
                    // },                    
                    // {
                    //     id: 1085,
                    //     elId: "prebuilt",
                    //     link: "prebuilt",
                    //     name: "Pre-built",
                    //     isLinkClickable: true,
                    //     access: [SUPER_ADMIN, ADMIN],
                    // },                    
                    {
                        id: 10811,
                        elId: "prebuilt",
                        link: "prebuilt",
                        name: "Pre-built",
                        isLinkClickable: true,
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    // {
                    //     id: 1089,
                    //     elId: "bespoke",
                    //     link: "bespoke",
                    //     name: "Bespoke",
                    //     isLinkClickable: true,
                    //     access: [SUPER_ADMIN, ADMIN],
                    // },
                    {
                        id: 1086,
                        elId: "deal_briefing",
                        link: "deal_briefing",
                        name: "Deal Briefing",
                        isLinkClickable: true,
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    // {
                    //     id: 1083,
                    //     elId: "deal_token_reference",
                    //     link: "deal_token_reference",
                    //     name: "Deal Token Reference",
                    //     isLinkClickable: true,
                    //     access: [SUPER_ADMIN, ADMIN],
                    // },
                    {
                        id: 1081,
                        elId: "addons",
                        link: "addons",
                        name: "Addons",
                        access: [SUPER_ADMIN, ADMIN],
                    },
                    // {
                    //     id: 1089,
                    //     elId: "curation_fee",
                    //     link: "curation_fee",
                    //     name: "Curation Fee",
                    //     access: [SUPER_ADMIN, ADMIN],
                    // },
                   /*  {
                        id: 1083,
                        elId: "publisher",
                        link: "publisher",
                        name: "Publisher",
                        isLinkClickable: true,
                        access: [SUPER_ADMIN, ADMIN],
                    }, */
                    
                ]
        },
        
        
        {
            id: 109,
            elId: "business",
            link: "business",
            isLinkClickable: false,
            name: "Business",
            color: "pink",
            icon: "analytics",
            access: [SUPER_ADMIN, ADMIN],
            submenu:
                [
                    {
                        id: 1091,
                        elId: "user-analytics-v2",
                        link: "user-analytics-v2",
                        isLinkClickable: true,
                        name: "User Analytics",
                        color: "pink",
                        access: [SUPER_ADMIN]
                    },
                    {
                        id: 1092,
                        elId: "deal-report",
                        link: "deal-report",
                        isLinkClickable: true,
                        name: "Team Deal IDs",
                        color: "pink",
                        access: [...ALL_ADMINS]
                    },
                    {
                        id: 1093,
                        elId: "sales-report",
                        link: "sales-report",
                        isLinkClickable: true,
                        name: "My Hive Diligence",
                        color: "pink",
                        access: [...ALL_ADMINS]
                    },
                    {
                        id: 1094,
                        elId: "hive-activity",
                        link: "hive-activity",
                        isLinkClickable: true,
                        name: "My Hive Activity",
                        color: "pink",
                        access: [...ALL_ADMINS]
                    },
                    {
                        id: 1095,
                        elId: "deal-monitoring",
                        link: "deal-monitoring",
                        isLinkClickable: true,
                        name: "Deal Monitoring Summary",
                        color: "pink",
                        access: [...ALL_ADMINS]
                    },
                    {
                        id: 1098,
                        elId: "foreign_exchange_rate",
                        link: "foreign_exchange_rate",
                        isLinkClickable: true,
                        name: "Forex",
                        color: "pink",
                        access: [...ALL_ADMINS]
                    },
                    /* {
                        id: 1096,
                        elId: "target",
                        link: "target",
                        isLinkClickable: true,
                        name: "Target",
                        color: "pink",
                        access: [...ALL_ADMINS]
                    },
                    {
                        id: 1097,
                        elId: "product-margin",
                        link: "product-margin",
                        isLinkClickable: true,
                        name: "Product Margin",
                        color: "pink",
                        access: [...ALL_ADMINS]
                    },
                    {
                        id: 1099,
                        elId: "performance_subscription",
                        link: "performance_subscription",
                        isLinkClickable: true,
                        name: "Performance Subscription",
                        color: "pink",
                        access: [SUPER_ADMIN],
                    }, */
                ]
        },
        {
            id: 111,
            elId: "billing",
            link: "billing",
            isLinkClickable: false,
            name: "Billing",
            color: "pink",
            icon: "receipt_long",
            access: [SUPER_ADMIN, ADMIN],
            submenu:
                [
                    {
                        id: 1195,
                        elId: "nexd-billing",
                        link: "nexd-billing",
                        name: "NEXD Billing",
                        access: [SUPER_ADMIN],
                    },
                    {
                        id: 1196,
                        elId: "airtory-billing",
                        link: "airtory-billing",
                        name: "AIRTORY Billing",
                        access: [SUPER_ADMIN],
                    },
                ]
        },
        {
            id: 117,
            link: "help",
            isLinkClickable: true,
            name: 'Help',
            color: "pink",
            icon: "help",
            access: [...ALL_USERS, ESG_TEAM],
        },
    ]; 