import Yup from '../../form-helpers/Yup';
import { numberWithCommas } from '../../utilities/customFunctions';
import { SUPPORT_OPTIONS} from '../helper';
import { differenceInCalendarDays } from 'date-fns';



export const DiscountSchema = Yup.object().shape({
    prem_spend: Yup.string().required('Required'),
    adv_spend: Yup.string().required('Required'),
    monthly_spend_prem: Yup.string().required('Required'),
    monthly_spend_adv: Yup.string().required('Required')
})

export const getMainSchema = () => Yup.object().shape({
    company_name: Yup.string().required('Required'),
    startDate: Yup.string().required('Required'), 
    endDate: Yup.mixed().test(
        'end-date check',
        `The Agreement Period needs to be at least 90 days.`,
        function () {
            const { startDate, endDate } = this.parent
            return differenceInCalendarDays(new Date(endDate), new Date(startDate)) >= 90;
        }
    ),
    email: Yup.mixed().when('receive_enabled', (receive_enabled, schema) => {
        if (receive_enabled) {
            return schema.required("Required")
        }
        return;
    }),
    commercials_approver:  Yup.string().required('Required'),
    bigads_approved_date: Yup.string().required('Required')

})
export const Schema = (totalSpendSumCheck) => Yup.object().shape({
    premium_spend_2: Yup.string().required('Required'),
    advanced_spend_2: Yup.string().required('Required'),
    monthly_spend_premium: Yup.string().required('Required'),
    monthly_spend_advanced: Yup.string().required('Required'),

    sumCheck: Yup.mixed().test(
        'sum-check',
        `The combined sum of monthly spend must be at least ${numberWithCommas(totalSpendSumCheck)}.`,
        function () {
            const { monthly_spend_premium, monthly_spend_advanced } = this.parent;

            // If either field is missing or invalid, skip combined check
            if (!monthly_spend_premium || !monthly_spend_advanced) return true;

            const num1 = parseFloat(monthly_spend_premium);
            const num2 = parseFloat(monthly_spend_advanced);

            if (isNaN(num1) || isNaN(num2)) {
                return this.createError({ message: 'Both fields must be valid numbers.' });
            }

            // Fail the test if the sum is less than 50,000
            return (num1 + num2) >= totalSpendSumCheck;
        }
    )

})

export const getCalcModalInitialValues = (pm2, ad2) => {
    return {
        premium_spend_2: pm2, advanced_spend_2: ad2, monthly_spend_premium: 0,
        monthly_spend_advanced: 0,
        usage_saas_fee: 0, usage_saas_fee_percentage: 0, paid_to_client: 0,
        bigads_saas_fee_percentage: 0, bigads_saas_fee: 0, annual_fee: 0, annual_earnings: 0
    }

}

export const getRefundCalcModal = (prem_base_set, adv_base_set, prem_spend_set ,adv_spend_set, isManagedService ) => {
    return {
        prem_spend: prem_base_set, adv_spend: adv_base_set, monthly_spend_prem: 0,
        monthly_spend_adv: 0,

        adjusted_cpm_prem: 0, adjusted_cpm_adv: 0,


        proposed_sell_cpm_prem: prem_spend_set, proposed_sell_cpm_adv: adv_spend_set,
        proposed_sell_cpm_percentage_prem: 0, proposed_sell_cpm_percentage_adv: 0,

        refund_percentage_prem: 0, refund_percentage_adv: 0,
        refund_cpm_prem: 0, refund_cpm_adv: 0,
        monthly_refund_prem: 0, monthly_refund_adv: 0,
        total_monthly_refund_p: 0, total_monthly_refund: 0,
        total_annualised_refund: 0,
        managed_service_refund: isManagedService
    }

}


export const getDiscountCalcModal = (prem_base_set, prem_spend_set, adv_base_set,adv_spend_set, isManagedService) => {
    return {
        prem_spend: prem_base_set, adv_spend: adv_base_set, monthly_spend_prem: 0,
        monthly_spend_adv: 0,
        imp_purchased_prem: 0, imp_purchased_adv: 0,
        vol_discount_percentage_prem: 0, vol_discount_percentage_adv: 0,
        adjusted_cpm_prem: 0, adjusted_cpm_adv: 0,
        imp_increase_prem: 0, imp_increase_adv: 0,
        adjusted_imp_prem: 0, adjusted_imp_adv: 0,
        proposed_sell_cpm_prem: prem_spend_set, proposed_sell_cpm_adv: adv_spend_set,
        proposed_sell_cpm_percentage_prem: 0, proposed_sell_cpm_percentage_adv: 0,
        margin_prem: 0, margin_adv: 0,
        total_sell_prem: 0, total_sell_adv: 0,
        monthly_margin_prem: 0, monthly_margin_adv: 0,
        total_monthly_margin: 0, total_annualised_margin: 0,
        managed_service_discount: isManagedService
    }
}


export const getInitVals = (isAU, model, PREMIUM_CPM_DISCOUNT_ALL, ADVANCED_CPM_DISCOUNT_ALL, network, PREMIUM_CPM, approver, bigApprover, edit = false) => {
    const today = new Date();
    const ninetyDaysFromNow = new Date();
    ninetyDaysFromNow.setDate(today.getDate() + 90);
    let dividen = 22;

    let obj = {
       
        
        premium_spend: model === 1 ? PREMIUM_CPM[0] : PREMIUM_CPM_DISCOUNT_ALL[0],
        advanced_spend: model === 1 ? PREMIUM_CPM[isAU ? 4 : 2] : ADVANCED_CPM_DISCOUNT_ALL[0],
        
        premium_spend_set: model === 2 ? PREMIUM_CPM_DISCOUNT_ALL[0] : null,
        advanced_spend_set: model === 2 ? ADVANCED_CPM_DISCOUNT_ALL[0] : null,
        
        support_options: SUPPORT_OPTIONS[1],
    
        percentage_refund:"",
        percentage_discount: "",
          
        set_adjusted_cpm_prem: "",
        set_adjusted_cpm_adv: "",
    }
    if (!edit){
        obj = {
            ...obj, 
            startDate: today, 
            endDate: ninetyDaysFromNow,
            ssp_publisher_enabled: true, 
            criteo_set_enabled: true,
            monthly_spend: "",
            receive_enabled: false,
            company_name: network,
            commercials_approved_date: new Date(),
            commercials_approver: approver,
            bigads_approver: bigApprover,
            percentage: "",
            bigads_approved_date: new Date(),
            first_spend_date: today,
            second_spend_date: new Date().setDate(today.getDate() + dividen),
            third_spend_date: new Date().setDate(today.getDate() + dividen*2),
            fourth_spend_date: new Date().setDate(today.getDate() + dividen*3),
            managed_service: false
        }
    }
    return obj;
}