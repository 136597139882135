export const CuratedAdConstants = {

    ADD_SELECTED_MEDIA_DETAILS_SUCCESS: 'ADD_SELECTED_MEDIA_DETAILS_SUCCESS',
    ADD_SELECTED_MEDIA_DETAILS_ERROR: 'ADD_SELECTED_MEDIA_DETAILS_ERROR',

    UPDATE_SELECTED_MEDIA_DETAILS_SUCCESS: 'UPDATE_SELECTED_MEDIA_DETAILS_SUCCESS',
    UPDATE_SELECTED_MEDIA_DETAILS_ERROR: 'UPDATE_SELECTED_MEDIA_DETAILS_ERROR',

    GET_CURATED_MEDIA_AD_BY_ID: 'GET_CURATED_MEDIA_AD_BY_ID',
    GET_CURATED_MEDIA_AD_BY_ID_ERROR: 'GET_CURATED_MEDIA_AD_BY_ID_ERROR',

    SELECTED_MEDIA_LOADING: 'SELECTED_MEDIA_LOADING',
    SELECTED_MEDIA_EDIT_LOADING: 'SELECTED_MEDIA_EDIT_LOADING',
    EMPTY_CURATED_MEDIA_AD_DATA: 'EMPTY_CURATED_MEDIA_AD_DATA',

    RENAME_CURATED_DEAL: 'RENAME_CURATED_DEAL',
    RENAME_CURATED_DEAL_ERROR: 'RENAME_CURATED_DEAL_ERROR',
    RENAME_LOADING_STATUS: 'RENAME_LOADING_STATUS',

    CHECK_IF_DEAL_EXISTS: 'CHECK_IF_DEAL_EXISTS',
    CHECK_IF_DEAL_EXISTS_ERROR: 'CHECK_IF_DEAL_EXISTS_ERROR',
    CHECK_IF_DEAL_EXISTS_LOADING: 'CHECK_IF_DEAL_EXISTS_LOADING',

    REQUEST_EXPORT_METHOD: 'REQUEST_EXPORT_METHOD',
    REQUEST_EXPORT_METHOD_ERROR: 'REQUEST_EXPORT_METHOD_ERROR',
    REQUEST_EXPORT_METHOD_LOADING: 'REQUEST_EXPORT_METHOD_LOADING',

    BULK_TRANSFER_CREATIVE_SUCCESS: 'BULK_TRANSFER_CREATIVE_SUCCESS',
    BULK_TRANSFER_CREATIVE_ERROR: 'BULK_TRANSFER_CREATIVE_ERROR',
    BULK_TRANSFER_CREATIVE_LOADING: 'BULK_TRANSFER_CREATIVE_LOADING',

    TRANSFER_CREATIVE_SUCCESS: 'TRANSFER_CREATIVE_SUCCESS',
    TRANSFER_CREATIVE_ERROR: 'TRANSFER_CREATIVE_ERROR',
    TRANSFER_CREATIVE_LOADING: 'TRANSFER_CREATIVE_LOADING',

    TRANSFER_DEAL_SUCCESS: 'TRANSFER_DEAL_SUCCESS',
    TRANSFER_DEAL_ERROR: 'TRANSFER_DEAL_ERROR',
    TRANSFER_DEAL_LOADING: 'TRANSFER_DEAL_LOADING',
    
    SHARE_DEAL_SUCCESS: 'SHARE_DEAL_SUCCESS',
    SHARE_DEAL_ERROR: 'SHARE_DEAL_ERROR',
    SHARE_DEAL_LOADING: 'SHARE_DEAL_LOADING',
    
    GET_PERFORMANCE_FEEDBACK_BY_ID: 'GET_PERFORMANCE_FEEDBACK_BY_ID',
    GET_PERFORMANCE_FEEDBACK_BY_ID_ERROR: 'GET_PERFORMANCE_FEEDBACK_BY_ID_ERROR',
    EMPTY_PERFORMANCE_FEEDBACK: 'EMPTY_PERFORMANCE_FEEDBACK',
    PERFORMANCE_FEEDBACK_LOADING: 'PERFORMANCE_FEEDBACK_LOADING',

    SUBMIT_FEEDBACK_SUCCESS: 'SUBMIT_FEEDBACK_SUCCESS',
    SUBMIT_FEEDBACK_ERROR: 'SUBMIT_FEEDBACK_ERROR',
    SUBMIT_FEEDBACK_LOADING: 'SUBMIT_FEEDBACK_LOADING',

    NEW_KRITIKOS: 'NEW_KRITIKOS',
    CALCULATE_KRITIKOS: 'CALCULATE_KRITIKOS',
    
    AUTOMATE_FEEDBACK_SUCCESS: 'AUTOMATE_FEEDBACK_SUCCESS',
    AUTOMATE_FEEDBACK_ERROR: 'AUTOMATE_FEEDBACK_ERROR',
    AUTOMATE_FEEDBACK_LOADING: 'AUTOMATE_FEEDBACK_LOADING'
};
