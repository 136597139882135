import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../utilities/BreadCrumb'
import "../../creative/report/report.scss"
import BuddyChart from '../../creative/report/reports/chart';
import { getModelNum } from '../helper';
import SpendingSummary from './card';
import { connect } from 'react-redux';

function SpendReviews(props) {


    let model = getModelNum(props.user?.commercial_model)

    //console.log(modelName, country)
    
    useEffect(() => {
        document.getElementById("root").classList.add("root-light");

        return () => {
            document.getElementById("root").classList.remove("root-light");
        }
        // eslint-disable-next-line
    }, [props])

    const renderChart = () => {
        return <div className="chart-container">
            {/* <strong><h3>Spend Review</h3> </strong> */}
            <p className="bb-sub-header-txt mb-4">As defined in the commercials dated <mark>dd/MM/yyyy</mark>, this Spend Review provides
                a comparison between the predicted and actual spend. The variance will be aligned in the next review, scheduled for <mark>dd/MM/yyyy</mark></p>

            <div className="d-flex flex-row">
            
            <BuddyChart type="mscolumn2d"
                width="50%"
                height="400"
                dataFormat="JSON"
                // dataSource={createDataReview(modelName, country)} 
                dataSource={[]}
                />
            <SpendingSummary country={"AU"} />
            </div>

        </div>
    }
    return (
        <>
            <BreadCrumb checkDeep={false} name={"Spend Review"} />
            <div className="d-flex flex-column justify-content-center m-3  ">

                <div className="d-flex flex-column mb-3 report-container">
                    {renderChart()}                    
                </div>
            </div>
        </>
    )
}


const mapStateToProps = state => ({

    user: state.user


});
const mapDispatchToProps = {


};
export default (connect(mapStateToProps, mapDispatchToProps)(SpendReviews));