import React, { useState } from 'react';
import Modal from "react-modal";
import { BuddyTable } from '../../../table-helpers/BuddyTable-2';
//import Slider from '../../../utilities/react-slider';
const data = [
    {
      "Publisher Domain": "Publisher 1",
      "Impressions": "778,212",
      "Supply Media Cost": "2,408.64"
    },
    {
      "Publisher Domain": "Publisher 2",
      "Impressions": "650,192",
      "Supply Media Cost": "1,907.51"
    },
    {
      "Publisher Domain": "Publisher 3",
      "Impressions": "458,821",
      "Supply Media Cost": "1,376.46"
    },
    {
      "Publisher Domain": "Publisher 4",
      "Impressions": "351,675",
      "Supply Media Cost": "1,213.29"
    },
    {
      "Publisher Domain": "Publisher 5",
      "Impressions": "301,314",
      "Supply Media Cost": 979.59
    },
    {
      "Publisher Domain": "Publisher 6",
      "Impressions": "271,110",
      "Supply Media Cost": 813.33
    },
    {
      "Publisher Domain": "Publisher 7",
      "Impressions": "195,266",
      "Supply Media Cost": 661.54
    }
  ]

function CriteoModal(props) {
    
    const [isModalOpen, setIsModalOpen] = useState(false)
    //const [slider, setSlider] = useState(0);

    const closeModal = () => { setIsModalOpen(false) }

    const renderIframeModal = () => {
        return (
            <Modal
            isOpen={isModalOpen ? true : false}
                            onRequestClose={(e) => closeModal()}
                            overlayClassName="bb-modal-overlay"
                            className="bb-modal-container h-470"
                            ariaHideApp={false}
                            bodyOpenClassName="overflow-hidden"
                        >
                           
                                <div key={0} onClick={event => event.stopPropagation()}>
                                    <div className="d-flex flex-row justify-content-between mb-3 modal-header bb-bg_primary">
                                        <h4 className="text-white">Sample Criteo Publisher Utilisation Report</h4>
                                        <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-white" onClick={(e) => closeModal()}>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-wrap justify-content-start' >
                                        
                                    <div className="col-auto d-flex align-items-center flex-column ">
                                         
                                        <p className="m-0 p-2 align-self-start bb-font_14px bb-bg_primary-0 w-100">01/06/2025 – 30/06/2025</p>
                                        <BuddyTable
                                                        defaultValue={1}
                                                        pagesize={1}
                                                        tableSorting={false}
                                                        showPagination={false}
                                                        showRowtable={false}
                                                        emptyMessage="No Data Found."
                                                        paginateClassName="col-12"
                                                        skeletonloading={false}
                                                        total={1}
                                                        loading={false}
                                                        currentPage={1}
                                                        data={data}
                                                        className="cursor-pointer col-12 buddy_light p-0"
                                                        dataFor=""
                                                        columns={[                                                            
                                                            {
                                                                Header: "Publisher Domain",
                                                                accessor: "Publisher Domain",
                                                                className: "text-center",
                                                                minWidth: 100,
                                                            },
                                                            {
                                                                Header: "Impressions",
                                                                accessor: "Impressions" ,
                                                                className: "text-center",
                                                                minWidth: 70,                                                               
                                        
                                                            },
                                                            {
                                                                Header: "Supply Media Cost",
                                                                accessor: "Supply Media Cost",
                                                                className: "text-center",
                                                                minWidth: 100                                        
                                                            }
                                                        ]}
                                                    /> 
                                        </div>
                                    </div>
            
                                </div>            
            
                        </Modal>)
    }

    return (
        <>
            {isModalOpen ? renderIframeModal() : null}
            <span className=''>
                <i className="fas pl-2 fa-info-circle cursor-pointer " onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsModalOpen(true)
                }}> </i>
            </span>
        </>

    );
}
export default CriteoModal;