import React from 'react'
import classnames from 'classnames'
import { FormError } from './FormError'

const renderLabel = (labelClassName, type, label, isOptional) => {
  return (
    <label className={classnames("mb-1", labelClassName, type === "checkbox" ? "pt-1" : "pt-2")}>
      {label} {isOptional && <span className="bb-text_small bb-color_disabled">(optional)</span>}
    </label>
  );
}

const CheckBoxInput = ({ disabled, field, rtl, containerClassName, form, onChange,
  checkedValue, labelClassName, inputContainerClassName, className, additionalComponent  = false,
  labelPosition = "start", maxLength = 200, keyPressCheck = true,
  label, placeholder, type, isOptional = false, form: { touched, errors } }) => {
  return (
    <div className={containerClassName} dir={rtl ? "rtl" : null}>
      {// eslint-disable-next-line
        labelPosition == "start" && label ? renderLabel(labelClassName, type, label, isOptional) : null}
      <div className={inputContainerClassName}>
        <input className={classnames("form-control", className, touched[field.name] && errors[field.name] ? 'error' : '')}
          placeholder={placeholder ? placeholder : label}
          type={type} {...field}
          disabled={disabled}
          autoComplete={field.name}
          checked={type === "checkbox" ? checkedValue : false}
          onKeyPress={(e) => {
            keyPressCheck && e.key === 'Enter' && e.preventDefault();
          }}
          onChange={(e) => {
            form.setFieldValue(field.name, e.target.checked);
            if (onChange) {
              onChange(e.target.checked, form.setFieldValue);
            }
          }
          }
          maxLength={maxLength}
        />
        {additionalComponent}
        <FormError name={field.name} />

      </div>
      {// eslint-disable-next-line
        labelPosition == "end" && label ? renderLabel(labelClassName, type, label, isOptional) : null}
    </div>
  )
}

export default CheckBoxInput