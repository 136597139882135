import React from 'react';

const BilledByAgencyArrow = () => {
    return (
        <div style={containerStyle}>
            <div style={lineStyle}></div>
            <div style={arrowContainerStyle}>
                <span style={textStyle}>Additional Markup</span>
                <div style={arrowLineStyle}></div>
                <div style={arrowHeadStyle}></div>
            </div>
        </div>
    );
};

// Styles
const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '119px',
    height: 'auto',
    position: 'absolute',
    //borderRight: '1px solid #000',
    right: "-18px",
    top: "22px"
};

const lineStyle = {
    width: '100%',
    height: '2px',
    borderTop: '2px dashed black',
    marginTop: '10px',
};

const arrowContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10px',
};

const textStyle = {
    fontSize: '14px',
    marginBottom: '10px',
};

const arrowLineStyle = {
    width: '2px',
    height: '100px',
    backgroundColor: 'black',
};

const arrowHeadStyle = {
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderTop: '10px solid black',
};

export default BilledByAgencyArrow;