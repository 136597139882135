import React from 'react'
import { BuddyTable } from '../../table-helpers/BuddyTable-2';
import { getModelNum } from '../helper';
import { connect } from 'react-redux';

function EarningsHistory(props) {
    let model = getModelNum(props.user?.commercial_model)
    let apexType = ""
    let data = []
    return (
        <>
            <h3 className="bb-sub-header-txt mb-4">{model === 2 ? "Volume Rewards Historical Statements" : "Earnings Statements Utilising Bud Enterprise to deliver Rich Media"}</h3>
            <BuddyTable
                defaultValue={1}
                pagesize={1}
                tableSorting={false}
                showPagination={false}
                showRowtable={false}
                emptyMessage="No Data Found."
                paginateClassName="col-12"
                skeletonloading={false}
                total={1}
                loading={false}
                currentPage={1}
                data={data}
                className="cursor-pointer col-12 buddy_light p-0"
                dataFor="invoices"
                columns={[
                    {
                        Header: "Month",
                        accessor: "month",
                        className: "text-center",
                        minWidth: 100,
                        Cell: ({ row }) => ( row.original["billing month"])
                    },
                    {
                        Header: "Statement Number",
                        accessor: "statementNumber",
                        className: "text-center",
                        minWidth: 100,
                    },
                    {
                        Header: "Amount",
                        accessor: apexType === "independent" ? "valueAfterFee" : "totalFee" ,
                        className: "text-center",
                        minWidth: 70,
                        Cell: ({ row }) => <span>${apexType === "independent" ? row.original.valueAfterFee : row.original.totalFee}</span>

                    },
                    {
                        Header: "PDF",
                        accessor: "pdf",
                        className: "text-center",
                        minWidth: 100,
                        Cell: ({ row }) => <a style={{color: "black"}} disable href={Number(row.id) <= 2 ? row.original.fileE: undefined} target="_blank">
                            <i className="fas fa-file-pdf"></i>
                          </a> 

                    }
                ]}
            /> 

        </>
    )
}

const mapStateToProps = state => ({

    user: state.user


});
const mapDispatchToProps = {


};
export default (connect(mapStateToProps, mapDispatchToProps)(EarningsHistory));