import React from "react";
import { config } from "../../../helpers";
import { setBuddySettings } from "../customFunctions";
import './tabel-rows.scss'

export const TableRows = (props) => {
    const {
        onChangeRow,
        defaultValue = config.dataLimit() || "10"
    } = props;
    function onChangeValue(event) {
        setBuddySettings("pageSize", event.target.value)

        onChangeRow(event.target.value)
    }
    return (
        <div className="bt-table-paginations-container d-flex flex-row flex-wrap my-3 col-12">
            <span>
                <select className="bb-row-select" value={defaultValue} onChange={e => onChangeValue(e)}>
                    <option value="10">Show 10</option>
                    <option value="25">Show 25</option>
                    <option value="50">Show 50</option>
                    <option value="100">Show 100</option>
                </select>
            </span>
        </div>
    )
}

export default TableRows;