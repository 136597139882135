import React from 'react'
import "../earnings/commercials.scss"
import { numberWithCommas } from '../../utilities/customFunctions';
import { roundDownToNearestHalf } from '../helper';

function FeesMonth(props) {
    const { isSavings } = props;
    let isAU = true

    let data =[]
    // let adjustedCPMP = roundDownToNearestHalf((100 - Number(data["premiumPercentage"].replace(/%/g, ''))) * Number(data["premiumCPM"]) / 100)
    // let adjustedCPMA = roundDownToNearestHalf((100 - Number(data["advancedPercentage"].replace(/%/g, ''))) * Number(data["advancedCPM"]) / 100)
    // let adjustedImpP = ((Number(data["premiumSpend"].replace(/,/g, '')) / adjustedCPMP) * 1000).toFixed(0)
    // let adjustedImpA = ((Number(data["advancedSpend"].replace(/,/g, '')) / adjustedCPMA) * 1000).toFixed(0)
    // let ImpIncreaseP = adjustedImpP - Number(data["premiumImps"].replace(/,/g, '')).toFixed(0)
    // let ImpIncreaseA = adjustedImpA - Number(data["advancedImps"].replace(/,/g, '')).toFixed(0)

    // let sePrem =  ((Number(data["premiumPercentage"].replace(/%/g, '')) * Number(data["premiumSpend"].replace(/,/g, '')))/100).toFixed(0)
    // let seAdv = ((Number(data["advancedPercentage"].replace(/%/g, '')) * Number(data["advancedSpend"].replace(/,/g, '')))/100).toFixed(0)

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between header-container'>
                <div className='d-flex flex-wrap col-6'>
                    <strong><h5>{`Volume Discount - Monthly ${isSavings ? "Savings" : "Margin"} Statement`}</h5></strong>
                    {/* <span>Billing Period {`${formatBillingDate(data["start period"])} to ${formatBillingDate(data["end period"])}`}</span> */}
                    <span>Billing Period</span>

                    <span>Statement Reference - </span>
                </div>
                <div className="position-relative">
                    <img className="bm-logo-c-deal-slider deal" src={`/images/budenterprise/budenterprise_unified.svg`}
                        alt="" />
                </div>
            </div>
        )
    }

    const renderdoubleInput = (label, input1 = "", input2 = "", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 small text-right">{input2}</span> : null}
                </div>
            </div>

        )

    }
    const renderdoubleDoubleInput = (label, input1 = "", input2 = "", input3 = "", input4 = "", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row ">
                    <span className="form-control fake-input mb-2  mr-1 xsmall text-right">{input2}</span>
                    <span className="form-control fake-input mb-2  mr-2 xxsmall text-right">{input1}</span>                    

                    <span className=" form-control fake-input mb-2 mr-1 xsmall text-right">{input4}</span>
                    <span className="form-control fake-input mb-2  xxsmall text-right">{input3}</span>
                    
                </div>
            </div>

        )

    }

    const renderfourDoubleInput = (label, input1 = "", input2 = "", input3 = "", input4 = "", input5 = "", input6 = "", input7 = "", input8 = "", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row ">
                    <span className="form-control fake-input mb-2  mr-1 xsmall text-right">{input2}</span>
                    <span className="form-control fake-input mb-2  mr-2 xxsmall text-right">{input1}</span>                    

                    <span className=" form-control fake-input mb-2 mr-1 xsmall text-right">{input4}</span>
                    <span className="form-control fake-input mb-2  mr-2 xxsmall text-right">{input3}</span>

                    <span className="form-control fake-input mb-2  mr-1 xsmall text-right">{input6}</span>
                    <span className="form-control fake-input mb-2  mr-2 xxsmall text-right">{input5}</span>                    

                    <span className=" form-control fake-input mb-2 mr-1 xsmall text-right">{input8}</span>
                    <span className="form-control fake-input mb-2  xxsmall text-right">{input7}</span>
                    
                </div>
            </div>

        )

    }
    const renderThreeInput = (label, input1 = "", input2 = "", input3 = "", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input2}</span> : null}
                    {input3 ? <span className=" form-control fake-input mb-2 small text-right">{input3}</span> : null}

                </div>
            </div>

        )

    }

    const renderFiveInput = (label, input1 = "", input2 = "", input3 = "", input4="", input5="", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input2}</span> : null}
                    {input3 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input3}</span> : null}
                    {input4 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input4}</span> : null}
                    {input5 ? <span className=" form-control fake-input mb-2 small text-right">{input5}</span> : null}
                </div>
            </div>

        )

    }

    const renderInput = (label, input) => {
        return (
            <div className="d-flex flex-row flex-wrap p-0 col-12 justify-content-between">
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row">
                    <span className="form-control fake-input mb-2  small text-right">{input}</span>
                </div>
            </div>
        )
    }

    return (
        <div className='fee'>
            {renderHeader()}
            <div className='d-flex col-12 flex-wrap mt-5'>
                <div className="d-flex flex-row flex-wrap p-0 col-12 justify-content-between">
                    <label className="mb-1 bb-form-input-label col-3 pt-2 bb-form-dsp-label"></label>
                    <div className="col-9 row">
                        {/* {isAU ? <span className="mb-2  mr-2  bb-font_12px text-center" style={{ width: "150px" }}>Lite</span> : null} */}
                        <span className="mb-2  mr-2  bb-font_12px text-center" style={{ width: "150px" }}>Premium</span>
                        <span className=" mb-2  mr-2 bb-font_12px text-center" style={{ width: "150px" }}>Advanced</span>
                        {/* {isAU ? <span className="mb-2  mr-2  bb-font_12px text-center" style={{ width: "150px" }}>Skins</span> : null} */}
                        {isSavings ? <span className=" mb-2 bb-font_12px text-center" style={{ width: "150px" }}>Total</span> : null}
                    </div>
                </div>
                {/* {isSavings ? <>
                    {isAU ? renderFiveInput("Base CPM Buy Rate", "$7", "$12", "$22", "$29", "") :renderdoubleInput("Base CPM Buy Rate", `$${data["premiumCPM"]}`, `$${data["advancedCPM"]}`)}
                    {isAU ? renderFiveInput("Actual Monthly Spend", "$27,500", "$38,500", "$22,700", "$16,500", "$110,000") :renderThreeInput("Actual Monthly Spend", `$${data["premiumSpend"]}`, `$${data["advancedSpend"]}`, `$${numberWithCommas(Number(data["advancedSpend"].replace(/,/g, '')) + Number(data["premiumSpend"].replace(/,/g, '')))}`)}
                    {isAU ? renderFiveInput("Impressions Purchased", "3,928,571", "2,138,889", "1,250,000", "568,986", "7,886,426"):renderThreeInput("Impressions Purchased", `${data["premiumImps"]}`, `${data["advancedImps"]}`, `${numberWithCommas(Number(data["premiumImps"].replace(/,/g, '')) + Number(data["advancedImps"].replace(/,/g, '')))}`)}
                    {isAU ? renderFiveInput("Volume Discount %", "14.29%", "16.67%", "15.91%", "0.00%", ""):renderdoubleInput("Volume Discount %", `${data["premiumPercentage"]}`, `${data["advancedPercentage"]}`)}
                    
                    {isAU ? renderFiveInput("Spend Efficiencies (Discount)", "$3,929", "$6,418", "$4,375", "$0", "$14,723"):renderThreeInput("Spend Efficiencies (Discount)", `$${numberWithCommas(sePrem)}`, `$${numberWithCommas(seAdv)}`, `$${numberWithCommas(Number(sePrem) + Number(seAdv))}`)}
                    
                    {isAU ? renderFiveInput("Adjusted CPM (After Discount)", "$6", "$11", "$20", "$29", ""):renderdoubleInput("Adjusted CPM (After Discount)", `$${adjustedCPMP}`, `$${adjustedCPMA}`)}

                    {isAU ? renderFiveInput("Impressions Gained", "654,991", "427,880", "253,505", "0", "1,319,374"):renderThreeInput("Impressions Gained", `${numberWithCommas(ImpIncreaseP)}`, `${numberWithCommas(ImpIncreaseA)}`, `${numberWithCommas(ImpIncreaseP + ImpIncreaseA)}`)}

                    {isAU ? renderFiveInput("Adjusted Impressions", "4,583,563", "2,566,769", "1,486,503", "568,986", "9,205,800" ):renderThreeInput("Adjusted Impressions", `${numberWithCommas(adjustedImpP)}`, `${numberWithCommas(adjustedImpA)}`, `${numberWithCommas(Number(adjustedImpP) + Number(adjustedImpA))}`)}



                </> :

                    <>
                        {isAU ? renderFiveInput("Adjusted Base CPM Buy Rate", "$6", "$15", "$18.5", "$29"): renderdoubleInput("Adjusted Base CPM Buy Rate", `$${data["premiumCPM"]}`, `$${data["advancedCPM"]}`)}

                        {isAU ? renderfourDoubleInput("Sell CPM & Increase", '$9', '50.01%','$24', '60.01%','$30', '62.16%', '$40', '37.93%'): renderdoubleDoubleInput("Sell CPM & Increase", `$24`, `${data["premiumPercentage"]}`, `$30`, `${data["advancedPercentage"]}`)}

                        {isAU ? renderFiveInput("Margin",'$3.00', '$9.00', '$11.50', '$11.00'): renderdoubleInput("Margin", `$${(24 - data["premiumCPM"]).toFixed(2)}`, `$${(30 - data["advancedCPM"]).toFixed(2)}`)}

                        {isAU ? renderFiveInput("Total Sell Price to Client",'$41,252', '$61,602', '$44,595' ,'$22,759'): renderdoubleInput("Total Sell Price to Client", `$${data["premiumSpend"]}`, `$${data["advancedSpend"]}`)}

                        {isAU ? renderFiveInput("Monthly Margin", '$13,752', '$23,102' ,'$17,095', '$6,259'): renderdoubleInput("Monthly Margin", `$${data["premiumFee"]}`, `$${data["advancedFee"]}`)}
                        {isAU ? renderInput("Total Monthly Margin", `$60,208`): renderInput("Total Monthly Margin", `$${data["totalFee"]}`)}


                    </>
                } */}

            </div>


        </div>
    )
}

export default (FeesMonth);
