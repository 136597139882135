import { CommercialsConstants } from './commercials.constants';

export const commercials = (state = {}, action) => {
    switch (action.type) {
        case CommercialsConstants.GET_COMMERCIALS_SUCCESS: return { ...state, ...action.payload };
        case CommercialsConstants.COMMERCIALS_LOADING: return { ...state, loading: action.payload };
        case CommercialsConstants.GET_COMMERCIALS_ERROR: return { ...state, error: true };
        case CommercialsConstants.COMMERCIALS_CURRENT_PAGE: return { ...state, page: action.payload };
        case CommercialsConstants.COMMERCIALS_LIMIT_PER_PAGE: return { ...state, limit: action.payload };
        case CommercialsConstants.COMMERCIALS_LIMIT: return { ...state, total: action.payload };
        
        case CommercialsConstants.GET_COMMERCIALS_AGREEMENT:
            return { ...state, agreement: action.payload, error: '' }
        case CommercialsConstants.GET_COMMERCIALS_AGREEMENT_ERROR:
            return { ...state, agreement: false, error: action.payload }

        case CommercialsConstants.SAVE_COMMERCIALS_AGREEMENT:
            return { ...state, agreementSave: true, agreementSaveResp: Date.now(), agreementSaveRespData: action.payload  }
        case CommercialsConstants.SAVE_COMMERCIALS_AGREEMENT_ERROR:
            return { ...state, agreementSave: false, agreementSaveResp: Date.now()}

        case CommercialsConstants.GET_COMMERCIALS_AGREEMENT_PROFILE:
            return { ...state, agreementProfile: action.payload, agreementProfileResp: Date.now() }
        case CommercialsConstants.GET_COMMERCIALS_AGREEMENT_PROFILE_ERROR:
            return { ...state, agreementProfile: action.payload, agreementProfileResp: Date.now()}
        case CommercialsConstants.EMPTY_COMMERCIALS_AGREEMENT:
            return { ...state, agreementProfile: "", agreementProfileResp: ""}

        case CommercialsConstants.GET_COMMERCIALS_AGREEMENT_TCS:
                return { ...state, agreementTCS: action.payload, error: '', agreementTCSUpdate: Date.now() }
        case CommercialsConstants.GET_COMMERCIALS_AGREEMENT_TCS_ERROR:
                return { ...state, agreementTCS: false, error: action.payload, agreementTCSUpdate: false }
        case CommercialsConstants.COMMERCIALS_TCS_LOADING: return { ...state, loadingTCS: action.payload };


        default: return state;
    }
}