import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from './React-Router-Breadcrumbs-hoc';
import classnames from 'classnames'

const PureBreadcrumbs = ({ prefix = false, name = "", className = "", isUpperCase = true, checkDeep = true, icon = "", onClick = null }) => {

  const breadcrumbs = useBreadcrumbs();

  return (
    <div className="d-flex flex-column">
      <div className={classnames("bb-breadcrumbs ml-3 mt-3 mb-2 d-flex flex-row flex-wrap ", isUpperCase ? "text-capitalize" : "", className, onClick ? "cursor-pointer hvr-underline-from-center mw-fit-content" : "")} onClick={onClick}>
        {prefix ?
          <React.Fragment>{prefix}<span className="bb-breadcrumbs-separator">|</span></React.Fragment>
          : ""
        }
        {checkDeep ? breadcrumbs.map(({ breadcrumb, match }, index) => (
          (match.pathname !== "/" && match.pathname !== "") ?
            <h5 className="bb-breadcrumbs-title" key={index}>
              {(index === (breadcrumbs.length - 1))
                ?
                <span className={classnames(isUpperCase ? "text-capitalize" : '')}>{name ? name : breadcrumb}</span>
                :
                <React.Fragment>
                  <Link className="hvr-underline-from-center" to={match.pathname || ""}> {name ? name : breadcrumb}</Link>
                  <span className="bb-breadcrumbs-separator">|</span>
                </React.Fragment>
              }
            </h5>
            :
            null
        )) :
          <h5 className="bb-breadcrumbs-title">
            <span className={classnames(isUpperCase ? "text-capitalize" : '')}>{name}</span>
          </h5>
        }
        {icon && <i className={classnames("fas align-self-center mb-1 ml-2", icon)}></i>}
      </div>
      <hr className="bb-breadcrumbs-line slideInLeft bb-animate" />
    </div>
  );
}

export default PureBreadcrumbs;
