export const AllContextualConstants = {

    GET_DEAL_TYPE: 'GET_DEAL_TYPE',
    DEAL_TYPE_LOADING: 'DEAL_TYPE_LOADING',
    GET_DEAL_TYPE_ERROR: 'GET_DEAL_TYPE_ERROR',
    EMPTY_DEAL_TYPE: 'EMPTY_DEAL_TYPE',

    GET_DEAL_TYPE_POP: 'GET_DEAL_TYPE_POP',
    GET_DEAL_TYPE_POP_ERROR: 'GET_DEAL_TYPE_POP_ERROR',
    EMPTY_DEAL_TYPE_POP: 'EMPTY_DEAL_TYPE_POP',

    GET_DEAL_TYPE_FORMAT_CAROUSEL: 'GET_DEAL_TYPE_FORMAT_CAROUSEL',
    DEAL_TYPE_FORMAT_CAROUSEL_LOADING: 'DEAL_TYPE_FORMAT_CAROUSEL_LOADING',
    GET_DEAL_TYPE_FORMAT_CAROUSEL_ERROR: 'GET_DEAL_TYPE_FORMAT_CAROUSEL_ERROR',
    EMPTY_DEAL_TYPE_FORMAT_CAROUSEL: 'EMPTY_DEAL_TYPE_FORMAT_CAROUSEL',

    ADD_SELECTED_DEAL_TYPE_SUCCESS: 'ADD_SELECTED_DEAL_TYPE_SUCCESS',
    ADD_SELECTED_DEAL_TYPE_ERROR: 'ADD_SELECTED_DEAL_TYPE_ERROR',
    UPDATE_SELECTED_DEAL_TYPE_SUCCESS: 'UPDATE_SELECTED_DEAL_TYPE_SUCCESS',
    UPDATE_SELECTED_DEAL_TYPE_ERROR: 'UPDATE_SELECTED_DEAL_TYPE_ERROR',
    GET_CONTEXUAL_MEDIA_AD_BY_ID: 'GET_CONTEXUAL_MEDIA_AD_BY_ID',
    GET_CONTEXUAL_MEDIA_AD_BY_ID_ERROR: 'GET_CONTEXUAL_MEDIA_AD_BY_ID_ERROR',
    EMPTY_CONTEXUAL_MEDIA_AD_DATA: 'EMPTY_CONTEXUAL_MEDIA_AD_DATA',
    SELECTED_CONTEXUAL_MEDIA_LOADING: 'SELECTED_CONTEXUAL_MEDIA_LOADING',
    UPDATE_SELECTED_DEAL_PLAN_SUCCESS: 'UPDATE_SELECTED_DEAL_PLAN_SUCCESS',
    UPDATE_SELECTED_DEAL_PLAN_ERROR: 'UPDATE_SELECTED_DEAL_PLAN_ERROR',

    GET_PRE_DEFINE_MEDIAS: 'GET_PRE_DEFINE_MEDIAS',
    GET_PRE_DEFINE_MEDIAS_ERROR: 'GET_PRE_DEFINE_MEDIAS_ERROR',
    GET_PRE_DEFINE_MEDIAS_LOADING: 'GET_PRE_DEFINE_MEDIAS_LOADING',
    EMPTY_PRE_DEFINE_MEDIAS: 'EMPTY_PRE_DEFINE_MEDIAS',
    
    CHECK_TOKEN_LOADING: 'CHECK_TOKEN_LOADING',
    CHECK_TOKEN: 'CHECK_TOKEN',
    CHECK_TOKEN_ERROR: 'CHECK_TOKEN_ERROR',

    GET_BESPOKE_MEDIAS: 'GET_BESPOKE_MEDIAS',
    GET_BESPOKE_MEDIAS_ERROR: 'GET_BESPOKE_MEDIAS_ERROR',
    GET_BESPOKE_MEDIAS_LOADING: 'GET_BESPOKE_MEDIAS_LOADING',
    EMPTY_BESPOKE_MEDIAS: 'EMPTY_BESPOKE_MEDIAS',

    ADD_SELECTED_MEDIAS_SUCCESS: 'ADD_SELECTED_MEDIAS_SUCCESS',
    ADD_SELECTED_MEDIAS_ERROR: 'ADD_SELECTED_MEDIAS_ERROR',

    GET_BESPOKE_CATEGORIES: 'GET_BESPOKE_CATEGORIES',
    GET_BESPOKE_CATEGORIES_ERROR: 'GET_BESPOKE_CATEGORIES_ERROR',
    GET_BESPOKE_CATEGORIES_LOADING: 'GET_BESPOKE_CATEGORIES_LOADING',
    EMPTY_BESPOKE_CATEGORIES: 'EMPTY_BESPOKE_CATEGORIES',

    ADD_BESPOKE_MEDIA_SUCCESS: 'ADD_BESPOKE_MEDIA_SUCCESS',
    ADD_BESPOKE_MEDIA_ERROR: 'ADD_BESPOKE_MEDIA_ERROR',
    UPDATE_BESPOKE_MEDIA_SUCCESS: 'UPDATE_BESPOKE_MEDIA_SUCCESS',
    UPDATE_BESPOKE_MEDIA_ERROR: 'UPDATE_BESPOKE_MEDIA_ERROR',

    UPDATE_BESPOKE_STATUS_SUCCESS:'UPDATE_BESPOKE_STATUS_SUCCESS',
    UPDATE_BESPOKE_STATUS_ERROR:'UPDATE_BESPOKE_STATUS_ERROR',
    UPDATE_BESPOKE_STATUS_LOADING:'UPDATE_BESPOKE_STATUS_LOADING'


};
