import { CommercialsConstants } from "./commercials.constants";
import axios from 'axios';
import { config } from '../../../helpers'
const ROOT_URL_API = config.apiServer + "/api";

export const getCommercials = ({pageSize = 10, page = 1, sortby = "", sorttype = ""}) => {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch({ type: CommercialsConstants.COMMERCIALS_CURRENT_PAGE, payload: page });
        dispatch({ type: CommercialsConstants.COMMERCIALS_LIMIT_PER_PAGE, payload: pageSize });
        axios.get(`${ROOT_URL_API}/commercials/rates_paginate?limit=${pageSize}&offset=${page}&sortby=${sortby}&sorttype=${sorttype}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(loading(false));
                if (response.data) {
                    dispatch({
                        type: CommercialsConstants.GET_COMMERCIALS_SUCCESS,
                        payload: response.data
                    });
                    dispatch({
                        type: CommercialsConstants.COMMERCIALS_LIMIT,
                        payload: response.data.total_rows
                    });
                }
                else {
                    dispatch({
                        type: CommercialsConstants.GET_COMMERCIALS_ERROR,
                        payload: "error"
                    });
                }
            }).catch(() => {
                dispatch(loading(false));
                dispatch({
                    type: CommercialsConstants.GET_COMMERCIALS_ERROR,
                    payload: "error"
                });
            })
    }
}

export const getCommercialsAgreement = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axios.get(`${ROOT_URL_API}/commercials/agreement`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT, payload: response.data.data });
                }
                else {
                    dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_ERROR, payload: response.data ? response.data.msg : "Error" });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}
const loading = (status) => {
    return {
        type: CommercialsConstants.COMMERCIALS_LOADING,
        payload: status
    };
};



export const generateTcs = (values) => {
    return (dispatch) => {
        dispatch(loadingTcs(true));

        axios.post(`${config.logServer}/generate_tcs`, {...values}, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_TCS, payload: response.data.url + `?date=${Date.now()}`});
                }
                else {
                    dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_TCS_ERROR, payload: response.data ? response.data.msg : "Error" });
                }
                dispatch(loadingTcs(false));
            }).catch(() => {
                dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_TCS_ERROR, payload: false });
                dispatch(loadingTcs(false));
            });
    };
}
const loadingTcs = (status) => {
    return {
        type: CommercialsConstants.COMMERCIALS_TCS_LOADING,
        payload: status
    };
};


export const saveCommercialsAgreement = (values) => {

    const isEdit = values._id ? true : false;
    const {_id, ...rest } = values

    return (dispatch) => {
        dispatch(loading(true));

        axios.post(`${ROOT_URL_API}/commercials/rates${isEdit ? "/"+ values._id : ""}`, isEdit ? {...rest} : {...values}, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CommercialsConstants.SAVE_COMMERCIALS_AGREEMENT, payload: response.data.data });
                }
                else {
                    dispatch({ type: CommercialsConstants.SAVE_COMMERCIALS_AGREEMENT_ERROR, payload: response.data ? response.data.msg : "Error" });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: CommercialsConstants.SAVE_COMMERCIALS_AGREEMENT_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}


export const getCommercialsAgreementById = (id) => {
    return (dispatch) => {
        dispatch(loading(true));

        axios.get(`${ROOT_URL_API}/commercials/rates/${id}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_PROFILE, payload: response.data.data });
                }
                else {
                    dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_PROFILE_ERROR, payload: response.data ? response.data.msg : "Error" });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: CommercialsConstants.GET_COMMERCIALS_AGREEMENT_PROFILE_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}

export const EmptyCommercialsAgreementById = () => {
    return (dispatch) => {
        dispatch({ type: CommercialsConstants.EMPTY_COMMERCIALS_AGREEMENT, payload: "" });
    };
}
