import React from 'react';
import classnames from 'classnames'
import './index.scss';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        };
    }

    componentDidMount() {
        this.setState({
            currentPage: this.props.current
        });
    }
    
    componentDidUpdate(prevProps) {
        if(this.props.current !== prevProps.current) this.setState({ currentPage: this.props.current })
    }

    setPage(page) {
        var totalPages = Math.ceil(this.props.total / this.props.pagesize) || 1;

        if (page > totalPages) {
            return;
        }
        this.setState({ currentPage: page });
    }
    changePage(page) {
        // eslint-disable-next-line
        if (page == this.props.current || page > this.props.total) {
            return;
        }
        this.setState({ currentPage: page });
        // update state
        // call change page function in parent component
        this.props.onChangePage(page);
    }

    render() {

        const { currentPage } = this.state;

        var totalPages = Math.ceil(this.props.total / this.props.pagesize) || 1;


        /* if (totalPages < 2) {
            return null;
        } */
        return (
            <React.Fragment>
                <div className={classnames("bt-table-paginations-container d-flex flex-row flex-wrap justify-content-center my-3", this.props.paginateClassName)}>
                    <div className="bt-table-page-input mr-sm-3 align-self-center">
                        Page <input type="number"
                            onBlur={(e) => this.changePage(e.target.value)}
                            onChange={(e) => this.setPage(e.target.value)}
                            value={currentPage}
                            min="1"
                            max={totalPages}
                            className="mr-1"
                        />
                        of {totalPages}
                    </div>
                    <div className="bt-table-paginations-buttons">

                        <button
                            className="bt-table-pagination-button"
                            onClick={() => {
                                if (currentPage === 1) return;
                                this.changePage(1);
                            }}
                            disabled={currentPage === 1}
                        >
                            {//this.props.previousText
                            }
                            <i className="fas fa-step-backward text-white"></i>
                        </button>
                        <button
                            className="bt-table-pagination-button"
                            onClick={() => {
                                if (currentPage === 1) return;
                                this.changePage(currentPage - 1);
                            }}
                            disabled={currentPage === 1}
                        >
                            {//this.props.previousText
                            }
                            <i className="fas fa-chevron-left text-white"></i>
                        </button>
                        <button
                            className="bt-table-pagination-button"
                            onClick={() => {
                                if (currentPage === totalPages) return;
                                this.changePage(currentPage + 1);
                            }}
                            disabled={currentPage === totalPages}
                        >
                            {
                                //this.props.nextText
                            }
                            <i className="fas fa-chevron-right text-white"></i>
                        </button>
                        <button
                            className="bt-table-pagination-button"
                            onClick={() => {
                                if (currentPage === totalPages) return;
                                this.changePage(totalPages);
                            }}
                            disabled={currentPage === totalPages}
                        >
                            <i className="fas fa-step-forward text-white"></i>
                        </button>
                    </div>
                </div>

            </React.Fragment >
        );

    }
}

export default Pagination;