import React, { useState } from 'react';
import Modal from "react-modal";

function DiscountSellCPMModal(props) {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const closeModal = () => { setIsModalOpen(false) }

    const renderIframeModal = () => {
        return (
            <Modal
                isOpen={isModalOpen ? true : false}
                onRequestClose={closeModal}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container mx-400"
                bodyOpenClassName="overflow-hidden"
                ariaHideApp={false}
            >
                <div className='col-sm-12 col-md-12 d-flex py-5 flex-column justify-content-center h-300'>
                    <>
                    <div className="d-flex flex-row justify-content-between">
                        <h5><strong>Calculating Discount & Earnings</strong></h5>
                        <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-dark" onClick={(e) => closeModal(e)}>
                        </div>
                    </div>

                            <p className="mt-2">By entering the monthly spend & proposed CPM sell rates, the platform can generate margin-based reports. If you leave blank those reports will not be created.</p>                    
                        
                        <button type="button" onClick={() => closeModal()} className="bb-button align-self-center mt-2" disabled={false}>Ok</button>
                    </>



                </div>
            </Modal>)
    }
    
    return (
        <>
            {isModalOpen ? renderIframeModal() : null}
            <span className='mt-2 order-2'>
                <i className="fas pl-2 fa-info-circle cursor-pointer " onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsModalOpen(true)
                }}> </i>
            </span>
        </>

    );
}
export default DiscountSellCPMModal;