import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../utilities/BreadCrumb'
import { pushLocation } from '../../utilities/customRoutes';
import "../../creative/report/report.scss"
import { getQueryStringParams } from '../../utilities/customFunctions';
import BuddyChart from '../../creative/report/reports/chart';
import FeesHistory from './history';
import FeesMonth from './month';

function FeesDate(props) {

    const [isDate, setIsDate] = useState(false)
    const [isMonth, setIsMonth] = useState(false)
    const [isHistory, setIsHistory] = useState(false)

    let searchParams = getQueryStringParams(props.location.search);

    useEffect(() => {
        document.getElementById("root").classList.add("root-light");
        if (searchParams && searchParams.page) {
            switch (searchParams.page) {
                case "month":
                    setIsMonth(true)
                    setIsDate(false)
                    setIsHistory(false)
                    break;
                case "history":
                    setIsHistory(true)
                    setIsDate(false)
                    setIsMonth(false)
                    break;
                default:
                    setIsDate(true)
                    setIsHistory(false)
                    setIsMonth(false)
            }
        }
        else {
            setIsDate(true)
            setIsHistory(false)
            setIsMonth(false)
        }
        return () => {
            document.getElementById("root").classList.remove("root-light");
        }
        // eslint-disable-next-line
    }, [props])

    const renderChart = () => {
        return <div className="mt-4 chart-container">
            <h3 className="bb-sub-header-txt mb-4">Platform Fees Year To Date</h3>

            <BuddyChart type="scrollstackedcolumn2d"
                width="100%"
                height="400"
                dataFormat="JSON"
                // dataSource={createDataSetFees()} 
                dataSource={[]} 
                /> 

            
        </div>
    }
    return (
        <>
            <BreadCrumb checkDeep={false} name={"Bud Independent - Platform Fees"} />
            <div className="d-flex flex-column justify-content-center m-3  ">
                <div className=" d-flex mx-4 direction-button">

                    <div className={isDate ? "selected-page" : "pl-2 bb-body-txt"} onClick={() => {
                        pushLocation(`/fees`)
                    }}>Platform Fees Year to Date</div>
                    <div className={isMonth ? "selected-page" : "pl-2 bb-body-txt"} onClick={() => {
                        pushLocation(`/fees?page=month`)
                    }}>This Month's Platform Invoice</div>
                    <div className={isHistory ? "selected-page" : "pl-2 bb-body-txt"} onClick={() => {
                        pushLocation(`/fees?page=history`)
                    }}>Past Invoices</div>

                </div>
                <div className="d-flex flex-column mx-4 p-3 mb-3 report-container">

                    {isHistory ? <FeesHistory /> : isMonth ? <FeesMonth /> : renderChart()}
                </div>
            </div>
        </>
    )
}

export default (FeesDate);