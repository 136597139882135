import React, { useEffect, useRef, useState } from 'react'
import BreadCrumb from '../../utilities/BreadCrumb'
import { FastField, Formik, Form, Field } from 'formik';
import {
    SelectInput,
    NumberFormatInput, CheckBoxInput,
    PercentageInput,
    FormInput
} from '../../form-helpers'
import Modal from 'react-modal'
import { Document, Page, pdfjs } from 'react-pdf';
import { calculateDiscountFees, calculateFees, calculateRefundRates, calculateSaasFees, formatSubmitDate, generatePDF, 
    getAdjustedCPM, getCountry, getModel, getModelNum, getPercentagePaid, getSumbitValues, gotoRates, handleSetSellCPM, 
    RenderEnterpriseLogo, setEditVals } from '../helper';
import DateRPicker from '../../render-helpers/DateRPicker';
import { SUPPORT_OPTIONS, createCPMObject, generateCPMArray } from '../helper';
import classNames from 'classnames';
import CustomDatePicker from '../../form-helpers/DatePicker';
import { pushLocation, refresh } from '../../utilities/customRoutes';
import "./index.scss"
import { getDealTypeFormatCarousel, emptyDealTypeFormatCarousel } from '../../../redux/audiences/contextual/contextual-media.actions';
import { connect } from 'react-redux';
import { emptyObjectives, getAllObjectives } from '../../../redux/showcase/helpers/helpers.actions';
import FormatCarouselModal from '../../audiences/contextual/create-deal/options/format-carousel/FormatCarouselModal';
import { StatusFilter } from '../../render-helpers';
import FeeModal from './models/fee';
import SupportModal from './models/support';
import DiscountSellCPMModal from './models/discount_sell_cpm';
import CriteoModal from './models/criteo';
import AgreementModal from './models/active_agreement';
import { notify } from '../../utilities/customFunctions';
import Toggle from 'react-toggle';
import { CalcModalForm, confirmActionApproved, DiscountCalcModal, RefundCalcModal, renderFastField, renderIndependent } from './renderHelper';
import { DiscountSchema, getInitVals, getMainSchema, Schema } from './schema';
import { BIG_MOBILE_SALESPERSON, C_LEVEL, COMMERCIAL_MANAGER, INTERNAL_USERS, SALES_DIRECTOR, SUPER_ADMIN } from '../../../helpers/roles';
import { emptyNetworks, getAllNetworksUser } from '../../../redux/helpers/helpers.actions';
import { emptyCMPCountry, getCMPByCountryModel } from '../../../redux/settings/others/commercial-model-pricing/cmp.actions';
import { usePrevious } from '../../utilities/customHooks';
import { differenceInDays } from 'date-fns';
import { MONTHLY_SPEND_BAND } from '../../admin/others/commercial-model-pricing/schema';
import { getallCommercialUsers } from '../../../redux/settings/all-users/all-users.actions';
import { EmptyCommercialsAgreementById, generateTcs, getCommercialsAgreement, getCommercialsAgreementById, saveCommercialsAgreement } from '../../../redux/budenterprise/commercials/commercials.actions';
import Spinner from '../../utilities/Spinner';
import { Loader } from 'rsuite';
import { isEmpty } from 'lodash';

function Rates(props) {
    pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';
    const { params, agreement, user } = props;



    const [modelL, setModelL] = useState(!params?.id ? getModel(props.location) : false);
    const [model, setModel] = useState(!params?.id ? getModelNum(modelL) : false);
    const [isBudIndependent, setIsBudIndependent] = useState(model === 1 ? true : false);

    const prevProps = usePrevious(props)


    const [country, setCountry] = useState(getCountry(props?.user?.country_id || ""));
    const [isAU, setIsAU] = useState(country === 'AU');
    const [isEdit, setIsEdit] = useState(false);

    const [minCPM, setMinCPM] = useState(isAU ? 20 : 15);
    const [maxCPM, setMaxCPM] = useState(isAU ? 50 : 35);
    const [advCPM, setAdvCPM] = useState(isAU ? 24 : 17);

    const [PREMIUM_CPM, setPREMIUM_CPM] = useState(generateCPMArray(minCPM, maxCPM));
    const [ADVANCED_CPM, setADVANCED_CPM] = useState([...PREMIUM_CPM]);

    const [PREMIUM_CPM_DISCOUNT_ALL, setPREMIUM_CPM_DISCOUNT_ALL] = useState(isAU ? generateCPMArray(18, 35) : generateCPMArray(15, 35));
    const [ADVANCED_CPM_DISCOUNT_ALL, setADVANCED_CPM_DISCOUNT_ALL] = useState(isAU ? generateCPMArray(22, 35) : generateCPMArray(17, 35));


    const [totalSpendSumCheck, setTotalSpendSumCheck] = useState(isAU ? 50000 : 20000);
    const [monthlySpendBand, setMonthlySpendBand] = useState([]);
    const [isReady, setIsReady] = useState(false)

    const [numPages, setNumPages] = useState(null);
    const [showCalculation, setShowCalculation] = useState(true)
    const [isCalcModalOpen, setIsCalcModalOpen] = useState(false)
    const [isDiscountCalcModalOpen, setIsDiscountCalcModalOpen] = useState(false)
    const [isRefundCalcModalOpen, setIsRefundCalcModalOpen] = useState(false)
    const [showDiscountMargin, setShowDiscountMargin] = useState(false)
    const [isCarouselModalOpen, setIsCarouselModalOpen] = useState(false)
    const [obj, setObj] = useState('')
    const [approvalAA, setApprovalAA] = useState(false)
    const [approvalBA, setApprovalBA] = useState(false)

    const [pm2, setPm2] = useState(PREMIUM_CPM[0])
    const [ad2, setAd2] = useState(PREMIUM_CPM[isAU ? 4 : 2])

    const [prem_spend_set, setPrem_spend_set] = useState(0)
    const [adv_spend_set, setAdv_spend_set] = useState(0)

    const [prem_base_set, setPrem_base_set] = useState(PREMIUM_CPM_DISCOUNT_ALL[0])
    const [adv_base_set, setAdv_base_set] = useState(ADVANCED_CPM_DISCOUNT_ALL[0])

    const [isManagedService, setIsManagedService] = useState(false)


    const divTCsRef = useRef(null);
    const [scrollTCsAtBottom, setScrollTCsAtBottom] = useState(false)

    const aaButtonRef = useRef(null);
    const baButtonRef = useRef(null);

    const formik = useRef(null);
    const calcFormik = useRef(null);

    const [tcsURL, setTcsURL] = useState('');


    const containerClass = "form-row col-md-12 mb-3",
        inputClass = "col-sm-12 col-md-7",
        labelClass = "col-md-5 pt-2 text-right ";

    useEffect(() => {
        if (agreement?._id) {
            gotoRates(agreement?._id)
        }
        // eslint-disable-next-line
    }, [agreement])

    const prevEditId = usePrevious(params && params.id)

    useEffect(() => {
        if (prevEditId !== params.id) {
            let id = (params && params.id) || ""


            if (id) {
                props.getCommercialsAgreementById(id)

            }
            else {
                refresh()
            }
        }
        // eslint-disable-next-line
    }, [params.id])

    useEffect(() => {
        if (user && user?.role === COMMERCIAL_MANAGER && user?.commercial_model) {
            props.getCommercialsAgreement()
        }
        // eslint-disable-next-line
    }, [user])

    useEffect(() => {
        if (prevProps && prevProps.CMPModel && props.CMPModel.data && prevProps.CMPModel.date !== props.CMPModel.date) {
            let data = MONTHLY_SPEND_BAND.filter(function (el) {
                return el.country == country;
            });
            setMonthlySpendBand(data)
            setIsReady(true)
            if (isEdit) {
                let sfv = formik.current.setFieldValue;
                let profile = props.agreementProfile
                setEditVals(sfv, profile)
                setTcsURL(profile.tcs_url)
                if (profile.status === "APPROVED") {
                    setApprovalAA(true)
                    setApprovalBA(true)
                }
                if (profile.status === "PENDING BIGADS APPROVAL") {
                    setApprovalAA(true)
                }
                else if (profile.status === "PENDING CLIENT APPROVAL") {
                    setApprovalBA(true)
                }
            }
            else {
                formik.current.setFieldValue("monthly_spend", data[0])
                let vals = { ...getInitVals(isAU, model, PREMIUM_CPM_DISCOUNT_ALL, ADVANCED_CPM_DISCOUNT_ALL, "", PREMIUM_CPM, "", role === C_LEVEL ? `${props.user.firstName} ${props.user.lastName}` : "Andrew O'Shea", true) }
                let v = { ...formik.current.values }
                Object.keys(vals).forEach(async (key) => {
                    v[key] = vals[key];
                    await formik.current.setFieldValue(key, vals[key]);
                });
                v["monthly_spend"] = data[0]
                handleCalculation(formik.current.setFieldValue, v)
            }


        }
        if (prevProps && props.agreementTCS && prevProps.agreementTCSUpdate !== props.agreementTCSUpdate) {
            setTcsURL(props.agreementTCS)
        }
        if (prevProps && prevProps.agreementSaveResp !== props.agreementSaveResp) {
            if (props.agreementSave) {
                notify("Contract Saved", "s")
                if (role === COMMERCIAL_MANAGER) {
                    setApprovalAA(true)
                    props.agreementSaveRespData && pushLocation("/commercials")
                }
                else {
                    setApprovalBA(true)
                    props.agreementSaveRespData && refresh();
                }
                //pushLocation("/commercials/rates/" + props.agreementSaveRespData?._id + "?t=" + Date.now())
            }
            else {
                notify("Something went wrong.", "e")
                setApprovalAA(false)
                setApprovalBA(false)

            }
        }
        if (prevProps && prevProps.agreementProfileResp !== props.agreementProfileResp) {
            if (props.agreementProfile) {
                let profile = props.agreementProfile
                if (profile.status === "TERMINATED" && role === COMMERCIAL_MANAGER){
                    pushLocation("/commercials")
                }
                setEditValues(profile);
            }
            else {
                pushLocation("/commercials")
            }

        }

    }, [props.CMPModel, prevProps, props.greementTCSUpdate, props.agreementTCS, props.agreementSaveResp, props.agreementSave, props.agreementProfile, props.agreementProfileResp])

    useEffect(() => {
        document.getElementById("root").classList.add("root-light");

        if (props.role !== COMMERCIAL_MANAGER) {
            props.getAllNetworksUser("15,153");
        }


        if (country && model) {            
            props.getCMPByCountryModel(isAU ? "AU" : "NZ", modelL)
        }


        const scrollableDiv = document.querySelector(".bb-tcs-container");

        const handleScroll = () => {
            if (divTCsRef.current && !scrollTCsAtBottom) {
                const { scrollTop, scrollHeight, clientHeight } = divTCsRef.current;

                if (scrollTop + clientHeight >= (scrollHeight - 500)) {
                    setScrollTCsAtBottom(true);

                    if (scrollableDiv) {
                        scrollableDiv.removeEventListener("scroll", handleScroll);
                    }
                }
            }
        };


        if (scrollableDiv) {
            scrollableDiv.addEventListener("scroll", handleScroll);
        }


        return () => {
            document.getElementById("root").classList.remove("root-light");
            if (scrollableDiv) {
                scrollableDiv.removeEventListener("scroll", handleScroll);
            }
            props.emptyNetworks()
            props.emptyCMPCountry()
            props.EmptyCommercialsAgreementById()

        }
    }, [])


    const setEditValues = (profile) => {
        let modelL = profile.model
        let model = getModelNum(modelL)
        let isBudIndependent = model === 1
        let countryEdit = profile.country || getCountry(props?.user?.country_id || "")
        setModelL(modelL)
        setModel(model)
        setIsBudIndependent(isBudIndependent)
        setCountry(countryEdit)
        handleInit(countryEdit, profile.company_id, modelL, true)
        setIsEdit(true)
    }


    const handleApproveClick = (values, errors) => {
        console.log(values, errors)
        if (isEmpty(errors)) {
            confirmActionApproved(handleSubmit, values)
        }
        else {
            notify("Please fill the required fields.", "e")
        }
    };

    const handleTerminate = () => {
        let v = { ...props.agreementProfile }
        v["status"] = "TERMINATED"
        v["terminated_by"] = `${props.user.firstName} ${props.user.lastName}`
        v["terminated_by_id"] = props.user.id
        v["terminated_date"] = formatSubmitDate(new Date())

        props.saveCommercialsAgreement(v)
    };


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const renderTAndC = () => {
        const { loadingTCS } = props;
        return (

            <div className={classNames(containerClass, "justify-content-center")}>
                <label className={"col-7 pt-2 "}>Terms & Conditions</label>
                <div className="col-7 ">
                    <Document
                        file={tcsURL}
                        // options={{ worker: '/pdf.worker.js', }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        inputRef={divTCsRef}
                        className={' ml-1 bb-tcs-container form-control large'}
                        externalLinkTarget="_blank"
                        loading="Loading T&Cs..."
                        noData="Please wait..."
                        error="Error getting T&Cs. Please download."
                    >  {

                            <div className="position-relative">
                                {loadingTCS || !tcsURL ? <Spinner className="tc-loader" type="oval" width="100" height="100" /> : null}
                                {
                                    numPages && Array.from(
                                        new Array(numPages),
                                        (el, index) => (
                                            <Page
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                                loading=""
                                                error=""
                                                width={750}
                                                renderAnnotationLayer={false}
                                            />
                                        ),
                                    )
                                }


                            </div>


                        }

                    </Document>
                    {loadingTCS || !tcsURL ? null :
                    <span className="hvr-underline-from-center font-weight-bold bb-text_small mt-1 ml-3"
                        onClick={() => { window.open(tcsURL, "_blank") }}>Download T&Cs</span>
                    }
                </div>
            </div>
        )
    }

    const handleCalculation = async (setFieldValue, values = false) => {
        const { premium_spend, advanced_spend, monthly_spend, premium_spend_set, advanced_spend_set } = values || formik?.current?.values

        let v = values ? { ...values } : { ...formik?.current?.values }
        if (premium_spend && advanced_spend && monthly_spend) {
            if (model === 1) {
                let percentage = getPercentagePaid(modelL, monthly_spend.value, premium_spend.value, advanced_spend.value, country, props.CMPModel?.data || [])
                await setFieldValue("percentage", percentage)
                v["percentage"] = percentage
            }
            setPm2(premium_spend)
            setAd2(advanced_spend)

            if (model === 3 || model === 2) {
                let adj = getAdjustedCPM(modelL, monthly_spend.value, premium_spend.value, advanced_spend.value, country, props.CMPModel?.data || [])
                await setFieldValue("set_adjusted_cpm_prem", adj.prem)
                await setFieldValue("set_adjusted_cpm_adv", adj.adv)
                v["set_adjusted_cpm_prem"] = adj.prem
                v["set_adjusted_cpm_adv"] = adj.adv


                if (model === 2) {
                    let p = premium_spend_set.value,
                        a = advanced_spend_set.value;
                    let p_r = (((p - Number(adj.prem)) / p) * 100).toFixed(2)
                    let a_r = (((a - Number(adj.adv)) / a) * 100).toFixed(2)
                    await setFieldValue("percentage_refund", Math.min(p_r, a_r))
                    v["percentage_refund"] = Math.min(p_r, a_r)

                }
                if (model === 3) {
                    let percentageP = getPercentagePaid(modelL, monthly_spend.value, premium_spend.value, advanced_spend.value, country, props.CMPModel?.data || [])
                    await setFieldValue("percentage_discount", percentageP)
                    v["percentage_discount"] = percentageP

                }
            }
            pdfTrigger(v)
        }
    }



    const calculateRefund = async (setFieldValue, updatePercentage = true) => {
        const { proposed_sell_cpm_prem, proposed_sell_cpm_adv,
            adjusted_cpm_prem, adjusted_cpm_adv,
            monthly_spend_prem, monthly_spend_adv
        } = calcFormik?.current?.values

        let tmm = 0;

        let client_facing_cpm_prem = proposed_sell_cpm_prem.value
        let client_facing_cpm_adv = proposed_sell_cpm_adv.value

        let proposed_sell_cpm_percentage_prem = (((client_facing_cpm_prem - adjusted_cpm_prem) / adjusted_cpm_prem) * 100).toFixed(2)
        let refund_percentage_prem = adjusted_cpm_prem > 0 ? (((client_facing_cpm_prem - adjusted_cpm_prem) / client_facing_cpm_prem) * 100).toFixed(2) : 0
        let refund_cpm_prem = adjusted_cpm_prem > 0 ? client_facing_cpm_prem - adjusted_cpm_prem : 0
        let monthly_refund_prem = Math.floor((refund_percentage_prem * monthly_spend_prem) / 100)

        updatePercentage && await setFieldValue('proposed_sell_cpm_percentage_prem', proposed_sell_cpm_percentage_prem)
        await setFieldValue('refund_percentage_prem', refund_percentage_prem)
        await setFieldValue('refund_cpm_prem', refund_cpm_prem.toFixed(2))
        await setFieldValue('monthly_refund_prem', monthly_refund_prem)

        let proposed_sell_cpm_percentage_adv = (((client_facing_cpm_adv - adjusted_cpm_adv) / adjusted_cpm_adv) * 100).toFixed(2)
        let refund_percentage_adv = adjusted_cpm_adv > 0 ? (((client_facing_cpm_adv - adjusted_cpm_adv) / client_facing_cpm_adv) * 100).toFixed(2) : 0
        let refund_cpm_adv = adjusted_cpm_adv > 0 ? client_facing_cpm_adv - adjusted_cpm_adv : 0
        let monthly_refund_adv = Math.floor((refund_percentage_adv * monthly_spend_adv) / 100)

        updatePercentage && await setFieldValue('proposed_sell_cpm_percentage_adv', proposed_sell_cpm_percentage_adv)
        await setFieldValue('refund_percentage_adv', refund_percentage_adv)
        await setFieldValue('refund_cpm_adv', refund_cpm_adv.toFixed(2))
        await setFieldValue('monthly_refund_adv', monthly_refund_adv)

        tmm = monthly_refund_prem + monthly_refund_adv
        await setFieldValue('total_monthly_refund', tmm)
        await setFieldValue('total_monthly_refund_p', ((tmm / (Number(monthly_spend_prem) + Number(monthly_spend_adv))) * 100).toFixed(2))
        await setFieldValue('total_annualised_refund', tmm * 12)
    }


    const calculateMargin = async (setFieldValue, updatePercentage = true) => {
        const { proposed_sell_cpm_prem, proposed_sell_cpm_adv,
            adjusted_cpm_prem, adjusted_cpm_adv, adjusted_imp_prem, adjusted_imp_adv,
            monthly_spend_prem, monthly_spend_adv,
        } = calcFormik?.current?.values

        let tmm = 0;

        let proposed_sell_cpm_percentage_prem = (((proposed_sell_cpm_prem.value - adjusted_cpm_prem) / adjusted_cpm_prem) * 100).toFixed(2)
        let margin_prem = adjusted_cpm_prem > 0 ? (proposed_sell_cpm_prem.value - adjusted_cpm_prem).toFixed(2) : 0
        let total_sell_prem = Math.round(proposed_sell_cpm_prem.value * adjusted_imp_prem / 1000)

        let monthly_margin_prem_2 = Math.round(total_sell_prem - Number(monthly_spend_prem)) || 0
        tmm += monthly_margin_prem_2
        updatePercentage && await setFieldValue('proposed_sell_cpm_percentage_prem', proposed_sell_cpm_percentage_prem)
        await setFieldValue('margin_prem', margin_prem)
        await setFieldValue('total_sell_prem', total_sell_prem)
        await setFieldValue('monthly_margin_prem', monthly_margin_prem_2)
        await setFieldValue('margin_percentage_prem', ((monthly_margin_prem_2 / total_sell_prem) * 100).toFixed(2))


        let proposed_sell_cpm_percentage_adv = (((proposed_sell_cpm_adv.value - adjusted_cpm_adv) / adjusted_cpm_adv) * 100).toFixed(2)
        let margin_adv = adjusted_cpm_adv > 0 ? (proposed_sell_cpm_adv.value - adjusted_cpm_adv).toFixed(2) : 0
        let total_sell_adv = Math.round(proposed_sell_cpm_adv.value * adjusted_imp_adv / 1000)

        let monthly_margin_adv_2 = Math.round(total_sell_adv - Number(monthly_spend_adv)) || 0
        tmm += monthly_margin_adv_2
        updatePercentage && await setFieldValue('proposed_sell_cpm_percentage_adv', proposed_sell_cpm_percentage_adv)
        await setFieldValue('margin_adv', margin_adv)
        await setFieldValue('total_sell_adv', total_sell_adv)
        await setFieldValue('monthly_margin_adv', monthly_margin_adv_2)
        await setFieldValue('margin_percentage_adv', ((monthly_margin_adv_2 / total_sell_adv) * 100).toFixed(2))

        await setFieldValue('total_monthly_margin', tmm)
        await setFieldValue('total_monthly_margin_p', ((tmm / (Number(total_sell_prem) + Number(total_sell_adv))) * 100).toFixed(2))
        await setFieldValue('total_annualised_margin', tmm * 12)
    }

    const handleSellCPMChange = async (type, values, opt, setFieldValue) => {
        calculateMargin(setFieldValue)
    }

    const handleSellPercentageChange = async (type, e, setFieldValue) => {
        let val = Number(e.replaceAll("%", "")) || 0

        const {
            adjusted_cpm_prem, adjusted_cpm_adv

        } = calcFormik?.current?.values

        if (type === "prem") {
            let cpm_prem = Math.round(((val * Number(adjusted_cpm_prem)) / 100) + Number(adjusted_cpm_prem))

            await setFieldValue("proposed_sell_cpm_prem", { label: cpm_prem, value: cpm_prem, name: `AUD ${cpm_prem} CPM` })
            calculateMargin(setFieldValue, false)
        }
        else if (type === "adv") {
            let cpm_adv = Math.round(((val * Number(adjusted_cpm_adv)) / 100) + Number(adjusted_cpm_adv))
            await setFieldValue("proposed_sell_cpm_adv", { label: cpm_adv, value: cpm_adv, name: `AUD ${cpm_adv} CPM` })
            calculateMargin(setFieldValue, false)
        }


    }

    const handleClientCPMPercentageChange = async (type, e, setFieldValue) => {
        let val = Number(e.replaceAll("%", "")) || 0
        const {
            adjusted_cpm_prem, adjusted_cpm_adv

        } = calcFormik?.current?.values

        if (type === "prem") {
            let cpm_prem = Math.round(((val * Number(adjusted_cpm_prem)) / 100) + Number(adjusted_cpm_prem))

            await setFieldValue("proposed_sell_cpm_prem", { label: cpm_prem, value: cpm_prem, name: `AUD ${cpm_prem} CPM` })
            calculateRefund(setFieldValue, false)
        }
        else if (type === "adv") {
            let cpm_adv = Math.round(((val * Number(adjusted_cpm_adv)) / 100) + Number(adjusted_cpm_adv))
            await setFieldValue("proposed_sell_cpm_adv", { label: cpm_adv, value: cpm_adv, name: `AUD ${cpm_adv} CPM` })
            calculateRefund(setFieldValue, false)
        }


    }


    const handleManageService = async (v, s, n) => {
        if (n) await s(n, v)
        setIsManagedService(v)

        let p = createCPMObject(isAU ? minCPM : minCPM + 2)
        let a = createCPMObject(isAU ? advCPM : advCPM + 2)
        if (!v) {
            p = PREMIUM_CPM_DISCOUNT_ALL[0]
            a = ADVANCED_CPM_DISCOUNT_ALL[0]
        }

        await s("premium_spend", p)
        await s("advanced_spend", a)

        if (model === 2) {
            await s("premium_spend_set", p)
            await s("advanced_spend_set", a)

            setPrem_spend_set(p)
            setAdv_spend_set(a)
        }
        handleCalculation(s)
        setPrem_base_set(p)
        setAdv_base_set(a)


    }


    const renderCalcModal = () => {
        return (
            <Modal
                isOpen={isCalcModalOpen}
                onRequestClose={(e) => setIsCalcModalOpen(false)}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container"
                ariaHideApp={false}
                bodyOpenClassName="overflow-hidden"
            >
                <CalcModalForm
                    setIsCalcModalOpen={setIsCalcModalOpen} calcFormik={calcFormik} Schema={Schema(totalSpendSumCheck)}
                    PREMIUM_CPM={PREMIUM_CPM} ADVANCED_CPM={ADVANCED_CPM} calculateFees={calculateFees} showCalculation={showCalculation}
                    setShowCalculation={setShowCalculation} calculateSaasFees={calculateSaasFees}
                    modelL={modelL} totalSpendSumCheck={totalSpendSumCheck} country={country} CMPModel={props.CMPModel}
                    pm2={pm2} ad2={ad2}

                />
            </Modal>
        )
    }

    const renderRefundCalcModal = () => {
        return (

            <Modal
                isOpen={isRefundCalcModalOpen}
                onRequestClose={(e) => setIsRefundCalcModalOpen(false)}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container"
                ariaHideApp={false}
                bodyOpenClassName="overflow-hidden"
            >
                <RefundCalcModal

                    setIsRefundCalcModalOpen={setIsRefundCalcModalOpen} calcFormik={calcFormik} DiscountSchema={DiscountSchema}
                    PREMIUM_CPM_DISCOUNT_ALL={PREMIUM_CPM_DISCOUNT_ALL} ADVANCED_CPM_DISCOUNT_ALL={ADVANCED_CPM_DISCOUNT_ALL}
                    handleClientCPMPercentageChange={handleClientCPMPercentageChange}
                    prem_base_set={prem_base_set} adv_base_set={adv_base_set} prem_spend_set={prem_spend_set}
                    adv_spend_set={adv_spend_set} country={country}
                    CMPModel={props?.CMPModel}
                    isManagedService={isManagedService} handleManageService={handleManageService} calculateRefundRates={calculateRefundRates} calculateRefund={calculateRefund}
                    totalSpendSumCheck={totalSpendSumCheck}
                />
            </Modal>
        )
    }

    const renderDiscountCalcModal = () => {

        return (
            <Modal
                isOpen={isDiscountCalcModalOpen}
                onRequestClose={(e) => setIsDiscountCalcModalOpen(false)}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container"
                ariaHideApp={false}
                bodyOpenClassName="overflow-hidden"
            >
                <DiscountCalcModal

                    prem_base_set={prem_base_set} prem_spend_set={prem_spend_set} adv_base_set={adv_base_set}
                    adv_spend_set={adv_spend_set}
                    setIsDiscountCalcModalOpen={setIsDiscountCalcModalOpen} calcFormik={calcFormik}
                    totalSpendSumCheck={totalSpendSumCheck} country={country} CMPModel={props?.CMPModel} setShowCalculation={setShowCalculation} calculateMargin={calculateMargin}
                    DiscountSchema={DiscountSchema} calculateDiscountFees={calculateDiscountFees} isManagedService={isManagedService}
                    handleManageService={handleManageService} handleSellCPMChange={handleSellCPMChange} handleSellPercentageChange={handleSellPercentageChange}
                    PREMIUM_CPM_DISCOUNT_ALL={PREMIUM_CPM_DISCOUNT_ALL} ADVANCED_CPM_DISCOUNT_ALL={ADVANCED_CPM_DISCOUNT_ALL} showDiscountMargin={showDiscountMargin} setShowDiscountMargin={setShowDiscountMargin}


                />

            </Modal>
        )
    }


    const handleSubmit = (values) => {

        let approver_id = role === COMMERCIAL_MANAGER ? props.user.id : typeof values.commercials_approver === "object" ? values.commercials_approver.user_id : values.commercials_approver
        let approved_date = role === COMMERCIAL_MANAGER ? formatSubmitDate(new Date()) : ""
        let approver_name = role === COMMERCIAL_MANAGER ? `${props.user.firstName} ${props.user.lastName}` : typeof values.commercials_approver === "object" ? `${values.commercials_approver.firstName} ${values.commercials_approver.lastName}` : ""

        let bigad_approver_id = role === C_LEVEL ? `${props.user.id}` : ""
        let bigad_approver = role === C_LEVEL ? `${props.user.firstName} ${props.user.lastName}` : ""
        let bigads_approved_date = role === C_LEVEL ? formatSubmitDate(new Date()) : ""

        if (isEdit) {
            let v = { ...props.agreementProfile }
            approver_id = v.approver_id ? v.approver_id : approver_id
            approved_date = v.approved_date ? v.approved_date : approved_date
            approver_name = v.approver ? v.approver : approver_name

            bigad_approver_id = v.bigads_approver_id ? v.bigads_approver_id : bigad_approver_id
            bigad_approver = v.bigads_approver ? v.bigads_approver : bigad_approver
            bigads_approved_date = v.bigads_approved_date ? v.bigads_approved_date : bigads_approved_date

        }

        let approved = {
            "approver_id": approver_id,
            "approved_date": approved_date,
            "approver": approver_name,
            "bigads_approver_id": bigad_approver_id,
            "bigads_approver": bigad_approver,
            "bigads_approved_date": bigads_approved_date
        }

        let v = getSumbitValues(isEdit ? props.agreementProfile : values,
            modelL, model, props.user, tcsURL,
            isEdit && props.agreementProfile.status !== "DRAFT" ? "APPROVED" : role === COMMERCIAL_MANAGER ? "PENDING BIGADS APPROVAL" : role === BIG_MOBILE_SALESPERSON ? "DRAFT" : "PENDING CLIENT APPROVAL",
            country, approved, isEdit)
        
        pdfTrigger(isEdit ? props.agreementProfile : values)
        props.saveCommercialsAgreement(v)
    }
    const handleDateChange = async (s, e, setFieldValue) => {
        const daysBetween = differenceInDays(e, s);
        if (daysBetween < 90) {
            const today = s;
            const ninetyDaysFromNow = new Date();
            ninetyDaysFromNow.setDate(today.getDate() + 90);
            await setFieldValue("startDate", s)
            await setFieldValue("endDate", ninetyDaysFromNow)
            notify("Agreement Period must be greater than 90 days.", "e")
            return
        }
        let dividen = daysBetween / 4

        await setFieldValue("startDate", s)
        await setFieldValue("endDate", e)
        await setFieldValue("first_spend_date", s)
        await setFieldValue("second_spend_date", new Date().setDate(s.getDate() + dividen))
        await setFieldValue("third_spend_date", new Date().setDate(s.getDate() + dividen * 2))
        await setFieldValue("fourth_spend_date", new Date().setDate(s.getDate() + dividen * 3))

        pdfTrigger()

    }

    const onInfoClick = (dealTypeFormatId) => {
        props.getAllObjectives()
        props.getDealTypeFormatCarousel(dealTypeFormatId)
        setIsCarouselModalOpen(true)
    }

    const closeInfoModal = () => {
        props.emptyDealTypeFormatCarousel()
        setIsCarouselModalOpen(false)
    }

    const filterAdsByObjective = (e) => {
        setObj(e.target.value)
    }

    const renderHeader = (onRequestClose) => {
        const { objectives } = props

        return (
            <div className="d-flex flex-column flex-sm-row justify-content-between  pt-3">
                <h3 className="font-weight-bold pt-2 px-4">Ad Formats</h3>
                <div className="d-flex flex-wrap kpi-select pt-2">
                    <StatusFilter status={obj} onChange={filterAdsByObjective} isDefault={false} label="KPI:"
                        placeholder="Choose" optionData={objectives && objectives.data}
                        labelKey="name"
                        valueKey="id"
                    />

                </div>
                <div className="fas fa-times-circle fa-2x cursor-pointer bb-modal-close" onClick={() => onRequestClose()}></div>
            </div>
        );
    }

    const renderCarouselModal = () => {
        const { formatCarousel, formatCarouselLoading } = props;
        let filteredFormats = formatCarousel.filter(format => format.objectiveId.includes(obj));
        return (
            !formatCarouselLoading && <Modal
                isOpen={isCarouselModalOpen}
                onRequestClose={() => closeInfoModal()}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container mw-900 "
                ariaHideApp={false}
                bodyOpenClassName="overflow-hidden"
            >
                <div className="carousel-container bb-format-container">
                    {renderHeader(() => closeInfoModal())}
                    {<FormatCarouselModal formatCarousel={filteredFormats} onRequestClose={() => closeInfoModal()} />}


                </div>
            </Modal>
        )
    }

    const pdfTrigger = (values = false) => {
        let v = values ? { ...values } : { ...formik.current.values }
        
        !isEdit && !isDiscountCalcModalOpen && !isRefundCalcModalOpen && generatePDF(modelL, model, v, props.generateTcs, typeof v?.company_name === "object" ? v?.company_name.id : props.user.networks)

    }

    const handleInit = (netwrokCountry, id, m, isEdit = false) => {
        let isA = netwrokCountry === 'AU'
        setIsAU(isA)
        
        props.getCMPByCountryModel(isA ? "AU" : "NZ", modelL || m)
        !isEdit && props.getallCommercialUsers(id)

        let minC = isA ? 20 : 15
        let maxC = isA ? 50 : 35
        setMinCPM(minC);
        setMaxCPM(maxC);
        setAdvCPM(isA ? 24 : 17);
        let prem = generateCPMArray(minC, maxC)
        setPREMIUM_CPM(prem);
        setADVANCED_CPM([...prem]);
        let prem_dis = isA ? generateCPMArray(18, 35) : generateCPMArray(15, 35)
        let adv_dis = isA ? generateCPMArray(22, 35) : generateCPMArray(17, 35)
        setPREMIUM_CPM_DISCOUNT_ALL(prem_dis);
        setADVANCED_CPM_DISCOUNT_ALL(adv_dis);
        setTotalSpendSumCheck(isA ? 50000 : 20000);
        setPm2(prem[0])
        setAd2(prem[isA ? 4 : 2])
        setPrem_base_set(prem_dis[0])
        setAdv_base_set(adv_dis[0])
    }


    const handleCompanyChange = (v) => {
        
        if(v.commercial_rate_id){
            pushLocation("/commercials/rates/" + v.commercial_rate_id)
        }
        else{
            let netwrokCountry = v.billingCountry || ""
            netwrokCountry = getCountry(netwrokCountry)
            setCountry(netwrokCountry)
            handleInit(netwrokCountry, v.id)
        }       
    }



    const { networks, role } = props;
    const today = new Date();
    const ninetyDaysFromNow = new Date();
    ninetyDaysFromNow.setDate(today.getDate() + 90);

    return (
        <>
            <BreadCrumb checkDeep={false} name="Commercials - Setting the rates" />
            {renderCarouselModal()}
            
            <Loader loading={props.loading ? "true" : "false"} fullpage="true" />

            <div className="d-flex flex-column justify-content-center m-3  ">
                <div className="d-flex flex-row align-items-center">
                    <h4 className='text-center flex-grow-1'>{model === 1 ? "Bud Enterprise Independent Commercials" : model === 2 ? "Bud Enterprise Unified Volume Rewards Commercials" : model === 3 ?"Bud Enterprise Unified Volume Discount Commercials": ""}</h4>
                    <RenderEnterpriseLogo model={modelL}></RenderEnterpriseLogo>
                </div>

                <div>
                    <Formik
                        innerRef={formik}
                        enableReinitialize={false}
                        validationSchema={isEdit ? false: getMainSchema()}
                        initialValues={{
                            ...getInitVals(isAU, model, PREMIUM_CPM_DISCOUNT_ALL, ADVANCED_CPM_DISCOUNT_ALL, role === COMMERCIAL_MANAGER ? props.user.networkName : "",
                                PREMIUM_CPM, role === COMMERCIAL_MANAGER ? props.user.firstName + " " + props.user.lastName : "", role === C_LEVEL ? `${props.user.firstName} ${props.user.lastName}` : "Andrew O'Shea")
                        }}       
                        onSubmit={() => {}}                
                    >{({ setFieldValue, dirty, errors, isSubmitting, values, submitCount, isValidating }) => (
                        <Form autoComplete="off" className="form-row bb-form-commercials bb-form bb-form-mw pt-4 bb-form-right-align-2-col">
                            <div className="col-md-12">

                                {role === COMMERCIAL_MANAGER || isEdit ?
                                    <FastField className="mb-1" containerClassName={containerClass}
                                        inputContainerClassName={classNames("maxWidth-400", inputClass)}
                                        disabled
                                        labelClassName={labelClass} label={"Account Company Name"} placeholder={"Name..."}
                                        name={'company_name'} component={FormInput} type={'text'}
                                    />

                                    :
                                    <FastField className="mb-1" containerClassName={containerClass}
                                        inputContainerClassName={classNames("maxWidth-400", inputClass)}
                                        disabled={isEdit}
                                        component={SelectInput} isLoading={networks && networks.loading}
                                        options={networks && networks.data} placeholder="Choose..."
                                        selectValue="id" selectLabel="name"
                                        labelClassName={labelClass} label={"Account Company Name"}
                                        name={'company_name'}
                                        onChange={(v) => handleCompanyChange(v)}
                                        disableThisField="isCommercial"
                                        disableThisBoolean={true}
                                    />

                                }

                                {values.company_name ? <>

                                    <div className={containerClass}>
                                        <label className={classNames(labelClass, "align-self-center")}>Agreement Period</label>
                                        <div className={inputClass}>
                                            <DateRPicker light initStartDate={values.startDate} initEndDate={values.endDate}
                                                noLabel={true} onDateChange={(s, e) => handleDateChange(s, e, setFieldValue)}
                                                minDate={new Date()}
                                                maxDate={new Date(2028, 1, 1)}
                                                disabled={props?.params?.id ? true : false}
                                                containerClassName="light-date-picker"

                                            />
                                        </div>
                                    </div>
                                    {isBudIndependent ?
                                        <>{renderIndependent(values, containerClass, inputClass, labelClass, isEdit)}</>

                                        : <Field containerClassName={containerClass}
                                            inputContainerClassName={classNames(inputClass, "d-flex align-items-center")}
                                            labelClassName={classNames(labelClass, "align-self-center")}
                                            className="col-3"
                                            disabled={isEdit}

                                            additionalComponent={isBudIndependent ? null : <>
                                                <CriteoModal />
                                                {values.receive_enabled ? renderFastField("", "Recipient’s email address", "email", "text", isEdit) : null}
                                            </>}
                                            label={"Receive Monthly Publisher Statement from Criteo"} component={CheckBoxInput}
                                            name="receive_enabled" type="checkbox"
                                            checkedValue={values.receive_enabled} />}


                                    {<Field name='monthly_spend' component={SelectInput} options={monthlySpendBand}
                                        isEdit={true} placeholder="0" labelClassName={labelClass}
                                        className="order-1 flex-grow-1"
                                        disabled={isEdit}

                                        onChange={(val) => handleCalculation(setFieldValue)}
                                        inputContainerClassName={classNames(inputClass, "maxWidth-400 d-flex flex-row flex-wrap")} containerClassName={containerClass}
                                        additionalComponent={<FeeModal model={model} />}
                                        label={"Monthly Spend Commitment"} />}

                                    {isBudIndependent ?
                                        <>
                                            <div className={containerClass}>
                                                <label className={classNames(labelClass, "align-self-center")}>Set Client Facing Minimum CPM Rate</label>
                                                <div className="align-self-center col-7">
                                                    <div className='d-flex'>
                                                        <div className='mr-3'>
                                                            <label className='mb-0'>Premium
                                                                <span>
                                                                    <i className="fas pl-2 fa-info-circle cursor-pointer" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        onInfoClick('988f04d6-9173-41e2-b351-97da7373e083')
                                                                    }}> </i>
                                                                </span></label>
                                                            <FastField name='premium_spend' component={SelectInput} options={PREMIUM_CPM}
                                                                isEdit={true} placeholder="$0" prefixLabel="$" disabled={isEdit}
                                                                onChange={(val) => handleCalculation(setFieldValue)}
                                                                label="" />
                                                        </div>
                                                        <div className='mr-3'>
                                                            <label className='mb-0'>Advanced<span>
                                                                <i className="fas pl-2 fa-info-circle cursor-pointer" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    onInfoClick('fb3bfc90-796c-46fa-b86d-84ab2818102b')
                                                                }}> </i>
                                                            </span></label>
                                                            <FastField name='advanced_spend' disabled={isEdit} component={SelectInput} options={ADVANCED_CPM}
                                                                onChange={(val) => handleCalculation(setFieldValue)}
                                                                isEdit={true} placeholder="$0" prefixLabel="$"
                                                                label="" />
                                                        </div>
                                                    </div>
                                                    <label className='bb-font_13px align-content-center mb-0'>{"CPM prices can be increased at campaign level, but not lowered"}</label>
                                                </div>
                                            </div>


                                            <FastField className="mb-1 disabled maxWidth-400  w-137" containerClassName={containerClass} disabled={isEdit}
                                                inputContainerClassName={classNames(inputClass, "d-flex flex-row flex-wrap")} labelClassName={labelClass}
                                                label={"Estimated Usage-Based SaaS Fee"} placeholder="0.00%" name="percentage" component={PercentageInput} />


                                            {!isEdit ?<div className={classNames(containerClass, "justify-content-center")}>
                                                <label className={labelClass}></label>
                                                <div className="align-self-center col-7">
                                                    <button type="button" onClick={() => { setIsCalcModalOpen(true) }} className={"p-2 bb-button roas-button border-0 mt-0 mw-unset"} >
                                                        <i className="fas fa-calculator"></i> Usage-Based SaaS Fee Calculator
                                                    </button>
                                                </div>
                                            </div>
                                            : null
                                            }

                                            <Field name='support_options' component={SelectInput} options={SUPPORT_OPTIONS}
                                                isEdit={true} placeholder="0" labelClassName={labelClass}
                                                className="order-1 flex-grow-1"
                                                disabled={isEdit}
                                                onChange={(val) => { }}
                                                inputContainerClassName={classNames(inputClass, "maxWidth-400 d-flex flex-row flex-wrap")} containerClassName={containerClass}
                                                additionalComponent={<SupportModal />}
                                                label={"Support Options"} />
                                        </>
                                        : <>
                                            <Field type="hidden" name="managed_service" />
                                            <div className={containerClass}>
                                                <label className={classNames(labelClass, "align-self-center bb-white-space-nowrap")}>Own Trading Desk</label>
                                                <div className={classNames("d-flex align-self-center col-7 pt-2")}>
                                                    {/* <label className={classNames("text-right align-self-center bb-white-space-nowrap")}></label> */}
                                                    <div className="align-self-center">
                                                        {<Toggle
                                                            key="toggle-measurement"
                                                            checked={values.managed_service}
                                                            className="toggle-status bb-toggle toggle-blue"
                                                            icons={false}
                                                            disabled={isEdit}
                                                            onChange={(e) => handleManageService(e.target.checked, setFieldValue, 'managed_service')}
                                                        />}
                                                    </div>
                                                    <label className={classNames("align-self-center bb-white-space-nowrap ml-2")}>Big Ads Managed Service</label>
                                                </div>
                                            </div>
                                            <div className={containerClass}>
                                                <label className={classNames(labelClass, "align-self-center")}>Standard CPM Rate</label>
                                                <div className="align-self-center col-7">
                                                    <div className='d-flex'>
                                                        <div className='mr-2 ww-100'>
                                                            <label className='mb-0'>Premium
                                                                <span>
                                                                    <i className="fas pl-2 fa-info-circle cursor-pointer" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        onInfoClick('988f04d6-9173-41e2-b351-97da7373e083')
                                                                    }}> </i>
                                                                </span></label>
                                                            <FastField name='premium_spend' component={SelectInput} options={PREMIUM_CPM_DISCOUNT_ALL} disabled={true}
                                                                isEdit={true} placeholder="$0" prefixLabel="$"
                                                                containerClassName="pr-2"
                                                                label="" />
                                                        </div>
                                                        <div className='mr-2 ww-100'>
                                                            <label className='mb-0'>Advanced<span>
                                                                <i className="fas pl-2 fa-info-circle cursor-pointer" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    e.preventDefault();
                                                                    onInfoClick('fb3bfc90-796c-46fa-b86d-84ab2818102b')
                                                                }}> </i>
                                                            </span></label>
                                                            <FastField name='advanced_spend' component={SelectInput} options={ADVANCED_CPM_DISCOUNT_ALL} disabled={true}
                                                                isEdit={true} placeholder="$0" prefixLabel="$"
                                                                containerClassName="pr-2"
                                                                label="" />
                                                        </div>


                                                    </div>

                                                </div>

                                            </div>
                                            <div className={containerClass}>
                                                <label className={classNames(labelClass, "align-self-center")}>Discounted CPM Rate</label>
                                                <div className="align-self-center col-7">
                                                    <div className='d-flex'>
                                                        <div className='mr-2 ww-100'>
                                                            <FastField name='set_adjusted_cpm_prem' isEdit={true} placeholder="0" prefixLabel="$"
                                                                minValue={0} decimalScale={2} component={NumberFormatInput} disabled={isEdit}
                                                                className="text-right disabled" prefixLabelClassname="disabled"
                                                                label="" labelClassName="d-none" />
                                                        </div>


                                                        <div className='mr-2 ww-100'>
                                                            <FastField name='set_adjusted_cpm_adv' isEdit={true} placeholder="0" prefixLabel="$"
                                                                minValue={0} decimalScale={2} component={NumberFormatInput} disabled={isEdit}
                                                                className="text-right disabled" prefixLabelClassname="disabled"
                                                                label="" labelClassName="d-none" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={containerClass}>
                                                <label className={classNames(labelClass, "align-self-center")}>
                                                    Set Client Facing Minimum CPM Rate {model == 3 ? <small>(optional)</small> : null}</label>
                                                <div className="align-self-center col-7">
                                                    <div className='d-flex'>
                                                        <div className='mr-2 ww-100 pt-1'>
                                                            <FastField name='premium_spend_set' disabled={isEdit} component={SelectInput} options={PREMIUM_CPM_DISCOUNT_ALL}
                                                                isEdit={true} placeholder="$0" prefixLabel="$"
                                                                onChange={(val) => handleSetSellCPM(setFieldValue, setPrem_spend_set, setAdv_spend_set, setShowDiscountMargin, handleCalculation, formik, model)}
                                                                containerClassName="pr-2"
                                                                label="" />
                                                        </div>
                                                        <div className='mr-2'>
                                                            <FastField name='advanced_spend_set' disabled={isEdit} component={SelectInput} options={ADVANCED_CPM_DISCOUNT_ALL}
                                                                onChange={(val) => handleSetSellCPM(setFieldValue, setPrem_spend_set, setAdv_spend_set, setShowDiscountMargin, handleCalculation, formik, model)}
                                                                inputContainerClassName="d-flex flex-row flex-wrap "
                                                                className="order-1 flex-grow-1 ww-100 pr-2"
                                                                isEdit={true} placeholder="$0" prefixLabel="$"
                                                                containerClassName="pr-2"

                                                                additionalComponent={model == 3 ? <DiscountSellCPMModal /> : null}
                                                                label="" />
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </>}

                                    {model === 2 ?
                                        <FastField disabled={isEdit} className="mb-1 disabled maxWidth-400  w-137" containerClassName={containerClass}
                                            inputContainerClassName={classNames(inputClass, "d-flex flex-row flex-wrap")} labelClassName={labelClass}
                                            label={"Rewards Percentage"} placeholder="0.00%" name="percentage_refund" component={PercentageInput} />
                                        : model === 3 ?
                                            <FastField disabled={isEdit} className="mb-1 disabled maxWidth-400  w-137" containerClassName={containerClass}
                                                inputContainerClassName={classNames(inputClass, "d-flex flex-row flex-wrap")} labelClassName={labelClass}
                                                label={"Discount Percentage"} placeholder="0.00%" name="percentage_discount" component={PercentageInput} />
                                            : null}

                                </> : null}

                            </div>
                            {values.company_name ? <>

                                {!isBudIndependent && !isEdit ?
                                    <div className={classNames(containerClass, "justify-content-center")}>
                                        <label className={labelClass}></label>
                                        <div className="align-self-center col-7">
                                            <button type="button" onClick={() => { model === 3 ? setIsDiscountCalcModalOpen(true) : setIsRefundCalcModalOpen(true) }} className={"p-2 bb-button roas-button border-0 mt-0 mw-unset"} >
                                                <i className="fas fa-calculator"></i> {`${model === 2 ? "Rewards Calculator" : "Discount Calculator"} `}
                                            </button>
                                        </div>
                                    </div>
                                    : null}
                                <div className={containerClass}>
                                    <label className={classNames(labelClass, "align-self-center pt-4")}>Lock-In Spend Review Dates</label>
                                    <div className="align-self-center col-7">
                                        <div className='d-flex'>
                                            <div className='mr-3 d-flex flex-column align-items-center'>
                                                <label className='mb-0'><i className="fas fa-calendar-alt" />
                                                    <span> 1<sup>st</sup>
                                                    </span></label>
                                                <FastField
                                                    labelClassName="mr-2" disabled={isEdit}
                                                    containerClassName="d-flex mr-2" inputContainerClassName=""
                                                    dateFormat="yyyy-MM-dd"
                                                    label="" name="first_spend_date" component={CustomDatePicker} setFieldValue={setFieldValue} />
                                            </div>
                                            <div className='mr-3 d-flex flex-column align-items-center'>
                                                <label className='mb-0'><i className="fas fa-calendar-alt" />
                                                    <span> 2<sup>nd</sup>
                                                    </span></label>
                                                <FastField
                                                    labelClassName="mr-2" disabled={isEdit}
                                                    containerClassName="d-flex mr-2" inputContainerClassName="" dateFormat="yyyy-MM-dd"
                                                    label="" name="second_spend_date" component={CustomDatePicker} setFieldValue={setFieldValue} />
                                            </div>
                                            <div className='mr-3 d-flex flex-column align-items-center'>
                                                <label className='mb-0'><i className="fas fa-calendar-alt" />
                                                    <span> 3<sup>rd</sup>
                                                    </span></label>
                                                <FastField
                                                    labelClassName="mr-2" disabled={isEdit}
                                                    containerClassName="d-flex mr-2" inputContainerClassName="" dateFormat="yyyy-MM-dd"
                                                    label="" name="third_spend_date" component={CustomDatePicker} setFieldValue={setFieldValue} />
                                            </div>
                                            <div className='mr-3 d-flex flex-column align-items-center'>
                                                <label className='mb-0'><i className="fas fa-calendar-alt" />
                                                    <span> 4<sup>th</sup>
                                                    </span></label>
                                                <FastField
                                                    labelClassName="mr-2" disabled={isEdit}
                                                    containerClassName="d-flex mr-2" inputContainerClassName=""
                                                    dateFormat="yyyy-MM-dd"
                                                    label="" name="fourth_spend_date" component={CustomDatePicker} setFieldValue={setFieldValue} />
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className="d-flex flex-row bb-border-gray rounded py-3 mb-2 mx-auto flex-wrap">
                                    {renderTAndC()}
                                    <div className='row mx-auto'>
                                        <div className='col-9 pr-0'>
                                            <div className='d-flex row mb-3 col-12 pr-0'>
                                                <div className='col-7'>
                                                    {role === COMMERCIAL_MANAGER || isEdit ? renderFastField("Authorised Approver", "TBA", "commercials_approver", "text", true) :

                                                        <FastField className="mb-1" disabled={isEdit} containerClassName="form-row col-md-12  align-items-center" inputContainerClassName="col-6"
                                                            labelClassName="col-6 text-right mb-1 pt-0" label="Authorised Approver" placeholder="TBA"
                                                            name="commercials_approver" component={SelectInput} isEdit={isEdit}
                                                            options={props.commercialUsers || []}
                                                            selectValue="user_id" selectLabel={["firstName", "lastName"]}
                                                        />


                                                    }
                                                </div>
                                                <div className='col-3'>
                                                    <div className='d-flex'>
                                                        <FastField
                                                            labelClassName="mr-2"
                                                            disabled={true}
                                                            containerClassName="d-flex mr-2" inputContainerClassName=""
                                                            label="Date" name="commercials_approved_date" component={CustomDatePicker} setFieldValue={setFieldValue} />
                                                        <button type="button" className="bb-button bb-bg_blue ripple" ref={aaButtonRef}
                                                            onClick={() => handleApproveClick(values, errors)}
                                                            //title='Read T&Cs'
                                                            disabled={role === COMMERCIAL_MANAGER && !approvalAA && scrollTCsAtBottom ? false : true}>{approvalAA ? "Approved" : "Approve"}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex row col-12 pr-0'>
                                                <div className='col-7'>
                                                    {renderFastField("Big Ads Approver", "Jack Huang", "bigads_approver", "text", true)}
                                                </div>
                                                <div className='col-3'>
                                                    <div className='d-flex'>
                                                        <FastField
                                                            labelClassName="mr-2"
                                                            disabled={true}
                                                            containerClassName="d-flex mr-2" inputContainerClassName=""
                                                            label="Date" name="bigads_approved_date" component={CustomDatePicker} setFieldValue={setFieldValue} />
                                                        <button type="button" className="bb-button bb-bg_blue ripple" ref={baButtonRef}
                                                            onClick={() => handleApproveClick(values, errors)} 
                                                            disabled={ !approvalBA && (INTERNAL_USERS.includes(role)) ? false : true} >
                                                                {approvalBA 
                                                                    ? "Approved" 
                                                                    : (role === BIG_MOBILE_SALESPERSON || role === SALES_DIRECTOR) 
                                                                        ?  (props.agreementProfile?.status !== "APPROVED" || props.agreementProfile?.status === "DRAFT") ? 
                                                                            "Requested" :"Request" 
                                                                        : "Approve"}
                                                                    </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <AgreementModal url={tcsURL} addLeftMargin={props.agreementProfile?.status === "TERMINATED"}/>
                                        

                                            {props.agreementProfile?.status === "TERMINATED" ? null :
                                                <div className="col">
                                                    <div className='d-flex row mb-3'>
                                                        {
                                                            role === COMMERCIAL_MANAGER && approvalAA ? <button type="button" onClick={() => handleTerminate()} className="bb-button bb-back-button" disabled={false}>Terminate</button>
                                                                :
                                                                <button type="button" onClick={() => pushLocation("/commercials")} className="bb-button bb-back-button" disabled={false}>Cancel</button>
                                                        }
                                                    </div>
                                                    {role === COMMERCIAL_MANAGER && approvalAA ? null :
                                                        <div className='d-flex row mb-3'>
                                                            <button type="button" className="bb-button mr-3 w-100" onClick={() => { pushLocation("/commercials") }}>Done</button>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        

                                    </div>
                                </div>

                            </> : null}


                        </Form>
                    )}
                    </Formik>
                </div>

                {isReady ? renderCalcModal() : null}
                {isReady ? renderDiscountCalcModal() : null}
                {isReady ? renderRefundCalcModal() : null}
            </div>
        </>
    )
}
const mapStateToProps = state => ({


    objectives: state.showcase.helpers.objectives || [],
    formatCarousel: state.audiences.Contextual.formatCarousel || [],
    formatCarouselLoading: state.audiences.Contextual.formatCarouselLoading,
    user: state.user,
    role: state.user.role,
    networks: state.helpers.networks || [],

    CMPModel: state.settings.others.cmp.CMPModel || [],

    commercialUsers: state.settings.users.allUsers.commercialUsers || [],

    agreementTCS: state.budenterprise.commercials.agreementTCS || "",
    agreementTCSUpdate: state.budenterprise.commercials.agreementTCSUpdate || false,

    loadingTCS: state.budenterprise.commercials.loadingTCS || false,
    loading: state.budenterprise.commercials.loading || false,

    agreementSave: state.budenterprise.commercials.agreementSave || false,
    agreementSaveResp: state.budenterprise.commercials.agreementSaveResp || false,
    agreementSaveRespData: state.budenterprise.commercials.agreementSaveRespData || false,

    agreementProfile: state.budenterprise.commercials.agreementProfile || false,
    agreementProfileResp: state.budenterprise.commercials.agreementProfileResp || false,
    agreement: state.budenterprise.commercials.agreement,


});
const mapDispatchToProps = {

    getDealTypeFormatCarousel,
    emptyDealTypeFormatCarousel,
    getAllNetworksUser,
    emptyNetworks,
    getCMPByCountryModel,

    getAllObjectives, emptyObjectives, emptyCMPCountry,
    getallCommercialUsers, generateTcs,
    saveCommercialsAgreement,
    getCommercialsAgreementById,
    EmptyCommercialsAgreementById,
    getCommercialsAgreement

};
export default (connect(mapStateToProps, mapDispatchToProps)(Rates));