import { CMPConstants } from "./cmp.constants";
import axios from "axios";
import { toFormData } from "../../../../components/utilities/customFunctions";
import { config } from "../../../../helpers";
const ROOT_URL_API = config.apiServer + "/api";

const loading = (status) => {
    return { type: CMPConstants.CMP_LOADING_STATUS, payload: status };
}

export const getAllCMPPaginate = ({pageSize = 10, page = 1, sortby = "", sorttype = "", model = "", search = "", monthly_spend_band = "", countryCode = ""}) => {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch({ type: CMPConstants.CMP_CURRENT_PAGE, payload: page });
        dispatch({ type: CMPConstants.CMP_LIMIT_PER_PAGE, payload: pageSize });
        // dispatch({ type: CMPConstants.CMP_SEARCH_VALUE, payload: search });
        // dispatch({ type: CMPConstants.CMP_STATUS_VALUE, payload: status });
        axios.get(`${ROOT_URL_API}/commercial_model_pricing_paginate?limit=${pageSize}&offset=${page}&sortby=${sortby}&sorttype=${sorttype}&model=${model}&combined_cpm=${search}&country=${countryCode}&monthly_spend_band=${monthly_spend_band}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(loading(false));
                if (response.data) {
                    dispatch({ type: CMPConstants.GET_ALL_CMP_PAGINATE_SUCCESS, payload: response.data });
                    dispatch({ type: CMPConstants.CMP_TOTAL_COUNT, payload: response.data.total_rows });
                }
                else {
                    dispatch({ type: CMPConstants.GET_ALL_CMP_PAGINATE_ERROR, payload: "error" });
                }
            }).catch(error => {
                dispatch(loading(false));
                dispatch({ type: CMPConstants.GET_ALL_CMP_PAGINATE_ERROR, payload: "error" });
            })
    }
}

export const addNewCMP = (values) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.post(`${ROOT_URL_API}/commercial_model_pricing`,
            toFormData(toCMPForm(values)),
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CMPConstants.ADD_CMP_SUCCESS, payload: response.data });
                }
                else {
                    dispatch({ type: CMPConstants.ADD_CMP_ERROR, payload: "Error" + Date.now() });
                }
                dispatch(loading(false));
            }).catch((error) => {
                dispatch({ type: CMPConstants.ADD_CMP_ERROR, payload: "Error" + Date.now() });
                dispatch(loading(false));
            });
    };
};
export const updateCMP = (values, id) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.post(`${ROOT_URL_API}/commercial_model_pricing/${id}`,
            toFormData(toCMPForm(values))
            ,
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CMPConstants.EDIT_CMP_SUCCESS, payload: response.data.data + Date.now() });
                }
                else {
                    dispatch({ type: CMPConstants.EDIT_CMP_ERROR, payload: "Error" + Date.now() });
                }
                dispatch(loading(false));
            }).catch((error) => {
                dispatch({ type: CMPConstants.EDIT_CMP_ERROR, payload: "Error" + Date.now() });
                dispatch(loading(false));
            });
    };
};

export const updateCMPInline = (id, name, value) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.post(`${ROOT_URL_API}/commercial_model_pricing_inline/${id}`,
            {
                col_name: name,
                col_value: value
            },
            { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CMPConstants.EDIT_CMP_SUCCESS, payload: response.data.data + Date.now() });
                }
                else {
                    dispatch({ type: CMPConstants.EDIT_CMP_ERROR, payload: "Error" + Date.now() });
                }
                dispatch(loading(false));
            }).catch((error) => {
                dispatch({ type: CMPConstants.EDIT_CMP_ERROR, payload: "Error" + Date.now() });
                dispatch(loading(false));
            });
    };
};
export const getCMPById = (id) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.get(`${ROOT_URL_API}/commercial_model_pricing/${id}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CMPConstants.GET_CMP_BY_ID, payload: response.data.data });
                }
                else {
                    dispatch({ type: CMPConstants.GET_CMP_BY_ID_ERROR, payload: false });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: CMPConstants.GET_CMP_BY_ID_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}

export const getCMPByCountryModel = (country, model) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.get(`${ROOT_URL_API}/commercial_model_pricing_info?model=${model}&country=${country}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CMPConstants.GET_CMP_BY_COUNTRY, payload: {data : response.data.data, date: Date.now()} });
                }
                else {
                    dispatch({ type: CMPConstants.GET_CMP_BY_COUNTRY_ERROR, payload: false });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: CMPConstants.GET_CMP_BY_COUNTRY_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}

export const emptyCMPProfile = () => {
    return (dispatch) => {
        dispatch({ type: CMPConstants.EMPTY_CMP_BY_PROFILE, payload: "" });
    };
}

export const emptyCMPCountry = () => {
    return (dispatch) => {
        dispatch({ type: CMPConstants.EMPTY_CMP_BY_COUNTRY, payload: "" });
    };
}

const toCMPForm = (values) => ({
    model: typeof values.model == "object" ? values.model.value : values.model,
    combined_cpm: values.combined_cpm,
    monthly_spend_band: typeof values.monthly_spend_band == "object" ? values.monthly_spend_band.value : values.monthly_spend_band,
    min_spend_band: typeof values.monthly_spend_band == "object" ? values.monthly_spend_band.min : values.min_spend_band,
    max_spend_band: typeof values.monthly_spend_band == "object" ? values.monthly_spend_band.max : values.max_spend_band,
    percentage: values.percentage,
    country: typeof values.country == "object" ? values.country.code : values.country,
})