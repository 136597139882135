import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../utilities/BreadCrumb'
import { pushLocation } from '../../utilities/customRoutes';
import "../../creative/report/report.scss"
import { getQueryStringParams } from '../../utilities/customFunctions';
import BuddyChart from '../../creative/report/reports/chart';
import FeesHistory from './history';
import FeesMonth from './month';

function SavingsDate(props) {

    const [isSpend, setIsSpend] = useState(false)
    const [isDate, setIsDate] = useState(false)
    const [isMonth, setIsMonth] = useState(false)
    const [isHistory, setIsHistory] = useState(false)

    let searchParams = getQueryStringParams(props.location.search);
    let isSavings = props.location.pathname.includes("savings")

    useEffect(() => {
        document.getElementById("root").classList.add("root-light");
        if (searchParams && searchParams.page) {
            switch (searchParams.page) {
                case "date":
                    setIsSpend(false)
                    setIsMonth(false)
                    setIsDate(true)
                    setIsHistory(false)
                    break;
                case "spend":
                    setIsSpend(true)
                    setIsMonth(false)
                    setIsDate(false)
                    setIsHistory(false)
                    break;
                case "month":
                    setIsMonth(true)
                    setIsDate(false)
                    setIsHistory(false)
                    setIsSpend(false)
                    break;
                case "history":
                    setIsHistory(true)
                    setIsDate(false)
                    setIsMonth(false)
                    setIsSpend(false)
                    break;
                default:
                    setIsDate(isSavings ? false : true)
                    setIsHistory(false)
                    setIsMonth(false)
                    setIsSpend(isSavings ? true: false)
            }
        }
        else {
            setIsDate(isSavings ? false : true)
            setIsHistory(false)
            setIsMonth(false)
            setIsSpend(isSavings ? true: false)
        }
        return () => {
            document.getElementById("root").classList.remove("root-light");
        }
        // eslint-disable-next-line
    }, [props])

    const renderChart = () => {
        return <div className="mt-4 chart-container">
            <h3 className="bb-sub-header-txt mb-4">{isSavings ? isSpend ? "Spend Efficiencies Year To Date" :"Volume Discount - Impressions Gained Year to Date" : "Volume Discount - Margin Year to Date"}</h3>

            <BuddyChart type={isSpend ? "mscolumn2d" :"scrollstackedcolumn2d"}
                width="100%"
                height="400"
                dataFormat="JSON"
                // dataSource={createDataSetSavings(!isSavings, isSpend)} 
                dataSource={[]} 

                /> 

            
        </div>
    }
    return (
        <>
            <BreadCrumb checkDeep={false} name={`My ${isSavings ? "Savings": "Margin" }`} />
            <div className="d-flex flex-column justify-content-center m-3  ">
                <div className=" d-flex mx-4 direction-button">
                    {isSavings ? 
                        <div className={isSpend ? "selected-page" : "pl-2 bb-body-txt"} onClick={() => {
                            pushLocation("/savings?page=spend")
                        }}>Spend Efficiencies</div>
                    : null
                    }
                    <div className={isDate ? "selected-page" : "pl-2 bb-body-txt"} onClick={() => {
                        pushLocation(`${isSavings ? "/savings" : "/margin" }?page=date`)
                    }}>{`${isSavings ? "Impressions Gained": "Margin To Date"}`}</div>
                    <div className={isMonth ? "selected-page" : "pl-2 bb-body-txt"} onClick={() => {
                        pushLocation(`${isSavings ? "/savings" : "/margin" }?page=month`)
                    }}>This Month's {`${isSavings ? "Savings": "Margin"}`}</div>
                    <div className={isHistory ? "selected-page" : "pl-2 bb-body-txt"} onClick={() => {
                        pushLocation(`${isSavings ? "/savings" : "/margin" }?page=history`)
                    }}>{`${isSavings ? "Savings History": "Margin History"}`}</div>

                </div>
                <div className="d-flex flex-column mx-4 p-3 mb-3 report-container">

                    {isHistory 
                    ? 
                        <FeesHistory isSavings={isSavings}/> 
                    : 
                        isMonth ? 
                            <FeesMonth isSavings={isSavings}/> 
                            : renderChart()}
                </div>
            </div>
        </>
    )
}

export default (SavingsDate);