class Config {
    constructor() {
        this.apiServer = "https://engine.bigbuddy.io";
        this.analyticServer = "https://analytics.bigbuddy.io"
        this.logServer = "https://engine-n.bigbuddy.io";
        this.previewServer = "https://preview.studio/";
        this.webServer = "https://buddydecarbonise.com/";
        this.budEnterpriseLocation = "https://bud-enterprise.com/"
    

        this.supplyPlatformDealPackage = "https://curate.themediagrid.com/package/"
        this.supplyPlatformDeal = "https://commercegrid.criteo.com/trading/demand-deals/"
        this.supplyPlatformPublisherDeal = "https://commercegrid.criteo.com/trading/supply-packages/"

        this.bbSettings = "bb-settings";
        this.dataLimit = () =>  JSON.parse(localStorage.getItem(this.bbSettings))?.pageSize || 10;
        this.dataLimitForDashboard = 9;
        this.dataLimitForReport = 4;
        this.activeTime = 120;
        this.viewTime = 120;

        this.host_bb = window.location.protocol + "//" + window.location.host + '';
 
        this.bbToken = "bb-user-token";
        
        this.accountId = "573b6a53-a5b5-404c-95e7-5a35fd31c3dd";
        this.tabcorpsID = "99b46ac9-9e75-48d6-a57a-7f0822a9ce53";

        this.isStaging = false;
        this.isBudEnterprise = false;
        this.GACode = 'G-WBY6F0CW96'



        this.tcsURL = "https://s3-ap-southeast-2.amazonaws.com/library.bigbuddy.io/download/Buddy's+T%26Cs.pdf";

        this.nexd = {
            fallback_campaign_id: "1pbIgv7Al6rThoRm",
            campaign_id: "TG3KZkHYB355NfR4",
            preview_url: "https://preview.nexd.com/?cre_id=",
            creative_url: "https://app.nexd.com/c/",
            analytics_url:"https://app.nexd.com/analytics/c/"
        }
        this.airtory = {
            fallback_campaign_id: "0ee7a0fe48699875bec9c0052f95791e",
            preview_url: "https://studio.airtory.com/ad-preview/",
            creative_url: "https://studio.airtory.com/#/campaigns/display/",
            // analytics_url:"https://studio.airtory.com/#/analytics/creative/"
            analytics_url:"https://studio.airtory.com/#/analytics/placement/"
        }
        
        this.primo = {
            token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmdJRCI6ImI0NzhmOGU3LTI5MjctNGFhOC04NDc2LTZlMzhhOWE4MDIwNyIsImlhdCI6MTYxNDE1NTIxMn0.ER2jiTu26SeqelUd9aSNk99lmXdG9hPZ-DgsU_KT03Y",
            customerId:  "usr_0vxaKXhI0gcMpXCYsk03oK",
            script: "https://script.visarity.com/pam/v3/pam-sdk.js?t=2330",
            preview_url: "https://live.visarity.com/campaigns/",
            creative_url: "https://primo.visarity.com/dashboard",
            analytics_url:"https://primo.visarity.com/analytics/"
        }

        this.tradeDesk = {
            creative_url: 'https://desk.thetradedesk.com/app/advertiser/'
        }   
        this.performanceP = {
            TC: "https://s3.ap-southeast-2.amazonaws.com/library.bigbuddy.io/download/Buddy's+Performance%2B+T%26Cs.pdf",
            image: "/images/performance+.png"
           
        }    
        this.performanceG = {
            TC: "https://s3.ap-southeast-2.amazonaws.com/library.bigbuddy.io/download/Buddy's+Performance+Guarantee+T%26Cs.pdf",
            image: "/images/performanceguarantee.png"
        }     
 

        if (process.env.REACT_APP_ENV === "development") {
            this.accountId = "5f06021c-14e3-445c-bb6d-51b8b6866386";
            this.tabcorpsID = "33e234e5-488e-49ca-8fb6-9a44a70f7757";
            this.apiServer = "https://staging2.bigbuddy.io";
            this.logServer = "https://staging-n.bigbuddy.io";
            this.analyticServer = "https://analytics-staging2.bigbuddy.io"
            this.previewServer = "https://test.preview.studio/";
            this.webServer = "https://test.buddydecarbonise.com/";
            this.budEnterpriseLocation = "https://test.bud-enterprise.com/"


            this.nexd.fallback_campaign_id = "j4GD2x5oR8loXvXB";
            this.nexd.campaign_id = "tcPNEHPGyIQRevCQ"; 
            this.airtory.fallback_campaign_id ="f10b5577e2f28a2eb381aaaf47918f35"
        

            this.isStaging = true;
            
        }

        this.ROOT_URL = this.apiServer;
        this.ROOT_URL_API = this.ROOT_URL + "/api";

        this.authToken = (withBearer = true) => {
            if (localStorage.getItem(this.bbToken)) {
                if (withBearer) {
                    return 'Bearer ' + localStorage.getItem(this.bbToken)
                }
                else {
                    return localStorage.getItem(this.bbToken);
                }
            }
            else {
                return false;
            }
        }  
        
        this.DEMO_DEAL_ID = 'velvedere'
        this.DEMO_DEAL_CO2_ID = '9149cf5d-b063-4d05-8cbc-9c99c2b9535f'
        if(window?.location?.origin.includes("bud-enterprise")){
            this.isBudEnterprise = true;
            this.GACode = "G-X01Q5LKT0H"
            document.title = "Bud Enterprise - By Big"
        }        

        let NewsCorpAccountID = ["815a8972-28e1-4a8b-bc9e-8e140af8d794", "37f7090f-9800-4cd2-9ed0-e693e2c53a5d"]
        
        this.isAccountforNewsCorp = (accountId) => {
            let id = typeof accountId === "object" ? accountId.id : accountId
            return NewsCorpAccountID.includes(id);
        }
        this.isAccountTabscorp = (accountId) => {
            let id = typeof accountId === "object" ? accountId.id : accountId
            return id === this.tabcorpsID;
        }


    }
}

export const config = new Config();
