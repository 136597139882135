import classNames from 'classnames';
import React, { useState } from 'react';
import Modal from "react-modal";
import { Document, Page, pdfjs } from 'react-pdf';

function AgreementModal(props) {

    pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

    const { url, addLeftMargin = false } = props;
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [numPages, setNumPages] = useState(null);
    const closeModal = () => { setIsModalOpen(false) }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    
    const renderTAndC = () => {
            return (
                <Document
                    file={url}
                    //   file={dealData['termsConditionsUrl'] || config.tcsURL}
                    // options={{ worker: '/pdf.worker.js', }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classNames('my-3 mx-auto bb-tcs-container form-control offset-cert', "large")}
                    externalLinkTarget="_blank"
                    loading="Loading T&Cs..."
                    noData="Error getting T&Cs."
                    error="Error getting T&Cs."
                >
                    {
                        numPages && Array.from(
                            new Array(numPages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    loading=""
                                    error=""
                                    width={540}
                                    renderAnnotationLayer={false}
                                />
                            ),
                        )
                    }
                </Document>
            )
        }

    const renderIframeModal = () => {
        return (
            <Modal
                isOpen={isModalOpen ? true : false}
                onRequestClose={closeModal}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container"
                bodyOpenClassName="overflow-hidden"
                ariaHideApp={false}
            >
                <div className='col-sm-12 col-md-12 d-flex py-5 flex-column justify-content-center'>
                    <>
                    <div className="d-flex flex-row justify-content-between">
                        <h5><strong>Active Agreement T&Cs</strong></h5>
                        <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-dark" onClick={(e) => closeModal(e)}>
                        </div>
                    </div>

                        { renderTAndC() }
                        
                        <button type="button" onClick={() => closeModal()} className="bb-button align-self-center mt-2" disabled={false}>Ok</button>
                    </>



                </div>
            </Modal>)
    }
    
    return (
        <>
            {isModalOpen ? renderIframeModal() : null}
            <button type="button" className={classNames("bb-button bb-bg_blue col bb-agreement mb-3 mr-4", addLeftMargin ? "ml-3": "")} disabled={false} onClick={() => {

                setIsModalOpen(true)
                
            }}>View Active Agreement</button>
        </>

    );
}
export default AgreementModal;