import React from "react";

const data = [
  { label: "Average Daily Spend this month", value: { "Australia" : "$3,166", "New Zealand" :"$800" } },
  { label: "Required Daily Spend this month", value: { "Australia" : "$3,333", "New Zealand" :"$1000" } },
  { label: "Forecast spend this month", value: { "Australia" : "$100,000", "New Zealand" :"$30,000" } },
  { label: "Forecast spend gap this month", value: { "Australia" : "-$5,000", "New Zealand" :"-$6,000" } },
  { label: "Forecast spend this quarter", value: { "Australia" : "$335,000", "New Zealand" :"$91,000" } },
  { label: "Forecast spend gap this quarter", value: { "Australia" : "+$35,000", "New Zealand" :"+$1,000" } }
];

const SpendingCard = ({ label, value }) => {
  const isNegative = value.includes("-$");
  const isPositive = value.includes("+$");

  return (
    <div style={styles.card}>
      <span style={styles.label}>{label}</span>
      <span style={{ ...styles.value, color: isNegative ? "red" : isPositive ? "green" : "black" }}>
        {value}
      </span>
    </div>
  );
};

const SpendingSummary = (props) => {
  return (
    <div style={styles.container} className="bb-border-lightgray">
      {/* {data.map((item, index) => (
        <SpendingCard key={index} label={item.label} value={item.value[props.country]} />
      ))} */}
    </div>
  );
};

// Inline CSS styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "20px",
    marginLeft: "20px",
    justifyContent: "center"
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "10px",
    width: "400px",
    backgroundColor: "white",
    boxShadow: "2px 2px 10px rgba(0, 0, 0, 0.1)",
  },
  label: {
    color: "#333",
  },
  value: {
    fontWeight: "bold",
  },
};

export default SpendingSummary;
