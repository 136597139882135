import { HelpersConstants } from "./helpers.constants";
import axios from "axios";
import { config } from "../../helpers";
import { toFormData } from "../../components/utilities/customFunctions";

const ROOT_URL = config.apiServer;
const ROOT_URL_API = ROOT_URL + "/api";
const ROOT_ANALYTICS_API = config.analyticServer + "/api";

export const getallTimezones = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_TIMEZONES_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/timezone`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_TIMEZONES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_TIMEZONES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_TIMEZONES_ERROR,
        payload: "",
      })
    );
};

export const getUniqueCPM =
  (accountId = "", countryId = "", is_mobile_app = "", dealType = "") =>
  (dispatch) => {
    dispatch({
      type: HelpersConstants.BB_GET_UNIQUE_CPM_INIT,
      payload: true,
    });
    axios
      .get(
        `${ROOT_URL_API}/get-unique-cpm?accountId=${accountId}&countryId=${countryId}&is_mobile_app=${is_mobile_app}&dealType=${dealType}`,
        { headers: { Authorization: config.authToken() } }
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: HelpersConstants.BB_GET_UNIQUE_CPM_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_GET_UNIQUE_CPM_ERROR,
            payload: "",
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: HelpersConstants.BB_GET_UNIQUE_CPM_ERROR,
          payload: "",
        })
      );
  };

export const getallRoles = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_ROLES_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/roles?limit=10000&offset=1&name=&status=1`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_ROLES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_ROLES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({ type: HelpersConstants.BB_GET_ALL_ROLES_ERROR, payload: "" })
    );
};

export const getallCurrencies = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_CURRENCIES_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/currencies`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_CURRENCIES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_CURRENCIES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_CURRENCIES_ERROR,
        payload: "",
      })
    );
};

export const emptyBespokeSelection = () => (dispatch) => {
  dispatch({ type: HelpersConstants.EMPTY_BESPOKE_BY_COUNTRY, payload: "" });
};
export const getBespokeSelection = (countries) => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_BESPOKE_BY_COUNTRY_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/get_all_g_bespoke?country_code=${countries}`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_BESPOKE_BY_COUNTRY_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_BESPOKE_BY_COUNTRY_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_BESPOKE_BY_COUNTRY_ERROR,
        payload: "",
      })
    );
};
export const getallCountries = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_COUNTRIES_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/country`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_COUNTRIES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_COUNTRIES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_COUNTRIES_ERROR,
        payload: "",
      })
    );
};
export const emptyStates = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_STATES, payload: "" });
};

export const emptyCities = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_CITIES, payload: "" });
};

export const getallStates = (countryId) => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_GET_ALL_STATES_INIT, payload: true });

  axios
    .get(`${ROOT_URL_API}/state?countryId=${countryId}`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_STATES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_STATES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({ type: HelpersConstants.BB_GET_ALL_STATES_ERROR, payload: "" })
    );
};

export const getallCities = (countryId, stateId) => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_GET_ALL_CITIES_INIT, payload: true });
  axios
    .get(`${ROOT_URL_API}/city?countryId=${countryId}&stateId=${stateId}`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_CITIES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_CITIES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({ type: HelpersConstants.BB_GET_ALL_CITIES_ERROR, payload: "" })
    );
};

export const getAllPublishers = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_PUBLISHERS_INIT,
    payload: true,
  });
  axios
    .get(`${config.SUPPLY_URL_API}/get-all-publishers`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_PUBLISHERS_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_PUBLISHERS_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_PUBLISHERS_ERROR,
        payload: "",
      })
    );
};

export const getAllNetworksUser = (country = "") => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_GET_ALL_NETWORKS_INIT, payload: true });
  axios
    .get(`${ROOT_URL_API}/get-all-networks?country=${country}`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_NETWORKS_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_NETWORKS_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_NETWORKS_ERROR,
        payload: "",
      })
    );
};

export const getAllAccountsUser =
  (networkId, dsp = "", constant = "BB_GET_ALL_ACCOUNTS", isDemo = "") =>
  (dispatch) => {
    dispatch({ type: HelpersConstants[constant + "_INIT"], payload: true });
    axios
      .get(
        `${ROOT_URL_API}/get-all-accounts?networkId=${networkId}&dspId=${dsp}&is_demo=${isDemo}`,
        { headers: { Authorization: config.authToken() } }
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: HelpersConstants[constant + "_SUCCESS"],
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: HelpersConstants[constant + "_ERROR"],
            payload: "",
          });
        }
      })
      .catch((error) =>
        dispatch({ type: HelpersConstants[constant + "_ERROR"], payload: "" })
      );
  };

export const getAllSitesPublisher = (Id) => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_GET_ALL_SITES_INIT, payload: true });
  axios
    .get(
      `${config.SUPPLY_URL_API}/get-all-publisher-sites?publisher_id=${Id}`,
      { headers: { Authorization: config.authToken() } }
    )
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_SITES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_SITES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({ type: HelpersConstants.BB_GET_ALL_SITES_ERROR, payload: "" })
    );
};

export const getallDealTypeFormat = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/dealType-format`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_ERROR,
        payload: "",
      })
    );
};

export const getDealTypeByCountry =
  (countryId, decarbonise = false, getByCode = false) =>
  (dispatch) => {
    dispatch({
      type: HelpersConstants.BB_GET_ALL_DEAL_TYPE_FORMAT_INIT,
      payload: true,
    });
    axios
      .get(
        `${ROOT_URL_API}/get-dealType-by-${
          getByCode ? "countryCode?countryCode" : "country?countryId"
        }=${
          typeof countryId == "object" ? countryId.id : countryId
        }&decarbonise=${decarbonise}`,
        { headers: { Authorization: config.authToken() } }
      )
      .then((response) => {
        if (response.data) {
          dispatch({
            type: HelpersConstants.BB_GET_DEAL_TYPE_BY_COUNTRY_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_GET_DEAL_TYPE_BY_COUNTRY_ERROR,
            payload: "",
          });
        }
      })
      .catch((error) =>
        dispatch({
          type: HelpersConstants.BB_GET_DEAL_TYPE_BY_COUNTRY_ERROR,
          payload: "",
        })
      );
  };

export const emptyPublishers = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_PUBLISHERS });
};
export const emptySites = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_SITES });
};
export const emptyNetworks = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_NETWORKS });
};

export const emptyDealTypeFormat = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_DEAL_TYPE_FORMAT });
};
export const emptyDealTypeByCountry = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_DEAL_TYPE_BY_COUNTRY });
};

export const emptyAccounts =
  (constant = "BB_EMPTY_ACCOUNTS") =>
  (dispatch) => {
    dispatch({ type: HelpersConstants[constant] });
  };

export const emptyCampaigns = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_CAMPAIGNS });
};

export const getAllDsps = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_GET_ALL_DSPS_INIT, payload: true });

  axios
    .get(`${ROOT_URL_API}/get-all-dsp`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_DSPS_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_DSPS_ERROR,
          payload: "Error",
        });
      }
    })
    .catch(() => {
      dispatch({
        type: HelpersConstants.BB_GET_ALL_DSPS_ERROR,
        payload: "Error",
      });
    });
};

export const emptyCompanyDsps = () => {
  return { type: HelpersConstants.BB_EMPTY_DSPS };
};

export const transferOwnership = (column, oldOwner, newOwner, id) => {
  return (dispatch) => {
    dispatch(ownershipLoading(true));
    axios
      .post(
        `${ROOT_URL_API}/transfer-ownership`,
        toFormData({
          column: column,
          oldOwnerId: oldOwner,
          newOwnerId: newOwner,
          id: id,
        }),
        { headers: { Authorization: config.authToken() } }
      )
      .then((response) => {
        if (response.data && response.data.success) {
          dispatch({
            type: HelpersConstants.BB_CHANGE_OWNERSHIP_SUCCESS,
            payload: response.data.success + Date.now(),
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_CHANGE_OWNERSHIP_ERROR,
            payload: false + Date.now(),
          });
        }
        dispatch(ownershipLoading(false));
      })
      .catch(() => {
        dispatch({
          type: HelpersConstants.BB_CHANGE_OWNERSHIP_ERROR,
          payload: false + Date.now(),
        });
        dispatch(ownershipLoading(false));
      });
  };
};
const ownershipLoading = (status) => {
  return {
    type: HelpersConstants.BB_CHANGE_OWNERSHIP_STATUS,
    payload: status,
  };
};

export const checkAccess = (what, id) => {
  return (dispatch) => {
    dispatch(checkAccessLoading(true));
    axios
      .get(`${ROOT_URL_API}/check-access?what=${what}&id=${id}`, {
        headers: { Authorization: config.authToken() },
      })
      .then((response) => {
        dispatch(checkAccessLoading(false));
        if (response.data && response.data.success && response.data.canAccess) {
          dispatch({
            type: HelpersConstants.BB_CHECK_ACCESS_SUCCESS,
            payload: true + Date.now(),
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_CHECK_ACCESS_ERROR,
            payload: false + Date.now(),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: HelpersConstants.BB_CHECK_ACCESS_ERROR,
          payload: false + Date.now(),
        });
        dispatch(checkAccessLoading(false));
      });
  };
};
const checkAccessLoading = (status) => {
  return {
    type: HelpersConstants.BB_CHECK_ACCESS_STATUS,
    payload: status,
  };
};

/* DSP Guide */
export const getDspGuide = (dsp, accountId = "") => {
  return (dispatch) => {
    dispatch(dspGuideLoading(true));
    axios
      .get(
        `${ROOT_URL_API}/get-dsp-guide?accountId=${accountId}&filename=${encodeURIComponent(
          dsp.replace(/\//gi, "-")
        )}`,
        { headers: { Authorization: config.authToken() } }
      )
      .then((response) => {
        dispatch(dspGuideLoading(false));
        if (response.data && response.data.success) {
          dispatch({
            type: HelpersConstants.BB_GET_DSP_GUIDE_SUCCESS,
            payload: response.data.guide + "~" + Date.now(),
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_GET_DSP_GUIDE_ERROR,
            payload: "false~" + Date.now(),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: HelpersConstants.BB_GET_DSP_GUIDE_ERROR,
          payload: "false~" + Date.now(),
        });
        dispatch(dspGuideLoading(false));
      });
  };
};
const dspGuideLoading = (status) => {
  return {
    type: HelpersConstants.BB_GET_DSP_GUIDE_LOADING,
    payload: status,
  };
};

/* Ad Spec */
export const getAdSpec = (accountId) => {
  return (dispatch) => {
    dispatch(adSpecLoading(true));
    axios
      .get(`${ROOT_URL_API}/get-ad-spec?accountId=${accountId}`, {
        headers: { Authorization: config.authToken() },
      })
      .then((response) => {
        dispatch(adSpecLoading(false));
        if (response.data && response.data.success) {
          dispatch({
            type: HelpersConstants.BB_GET_AD_SPEC_SUCCESS,
            payload: response.data.url + "~" + Date.now(),
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_GET_AD_SPEC_ERROR,
            payload: "false~" + Date.now(),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: HelpersConstants.BB_GET_AD_SPEC_ERROR,
          payload: "false~" + Date.now(),
        });
        dispatch(adSpecLoading(false));
      });
  };
};
const adSpecLoading = (status) => {
  return {
    type: HelpersConstants.BB_GET_AD_SPEC_LOADING,
    payload: status,
  };
};

/* DSP Guide */
export const getVideoId = (accountId) => {
  return (dispatch) => {
    dispatch(videoIdLoading(true));
    axios
      .get(`${ROOT_URL_API}/get-video-tutorial?accountId=${accountId}`, {
        headers: { Authorization: config.authToken() },
      })
      .then((response) => {
        dispatch(videoIdLoading(false));
        if (response.data && response.data.success) {
          dispatch({
            type: HelpersConstants.BB_GET_VIDEO_ID_SUCCESS,
            payload: response.data.url + "~" + Date.now(),
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_GET_VIDEO_ID_ERROR,
            payload: "false~" + Date.now(),
          });
        }
      })
      .catch(() => {
        dispatch({
          type: HelpersConstants.BB_GET_VIDEO_ID_ERROR,
          payload: "false~" + Date.now(),
        });
        dispatch(videoIdLoading(false));
      });
  };
};
const videoIdLoading = (status) => {
  return {
    type: HelpersConstants.BB_GET_VIDEO_ID_LOADING,
    payload: status,
  };
};

export const getallCategories = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_CATEGORIES_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/get-all-audience-categories`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_CATEGORIES_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_CATEGORIES_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_CATEGORIES_ERROR,
        payload: "",
      })
    );
};

export const getHACTemperature = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_HAC_TEMPERATURE_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/get_hac_temperature`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_HAC_TEMPERATURE_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_HAC_TEMPERATURE_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_HAC_TEMPERATURE_ERROR,
        payload: "",
      })
    );
};

export const emptyCategories = () => (dispatch) => {
  dispatch({ type: HelpersConstants.BB_EMPTY_CATEGORIES });
};

/* Gross Margin */

const grossMarginLoading = (status) => {
  return {
    type: HelpersConstants.BB_GROSS_MARGIN_LOADING_STATUS,
    payload: status,
  };
};

const getTransposedData = (data) => {
  return {
    ...data,
  };
};

const getDayWiseData = (data) => {
  let n = [
    ...data.current,
    ...data.current_month,
    ...data.one_month_ago,
    ...data.two_month_ago,
  ];

  if (n && n.length) {
    n = Object.values(
      n.reduce((a, c) => {
        (a[
          c.vendor + c.money_flow + c.classification + c.classification_item
        ] ||
          (a[
            c.vendor + c.money_flow + c.classification + c.classification_item
          ] = {
            vendor: c.vendor,
            money_flow: c.money_flow,
            classification: c.classification,
            classification_item: c.classification_item,
            last_updated: c.last_updated,
            date: c.date,
            amount: c.amount,
            impressions: c.impressions,
          }))[c.date] = getTransposedData(c);
        return a;
      }, {})
    );
  }
  return n;
};

export const getGrossMarginReport = () => {
  return (dispatch) => {
    dispatch(grossMarginLoading(true));
    /* dispatch({ type: HelpersConstants.BB_GROSS_MARGIN_SUCCESS, payload: getDayWiseData(data) });

        dispatch(grossMarginLoading(false)); */

    axios
      .get(`${ROOT_ANALYTICS_API}/gross_margin`, {
        headers: { Authorization: config.authToken() },
      })
      .then((response) => {
        if (response.data && response.data.success) {
          dispatch({
            type: HelpersConstants.BB_GROSS_MARGIN_SUCCESS,
            payload: getDayWiseData(response.data.data),
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_GROSS_MARGIN_ERROR,
            payload: false,
          });
        }
        dispatch(grossMarginLoading(false));
      })
      .catch((e) => {
        dispatch({
          type: HelpersConstants.BB_GROSS_MARGIN_ERROR,
          payload: false,
        });
        dispatch(grossMarginLoading(false));
      });
  };
};

export const emptyGrossMarginReport = () => (dispatch) => {
  dispatch({ type: HelpersConstants.EMPTY_GROSS_MARGIN });
};

/* Product Margin */

const productMarginLoading = (status) => {
  return {
    type: HelpersConstants.BB_PRODUCT_MARGIN_LOADING_STATUS,
    payload: status,
  };
};

export const getProductMarginReport = () => {
  return (dispatch) => {
    dispatch(productMarginLoading(true));
    axios
      .get(`${ROOT_ANALYTICS_API}/product_margin_v2`, {
        headers: { Authorization: config.authToken() },
      })
      .then((response) => {
        if (response.data && response.data.success) {
          dispatch({
            type: HelpersConstants.BB_PRODUCT_MARGIN_SUCCESS,
            payload: response.data.data,
          });
        } else {
          dispatch({
            type: HelpersConstants.BB_PRODUCT_MARGIN_ERROR,
            payload: false,
          });
        }
        dispatch(productMarginLoading(false));
      })
      .catch((e) => {
        dispatch({
          type: HelpersConstants.BB_PRODUCT_MARGIN_ERROR,
          payload: false,
        });
        dispatch(productMarginLoading(false));
      });
  };
};

export const emptyProductMarginReport = () => (dispatch) => {
  dispatch({ type: HelpersConstants.EMPTY_PRODUCT_MARGIN });
};

export const getallProviders = () => (dispatch) => {
  dispatch({
    type: HelpersConstants.BB_GET_ALL_PROVIDERS_INIT,
    payload: true,
  });
  axios
    .get(`${ROOT_URL_API}/get_unique_data_providers`, {
      headers: { Authorization: config.authToken() },
    })
    .then((response) => {
      if (response.data) {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_PROVIDERS_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: HelpersConstants.BB_GET_ALL_PROVIDERS_ERROR,
          payload: "",
        });
      }
    })
    .catch((error) =>
      dispatch({
        type: HelpersConstants.BB_GET_ALL_PROVIDERS_ERROR,
        payload: "",
      })
    );
};
