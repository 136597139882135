import React, { useState, useEffect } from 'react';
import Skeleton from "../../../helpers/Skeleton"
import ReactTable from './ReactTable'

function TableSkeleton(props) {
    
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const { className = "" } = props;

    useEffect(() => {
        if (props.rows && props.columns) {
            columnAndData()
        }
        // eslint-disable-next-line
    }, [])

    function columnAndData() {
        const { rows, columns } = props;
        let data = [];
        let col = [];
        for (var i = 0; i < rows; i++) {
            var row = { id: i };
            data.push(row)
        }
        for (var j = 0; j < columns; j++) {
            var column =
            {
                Header: <Skeleton animated={false} color="dimgrey" width="100px" height="15px" />,
                accessor: "id" + j,
                minWidth: 200,
                className: "text-center",
                Cell: obj => (
                    <div>
                        <Skeleton width="80px" height="15px" />
                    </div>
                )
            }
            col.push(column)
        }
        setData(data)
        setColumns(col)
    }


    return (
        (data instanceof Array && data.length > 0) ?
            <ReactTable data={data} columns={columns} className={className} />
            : null
    )
}

export default TableSkeleton;


