export const AllUserConstants = {
    GET_ALL_USERS: 'GET_ALL_USERS',
    ALL_USERS_LOADING: 'ALL_USERS_LOADING',
    GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',
    ALL_USERS_CURRENT_PAGE: 'ALL_USERS_CURRENT_PAGE',
    ALL_USERS_LIMIT_PER_PAGE: 'ALL_USERS_LIMIT_PER_PAGE',
    ALL_USERS_SEARCH_VALUE: 'ALL_USERS_SEARCH_VALUE',
    ALL_USERS_STATUS_VALUE: 'ALL_USERS_STATUS_VALUE',
    ALL_USERS_LIMIT: 'ALL_USERS_LIMIT',


    GET_EDIT_USER_INFO: "GET_EDIT_USER_INFO", 
    EMPTY_EDIT_USER_INFO: "EMPTY_EDIT_USER_INFO",  
    EDIT_USER_LOADING: "EDIT_USER_LOADING",
    BB_EDIT_USER_SUCCESS: "BB_EDIT_USER_SUCCESS",
    BB_EDIT_USER_ERROR: "BB_EDIT_USER_ERROR",
    BB_EDIT_USER_LOADING: 'BB_EDIT_USER_LOADING',

    BB_GET_USER_INFORMATION: "BB_GET_USER_INFORMATION",
    BB_CHANGE_USER_INFORMATION: "BB_CHANGE_USER_INFORMATION",
    BB_CHANGE_USER_PASSWORD: "BB_CHANGE_USER_PASSWORD",
    BB_CHANGE_USER_INFORMATION_ERROR: "BB_CHANGE_USER_INFORMATION_ERROR",
    BB_CHANGE_USER_PASSWORD_ERROR: "BB_CHANGE_USER_PASSWORD_ERROR",
    BB_CHANGE_USER_INFORMATION_LOADING: "BB_CHANGE_USER_INFORMATION_LOADING",

    BB_GET_ALL_USERS: 'BB_GET_ALL_USERS',
    BB_GET_ALL_USERS_ERROR: 'BB_GET_ALL_USERS_ERROR',

    GET_ALL_COMMERCIALS_USERS: 'GET_ALL_COMMERCIALS_USERS', 
    GET_ALL_COMMERCIALS_USERS_ERROR: 'GET_ALL_COMMERCIALS_USERS_ERROR',


    BB_GET_USER_PROFILE: 'BB_GET_USER_PROFILE',
    BB_EDIT_USER_PROFILE: 'BB_EDIT_USER_PROFILE',
    BB_EDIT_USER_PROFILE_ERROR: 'BB_EDIT_USER_PROFILE_ERROR',

    BB_DELETE_USER: 'BB_DELETE_USER',
    BB_DELETE_USER_ERROR: 'BB_DELETE_USER_ERROR',

    BB_USERS_CURRENT_PAGE: 'BB_USERS_CURRENT_PAGE',
    BB_USERS_LIMIT_PER_PAGE: 'BB_USERS_LIMIT_PER_PAGE',
    BB_USERS_SEARCH_VALUE: 'BB_USERS_SEARCH_VALUE',
    BB_USERS_TOTAL_USER: 'BB_USERS_TOTAL_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',
    ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_ERROR: 'ACTIVATE_USER_ERROR',
    BB_USERS_LOADING_STATUS: 'BB_USERS_LOADING_STATUS',

    BB_ADD_USER_LOADING: 'BB_ADD_USER_LOADING',
    BB_ADD_USER_SUCCESS: 'BB_ADD_USER_SUCCESS',
    BB_ADD_USER_ERROR: 'BB_ADD_USER_ERROR',

    EMPTY_USERS:'EMPTY_USERS',

    RESET_USERS_PASSWORD_SA: 'RESET_USERS_PASSWORD_SA',
    RESET_USERS_PASSWORD_SA_ERROR: 'RESET_USERS_PASSWORD_SA_ERROR' 


};
