import React from 'react'
import { FormImage, EditImage, TextArea, SortableItems, FormRadio } from '../../../../form-helpers'
import Tooltip from '../../../../utilities/Tooltip'
import { Field } from 'formik';
import classnames from "classnames"

function MediaUpload(props) {

    const { isDisabled = false, adWidth, adHeight, isVideo = false, name, isEdit = false, onChange = "", onRemoveChange = false, isStringVideo = false,
        setFieldValue, values, landingName = "", impressionName = "", headName = "Frame",
        isStrictDimension = false, flexStart = false, tipDescription = "", isMedia = false, hasVideoSettings = false,
        isOptional = false, strictSize = "100KB", imageWithGif = false, aspectRatio = false, isSortableItems = false, largContainer = false,
        videoFormat = "MP4 H.264 codec and audio in AAC-LC up to 48kHz", videoSize = "2MB" } = props;
    var label = `<span class='bb-' style='color: black;font-weight: 800;padding: 12px;'>DRAG DROP OR SELECT ${isMedia ? "MEDIA" : isVideo ? "A VIDEO" : "IMAGE"}</span> <span class='bb-bg_secondary' style='border-radius: 19px;padding: 14px;margin-left: 30px;margin-right: 30px;font-weight: 700;'>Select</span>`
    return (
        <>
            {headName ? <h5 className="mb-4 pl-0 col-12">{!tipDescription ? headName : null}
                {isOptional ? <span className="bb-text_small bb-color_disabled"> (optional) </span> : null}
                {tipDescription ? <Tooltip light={true} className="m-0 cursor-pointer" label={headName} iconname="fa-info-circle">
                    <div className="p-2 text-left maxWidth-300"><span>{tipDescription}</span></div>
                </Tooltip> : null}
            </h5> : null}
            <div className={classnames("col-md-12 mb-3 d-flex flex-column flex-wrap pl-0 ", largContainer ? "col-lg-12" : "col-lg-6", flexStart ? "justify-content-start" : "justify-content-center")}>
                <Field
                    isEdit={isSortableItems && isEdit ? true : ""}
                    values={values}
                    isDisabled={isDisabled}
                    isMedia={isMedia}
                    isVideo={isVideo}
                    imageWithGif={imageWithGif}
                    component={isSortableItems ? SortableItems : isEdit ? EditImage : FormImage} containerClassName={classnames("bb-upload-container-300x300 ", largContainer ? "align-self-left" : "align-self-center")} name={name}
                    previewContainerClassName="bb-preview-container-300x300"
                    setFieldValue={setFieldValue}
                    onChange={onChange}
                    onRemoveChange={onRemoveChange}
                    isLabelHtml={true}
                    strictDivClassName={largContainer ? true : false}
                    label={label} />
                <div>
                    {!isMedia ?
                        <>
                            {isVideo ?
                                <>
                                    <p className="col-12 m-0 mt-3 pl-0 bb-text_small">Aspect ratio: {aspectRatio ? aspectRatio : "1:1 or 16:9"}</p>
                                    <p className="col-12 m-0 pl-0 bb-text_small">Format: {videoFormat}</p>
                                    <p className="col-12 m-0 pl-0 bb-text_small">Max file size: {videoSize}</p>
                                    <p className="col-12 m-0 pl-0 bb-text_small">Duration: 6-30 seconds</p>
                                </>
                                :
                                <>
                                    <p className="col-12 m-0 mt-3 pl-0 bb-text_small">Dimensions: {(adWidth) + "x" + (adHeight)}{!isStrictDimension ? "," : null} {!isStrictDimension ? (adWidth * 2) + "x" + (adHeight * 2) : null}</p>
                                    <p className="col-12 m-0 pl-0 bb-text_small">Format: .jpg .jpeg .png {imageWithGif ? ".gif" : null}</p>
                                    <p className="col-12 m-0 pl-0 bb-text_small">Max file size: {strictSize}</p>
                                </>
                            }
                        </> :

                        <>
                            <p className="col-12 m-0 mt-3 pl-0 bb-text_small">Dimensions: {(adWidth) + "x" + (adHeight)}{!isStrictDimension ? "," : null} {!isStrictDimension ? (adWidth * 2) + "x" + (adHeight * 2) : null}</p>
                            <p className="col-12 m-0 pl-0 bb-text_small">Image: .jpg .jpeg .png .gif (max: {strictSize})</p>
                            <p className="col-12 m-0 pl-0 bb-text_small">Video: .mp4 (max: 2MB)</p>

                        </>
                    }
                </div>
            </div>
            {landingName || impressionName || hasVideoSettings ?
                <div className="col-md-12 col-lg-6 d-flex flex-column pl-0 justify-content-start">
                    {hasVideoSettings  && !isStringVideo? 
                    
                    <div className=" d-flex flex-column mb-2">
                    <h5 className="mb-4 pl-0">VIDEO SETTINGS</h5>
                    <p className="mb-2 pl-0">Video Style</p>
                    <div className=" d-flex flex-row  mb-2">
                        <Field name='videoStyle' value="cover" id="videoStyle_cover" type="radio" setFieldValue={setFieldValue} checkedValue={values.videoStyle}
                            className="text-dark" label="Fill" showPreview={onChange} component={FormRadio} values={values} />
                        <Field name='videoStyle' value="letterbox" id="videoStyle_letterbox" type="radio" setFieldValue={setFieldValue} checkedValue={values.videoStyle}
                            className="text-dark" label="Fit" showPreview={onChange} component={FormRadio} values={values} />
                    </div>
                </div> : null
                }

                    {landingName ?
                        <Field name={landingName}
                            onBlurTextArea={props.onBlurTextArea}
                            containerClassName="mb-3"
                            className="text-dark" label="Landing Page URL" component={TextArea} />
                        : null}
                    {impressionName ?
                        <Field name={impressionName}
                            onBlurTextArea={props.onBlurTextArea}
                            containerClassName=""
                            placeholder="Write here..." className="text-dark" label="3rd Party Impression Tracker" component={TextArea} />
                        : null}
                </div> : null}
        </>
    )
}

export default MediaUpload