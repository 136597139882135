import React, {useEffect} from 'react';
import { Formik, Form, Field } from 'formik'

import { Loader } from '../react-overlay-loader';
import { FormInput } from '../form-helpers'
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty'

import { forgotPassword } from '../../redux/user/user.actions';
import { connect } from 'react-redux';
import { notify } from '../utilities/customFunctions';
import { config } from '../../helpers';
import classnames from "classnames"


const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Invalid email.')
        .required('Required'),
});

function ForgotPassword(props) {

    function handleFormSubmit(values, actions) {
        props.forgotPassword(values)
    }

    const { errorMessage } = props;
    useEffect(() => {
  
        let msg = errorMessage;
        if (msg && msg.includes("~")) {
          msg = msg.substring(0, msg.indexOf("~"));
        }
        msg && notify(`Oops! ${msg}`, "e");

    }, [errorMessage])


    const { loading, success } = props;

    return (
        <div className="bb-login-form-container d-flex flex-row w-100 h-100">
            <Loader loading={loading} />
            {!success ?
                <div className="h-100 d-flex align-items-center">
                    <Formik
                        validationSchema={ForgetPasswordSchema}
                        initialValues={{ email: '' }}
                        onSubmit={handleFormSubmit}
                        >{({ dirty, isSubmitting, errors }) => (
                            <Form className="bb-login-form d-flex flex-column align-items-start">
                                <div className={classnames("img-fluid logo", config.isBudEnterprise ? " enterprise": "")}></div>
                                <span className='bb-text_small spacer invisible'>.</span>
                                <p className="bb-text_small mb-3">Please enter your email address and we will send a link to reset your password.</p>
                                <Field containerClassName="w-100 mb-3 input-container"  placeholder="Email" name="email" component={FormInput} type="email" keyPressCheck={false} />

                                <div className="d-flex flex-row flex-wrap justify-content-between w-100 align-items-center" >
                                    <Link to="/login" className="bb-forgot-password hvr-underline-from-center bb-text_small">Login</Link>
                                    <button type="submit" className="text-uppercase bb-bg_primary bb-signin-btn" disabled={!isEmpty(errors) || !dirty}>
                                        Reset Password</button>
                                </div>
                            </Form>
                        )}
                   </Formik>
                </div>
                :
                <React.Fragment>
                    <div className="bb-login-form-container align-items-center d-flex flex-row w-100 h-100">
                        <div className="bb-login-form d-flex flex-column align-items-start">
                            <img className="logo" src="/images/buddyLogo.svg" alt="" />
                            <span className='bb-text_small spacer invisible'>.</span>
                            <p className="bb-text_small mb-3">Please check your email for <br />instructions to reset your password.</p>
                            <Link to="/login" className="bb-forgot-password hvr-underline-from-center mt-3">Login</Link>
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        errorMessage: state.user.errorForgotPassword,
        success: state.user.successForgotPassword,
        loading: state.user.loading
    }
};

export default (connect(mapStateToProps, { forgotPassword })(ForgotPassword));


