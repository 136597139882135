import React, { useState } from "react";
import classnames from "classnames"
import Slider from "react-slick";
import { config } from '../../../../../../helpers/config'

import '../../../../../ad-preview/ad-preview.scss'
import "./index.scss";
import PrimoPreviewLive from "../../../../../ad-preview/PrimoPreviewLive";


const FormatCarouselModal = (props) => {
  const { formatCarousel } = props
  const [imageIndex, setImageIndex] = useState(0);
  const [slider, setSlider] = useState(0);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    dots: false,
    speed: 1000,
    slidesToShow: (window.innerWidth < 576 || formatCarousel.filter(e => e.showDesktop).length || formatCarousel.length < 4) ? 1 : 3,
    centerPadding: "0",
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToScroll: 1,
    beforeChange: (current, next) => setImageIndex(next),
  };

  const renderIframe = (format, isActive) => {
    let isAirtime = format?.name.includes("Airtime")
    return (
      
      

      <div className="creative-frame ad-preview-iframe-container position-relative">
        {<img className={classnames("format-image", isActive ? "active" : "", format.showDesktop ? "desktop-view" : "")} src={format.image || "/images/dealPlaceholder.jpg"} alt="notLoaded" />}
        {format.showDesktop ?
          <>
            <img className="iframe-desktop-image" src="/images/desktop-carousel.png" alt=""></img>
            {isActive && <iframe title="Desktop-preview" className="ad-preview-iframe-desktop iframe-desktop-skin" frameBorder="0" scrolling="yes" id="creative-frame" src={config.previewServer + format.previewId + "?desktop=true"}></iframe>}
          </> :
          <>
            <img className="mars-phone" src="/images/mobile-carousel.png" alt="" />
            {isActive ?
              <>
                {isAirtime ? <PrimoPreviewLive xl={true} width={320} height={480} isAirtime={true} isPrimo={true} previewCreative={format} blockBack={true} formatName={"Airtime"} hasReload={false} frameBorder="0" scrolling="yes" isDesktop={false} id="creative-frame"
                  className="preview-iframe" ></PrimoPreviewLive> :
                  <iframe title="Mobile-preview" className="preview-iframe preview-iframe" frameBorder="0" scrolling="yes" id="creative-frame" src={config.previewServer + format.previewId + "?focusAd=true"}></iframe>
                }
              </> : null
            }

          </>
        }

      </div>

    )
    
  }

  const renderFormat = (format, index, isActive) => {
    return (
      <div
        className={classnames("slide-container", isActive ? "activeSlide" : "slide")}
        key={format.id}
      >
        <div className={classnames("slideWrapper d-flex flex-wrap position-relative", format.showDesktop ? "desktop-view" : "")}>
          {
            renderIframe(format, isActive)

          }
          {
            isActive ?
              <div className="format-detail w-100 d-flex flex-wrap">
                <h3>{format.name}</h3>
                <div className="w-100 d-flex flex-wrap justify-content-center">
                  <div className="device-icons">
                    <i className={classnames("fas fa-desktop pr-3", format.device.includes("1") ? "--active" : "")}></i>
                    <i className={classnames("fas fa-mobile-alt", format.device.includes("0") ? "--active" : "")}></i>
                  </div>
                  <button className="bb-button text-center ml-sm-4" onClick={() => { window.open(config.previewServer + format.previewId, "_blank"); }}>Preview</button>
                  {(props?.deal?.decarbonise || props?.isDecarbonise) && <div className="bb-font_20px mt-2">
                    <span className="green-tag mr-2">CO<sub>2</sub></span>
                    {format.carbon_emission_range || 0} g CO<sub>2</sub>PM
                  </div>}
                </div>
              </div> : null
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <Slider ref={slider => (setSlider(slider))}{...settings}>
        {formatCarousel.length ? formatCarousel.map((format, idx) => {
          return (
            renderFormat(format, idx, idx === imageIndex)
          )
        })
        : 
        <div className="d-flex justify-content-center w-100 align-self-center">
          No formats found
        </div>
      }
      </Slider>
      <div className="d-flex flex-row justify-content-center p-4 mt-1">
        <div className="formats-buttons flex-wrap justify-content-center">
          {formatCarousel.map((format, idx) => {
            return (
              <p className={classnames(idx === imageIndex ? "active" : "")} key={idx} onClick={() => {
                if (idx !== imageIndex) {
                  slider.slickGoTo(idx, true);
                }

              }}>{format.name}</p>
            )
          })}
        </div>
      </div>
    </>
  )
};

export default FormatCarouselModal;
