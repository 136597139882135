import Yup from '../../../form-helpers/Yup';

export const MODEL_OPTIONS = [
    {
        "label": "Independent",
        "value": "independent",
    }, 
    {
        "label": "Unified - Rewards",
        "value": "unified-rewards",
    },
    {
        "label": "Unified - Discount",
        "value": "unified-discount",
    }
]

export const MONTHLY_SPEND_BAND = [
    {
        "label": "$50,000 - $100,000",
        "value": "$50,000 - $100,000",
        "min": 50000,
        "max": 100000,
        "country": "AU"
    },
    {
        "label": "$100,000 - $150,000",
        "value": "$100,000 - $150,000",
        "min": 100001,
        "max": 150000,
        "country": "AU"
    },
    {
        "label": "$150,000 - $200,000",
        "value": "$150,000 - $200,000",
        "min": 150001,
        "max": 200000,
        "country": "AU"
    },
    {
        "label": "$200,000 - $250,000",
        "value": "$200,000 - $250,000",
        "min": 200001,
        "max": 250000,
        "country": "AU"
    },
    {
        "label": "$250,000 - $300,000",
        "value": "$250,000 - $300,000",
        "min": 250001,
        "max": 300000,
        "country": "AU"
    },
    {
        "label": "$300,000 +",
        "value": "$300,000 +",
        "min": 300001,
        "max": 10000000,
        "country": "AU"
    },

    {
        "label": "$20,000 - $40,000",
        "value": "$20,000 - $40,000",
        "min": 20000,
        "max": 40000,
        "country": "NZ"
    },
    {
        "label": "$40,000 - $60,000",
        "value": "$40,000 - $60,000",
        "min": 40001,
        "max": 60000,
        "country": "NZ"
    },
    {
        "label": "$60,000 - $80,000",
        "value": "$60,000 - $80,000",
        "min": 60001,
        "max": 80000,
        "country": "NZ"
    },
    {
        "label": "$80,000 - $100,000",
        "value": "$80,000 - $100,000",
        "min": 80001,
        "max": 100000,
        "country": "NZ"
    },
    {
        "label": "$100,000+",
        "value": "$100,000+",
        "min": 100001,
        "max": 10000000,
        "country": "NZ"
    }
]

export const Modal = {
    model: "",
    combined_cpm: "",
    monthly_spend_band: "",
    min_spend_band: "",
    max_spend_band: "",
    percentage: "",
    country: "",
};

export const ValidationSchema = Yup.object().shape({
    model: Yup.string().required('Required'),
    combined_cpm: Yup.string().required('Required'),
    monthly_spend_band: Yup.string().required('Required'),
    min_spend_band: Yup.string().required('Required'),
    max_spend_band: Yup.string().required('Required'),
    percentage: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
});