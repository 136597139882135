import History from '../../helpers/history'
import React from 'react'
import { Link } from "react-router-dom";
import Toggle from 'react-toggle'
import { confirmAlert } from 'react-confirm-alert';
import { getBuddySettings } from './customFunctions';

export const goBack = () => {
    History.goBack();
}
export const previousPage = () => {
    let prevLink = History?.location?.state?.prevPath
    if(prevLink) pushLocation(prevLink)
    else{
        //if there's no previous link, push the current page without edit and create
        pushLocation(History.location.pathname.replace("/edit", "").replace("/create", ""))
    }
}
export const refresh = () => {
    // window.location.reload()
    pushLocation('/reload')
}

export const goto = (route) => {
    pushLocation("/" + route)
}

export const pushLocation = (route) => {
    History.push(typeof route === 'string' && route.includes(' ') ? encodeURI(route) : route)
}

export const openInNewTab = (route) => {
    window.open(window.location.origin + route, "_blank")
}

export const gotoPrevOfssetEmission = () => {
    pushLocation(`/emissions-reporting/offset-emissions${getBuddySettings("offset_params") || ""}`)
}

export const gotoDashboard = () => {
    pushLocation("/dashboard")
}

export const gotoLogin = () => {
    pushLocation("/login")
}

export const goto404Error = () => {
    pushLocation("/404Error")
}


export const gotoNetworks = () => {
    pushLocation("/networks")
}

export const gotoDealProvisioning = () => {
    pushLocation("/deal-provisioning")
}


export const gotoCompanies = () => {
    pushLocation("/accounts")
}

export const gotoDeals = () => {
    pushLocation("/settings/deals")
}
export const gotoCuratedMedia = () => {
    pushLocation("/contextual/curated-media")
}
export const gotoPrebuilt = () => {
    pushLocation("/contextual/prebuilt")
}
export const gotoPrebuiltGMP = () => {
    pushLocation("/contextual/prebuilt")
}
export const gotoBespokeDictionary = () => {
    pushLocation("/contextual/bespoke-category")
}
export const gotoMasterSitelist = () => {
    pushLocation("/contextual/master-sitelist")
}
export const gotoDealType = () => {
    pushLocation("/contextual/format_category")
}
export const gotoFormatTypeTier = () => {
    pushLocation("/contextual/format_category_tier")
}
export const gotoCategory = () => {
    pushLocation("/contextual/category")
}
export const gotoFormatCarousel = () => {
    pushLocation("/contextual/format_carousel")
}
export const gotoCurationFee = () => {
    pushLocation("/contextual/curation_fee")
}
export const gotoDimensions = () => {
    pushLocation("/settings/dimensions")
}
export const gotoFAQ = () => {
    pushLocation("/settings/faqs")
}
export const gotoHelp = () => {
    pushLocation("/help")
}
export const gotoTarget = () => { pushLocation("/business/target") }
export const gotoSegmentCategory = () => { pushLocation("/data-provider/segment-category") }
export const gotoSegmentPricing = () => { pushLocation("/data-provider/segment-pricing") }
export const gotoFormats = () => {
    pushLocation("/settings/formats")
}
export const gotoAddons = () => {
    pushLocation("/contextual/addons")
}
export const gotoCuratedPublisher = () => {
    pushLocation("/contextual/publisher")
}
export const gotoShowcaseManageCreative = () => {
    pushLocation("/showcase/management/creatives")
}
export const gotoShowcaseManageBenchmark = () => {
    pushLocation("/settings/benchmarks")
}
export const gotoMgSupplyConfiguration = () => {
    pushLocation("/criteo/supply_configuration")
}

export const gotoRules = () => {
    pushLocation("/settings/rules")
}

export const gotoAccountsForNetwork = (page = 1, search = "", status = "", id = "", name = "") => {
    pushLocation(`/accounts?page=${page}&search=${search}&status=${status}&networkId=${id}&networkName=${name}`)
}

export const gotoFlightsForAccount = (page = 1, search = "", status = "", id, name) => {
    // pushLocation(`/flights?page=${page}&search=${search}&status=${status}&accountID=${id}&accountName=${name}`)   
    pushLocation(`/my-deals?page=${page}&search=${search}&status=${status}&accountID=${id}&accountName=${name}`)

}

export const gotoDealBriefingDetail = (id) => {  
    pushLocation(`/contextual/deal_briefing/${id}`)

}

export const getCurrentLink = () => {
    return History.location.pathname + History.location.search
}
export const renderCreateNewGreen = (label, link, className = "bb-bg_green-600", isBolt = false) => {
    return (
        <Link to={link} className="text-white cursor-pointer mr-4 mb-3 hvr-underline-from-center">
            {label}
            <i className={isBolt ? "fas fa-bolt ml-2 p-2 rounded " +className : "fas fa-plus ml-2 p-2 rounded "+className}></i>
        </Link>
    );
}
export const renderCreateNew = (label = "Add New") => {
    return (
        <Link to={History.location.pathname.replace(/\/$/, "") + "/create"} className="text-white cursor-pointer mr-4 mb-3 hvr-underline-from-center">
            {label}
            <i className="fas fa-plus ml-2 p-2 rounded bb-bg_blue-700"></i>
        </Link>
    );
}
export const renderEdit = (id) => {
    return (
        <Link to={{pathname: History.location.pathname.replace(/\/$/, "")+"/edit", search: `?id=${id}`, state: { prevPath: getCurrentLink() }}} className="text-white cursor-pointer mr-4 mb-3 hvr-underline-from-center">
            <i className="fas fa-edit"></i>
        </Link>
    );
}

export const renderDuplicate = (id) => {
    return (
        <Link to={History.location.pathname.replace(/\/$/, "") + `/create?duplicate=${id}`} className="text-white cursor-pointer mr-4 mb-3 hvr-underline-from-center">
            <i title="Duplicate" className="far fa-clone"></i>
        </Link>
    );
}

export const renderStatus = (notify, onConfirm, id, isDelete, title = "this", msg = false) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='bb-confirm-box'>
                    <h4 className="pb-3 ft-title">Are you sure?</h4>
                    <p>You want to {msg ? msg : isDelete ? "delete" : "activate"} <span className='text-danger'>{`${title}`}</span>?</p>
                    <div className="d-flex flex-row justify-content-center">
                        <button className="bb-button mr-3" onClick={onClose}>No</button>
                        <button className="bb-button" onClick={() => {
                            onConfirm(id);
                            notify(isDelete ? "Deleting" : "Activating...", "w");
                            onClose();
                        }}>Yes</button>
                    </div>
                </div>
            )
        }
    })
}
export const renderToggleStatus = (status, submit, id) => {
    return (
        <Toggle
            key={Math.random()}
            defaultChecked={status === 1 ? true : false}
            className="toggle-status"
            onChange={(option) => {
                setTimeout(() => { submit(id); }, 500);

            }}
        />
    )
}


export const renderCompletion = (notify, onConfirm, id, isDelete, title = "this") => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='bb-confirm-box'>
                    <h4 className="pb-3 ft-title">Are you sure?</h4>
                    <p>You want to {isDelete ? "close" : "open"} <span className='text-danger'>{`${title}`}</span>?</p>
                    <div className="d-flex flex-row justify-content-center">
                        <button className="bb-button mr-3" onClick={onClose}>No</button>
                        <button className="bb-button" onClick={() => {
                            onConfirm(id);
                            notify(isDelete ? "Closing request" : "Opening request...", "w");
                            onClose();
                        }}>Yes</button>
                    </div>
                </div>
            )
        }
    })
}


export const renderStatusDependencies = (notify, onConfirm, id, title = "this", effected = "", dependencies = []) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='bb-confirm-box'>
                    <h5 className="pb-2 ft-title">Are you sure you want to delete{` ${title}?`} </h5>
                    {dependencies && dependencies.length ? (
                        <div className="mb-2"><p>This will effect the followings {` ${effected}`}</p>
                            {dependencies.map((content, i) =>
                                <li key={i}>{content.name}</li>
                            )}
                        </div>) : null}
                    <div className="d-flex flex-row justify-content-center">
                        <button className="bb-button mr-3" onClick={onClose}>No</button>
                        <button className="bb-button" onClick={() => {
                            onConfirm(id);
                            notify("Deleting", "w");
                            onClose();
                        }}>Yes</button>
                    </div>
                </div>
            )
        }
    })
}

export const renderGoToActivityReport = (ad_id, creative_id, isIcon = false, iconOnly = false) => {
    return (
        isIcon ? 
            <div title="View Report" style={{ maxWidth: "145px" }} onClick={() => openInNewTab(`/creative-report/${ad_id}?cre_id=${creative_id}`)}
                className={"p-0 m-0 cursor-pointer download " + (iconOnly ? "" : " bb-button bb-button-tertiary-20")}>
                <i className="fas fa-chart-bar" aria-hidden="true"></i>
            </div>
        :
            <div onClick={() => openInNewTab(`/creative-report/${ad_id}?cre_id=${creative_id}`)}>View Report</div>
    )
}