import { CuratedAdConstants } from './c-ad.constants';

export const CuratedAd = (state = {}, action) => {
    switch (action.type) {

        case CuratedAdConstants.ADD_SELECTED_MEDIA_DETAILS_SUCCESS:
            return { ...state, addStatus: true, addResp: action.payload };
        case CuratedAdConstants.ADD_SELECTED_MEDIA_DETAILS_ERROR:
            return { ...state, addStatus: false, addResp: action.payload };

        case CuratedAdConstants.UPDATE_SELECTED_MEDIA_DETAILS_SUCCESS:
            return { ...state, editStatus: true, editResp: action.payload };
        case CuratedAdConstants.UPDATE_SELECTED_MEDIA_DETAILS_ERROR:
            return { ...state, editStatus: false, editResp: action.payload };

        case CuratedAdConstants.GET_CURATED_MEDIA_AD_BY_ID:
            return { ...state, adData: action.payload, error: '' }
        case CuratedAdConstants.GET_CURATED_MEDIA_AD_BY_ID_ERROR:
            return { ...state, adData: false, error: action.payload }

        case CuratedAdConstants.SELECTED_MEDIA_LOADING:
            return { ...state, loading: action.payload };
        case CuratedAdConstants.SELECTED_MEDIA_EDIT_LOADING:
            return { ...state, editLoading: action.payload };

        case CuratedAdConstants.EMPTY_CURATED_MEDIA_AD_DATA:
            return { ...state, adData: action.payload };

        case CuratedAdConstants.RENAME_CURATED_DEAL:
            return { ...state, renameStatus: true, renameResp: action.payload };
        case CuratedAdConstants.RENAME_CURATED_DEAL_ERROR:
            return { ...state, renameStatus: false, renameResp: action.payload };
        case CuratedAdConstants.RENAME_LOADING_STATUS:
            return { ...state, renameLoading: action.payload };

        case CuratedAdConstants.CHECK_IF_DEAL_EXISTS:
            return { ...state, dealExistsStatus: true, dealExistsResp: action.payload };
        case CuratedAdConstants.CHECK_IF_DEAL_EXISTS_ERROR:
            return { ...state, dealExistsStatus: false, dealExistsResp: action.payload };
        case CuratedAdConstants.CHECK_IF_DEAL_EXISTS_LOADING:
            return { ...state, checkLoading: action.payload };

        case CuratedAdConstants.TRANSFER_CREATIVE_SUCCESS:
            return { ...state, transferSuccess: true, transferResp: action.payload };
        case CuratedAdConstants.TRANSFER_CREATIVE_ERROR:
            return { ...state, transferSuccess: false, transferResp: action.payload };
        case CuratedAdConstants.TRANSFER_CREATIVE_LOADING:
            return { ...state, transferLoading: action.payload };

        case CuratedAdConstants.BULK_TRANSFER_CREATIVE_SUCCESS:
            return { ...state, bulkTransferSuccess: true, bulkTransferResp: action.payload };
        case CuratedAdConstants.BULK_TRANSFER_CREATIVE_ERROR:
            return { ...state, bulkTransferSuccess: false, bulkTransferResp: action.payload };
        case CuratedAdConstants.BULK_TRANSFER_CREATIVE_LOADING:
            return { ...state, bulkTransferLoading: action.payload };

        case CuratedAdConstants.TRANSFER_DEAL_SUCCESS:
            return { ...state, transferDealSuccess: true, transferDealResp: action.payload };
        case CuratedAdConstants.TRANSFER_DEAL_ERROR:
            return { ...state, transferDealSuccess: false, transferDealResp: action.payload };
        case CuratedAdConstants.TRANSFER_DEAL_LOADING:
            return { ...state, transferDealLoading: action.payload };

        case CuratedAdConstants.SHARE_DEAL_SUCCESS:
            return { ...state, shareDealSuccess: true, shareDealResp: action.payload };
        case CuratedAdConstants.SHARE_DEAL_ERROR:
            return { ...state, shareDealSuccess: false, shareDealResp: action.payload };
        case CuratedAdConstants.SHARE_DEAL_LOADING:
            return { ...state, shareDealLoading: action.payload };
            
        case CuratedAdConstants.SUBMIT_FEEDBACK_SUCCESS:
            return { ...state, submitFeedbackSuccess: true, submitFeedbackResp: action.payload };
        case CuratedAdConstants.SUBMIT_FEEDBACK_ERROR:
            return { ...state, submitFeedbackSuccess: false, submitFeedbackResp: action.payload };
        case CuratedAdConstants.SUBMIT_FEEDBACK_LOADING:
            return { ...state, submitFeedbackLoading: action.payload };
            
        case CuratedAdConstants.AUTOMATE_FEEDBACK_SUCCESS:
            return { ...state, automateFeedbackSuccess: true, automateFeedbackResp: action.payload };
        case CuratedAdConstants.AUTOMATE_FEEDBACK_ERROR:
            return { ...state, automateFeedbackSuccess: false, automateFeedbackResp: action.payload };
        case CuratedAdConstants.AUTOMATE_FEEDBACK_LOADING:
            return { ...state, automateFeedbackLoading: action.payload };
        case CuratedAdConstants.NEW_KRITIKOS:
            const newKritikos = action?.payload
            let adData = state.adData
            if(adData && adData["page-info"] && adData["page-info"][0]?.id === newKritikos?.dealID){
                //creative kot
                if(newKritikos.creativeID){
                    adData.creative = adData.creative.map(cr => {
                        if(newKritikos.creativeID === cr._id) {
                            cr.kot = newKritikos.kot
                            cr.kritikos_score = newKritikos.kritikos_score
                        }
                        return cr
                    })
                } else { //deal kot
                    adData.kot = newKritikos.kot
                    adData.kritikos_score = newKritikos.kritikos_score
                }
                return { ...state, adData: {...adData} }
            }
            return { ...state, adData }

        case CuratedAdConstants.CALCULATE_KRITIKOS:
            const editedKritikos = action?.payload
            let adDataCalc = state.adData
            if(adDataCalc && adDataCalc["page-info"] && adDataCalc["page-info"][0]?.id === editedKritikos?.dealID){
                adDataCalc.kot = null
                adDataCalc.kritikos_score = "calculating"
                adDataCalc.creative = adDataCalc.creative.map(cr => {
                    if(editedKritikos.creativeID === cr._id) {
                        cr.kot = null
                        cr.kritikos_score = "calculating"
                    }
                    return cr
                })
                return { ...state, adData: {...adDataCalc} }
            }
            return { ...state, adData: adDataCalc }

        default:
            return state;
    }
}
