import { HelpersConstants } from "./helpers.constants";
import axios from 'axios';
import { config } from '../../../helpers'

const ROOT_URL = config.apiServer;
const ROOT_URL_API = ROOT_URL + "/api"
const limit = config.dataLimitForDashboard;

export const getAllVerticals = () => dispatch => {
    dispatch({
        type: HelpersConstants.BB_SHOWCASE_GET_ALL_VERTICALS_INIT,
        payload: true
    });
    axios.get(`${ROOT_URL}/get-all-verticals`)
        .then(response => {
            if (response.data) {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_VERTICALS_SUCCESS, payload: response.data.data });
            }
            else {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_VERTICALS_ERROR, payload: "" });
            }
        }).catch(error => dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_VERTICALS_ERROR, payload: "" }))
}

export const emptyVerticals = () => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_EMPTY_VERTICALS, payload: '' });
}


export const getAllFormats = () => dispatch => {
    dispatch({
        type: HelpersConstants.BB_SHOWCASE_GET_ALL_FORMATS_INIT,
        payload: true
    });
    axios.get(`${ROOT_URL}/get-all-formats`)
        .then(response => {
            if (response.data) {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_FORMATS_SUCCESS, payload: response.data.data });
            }
            else {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_FORMATS_ERROR, payload: "" });
            }
        }).catch(error => dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_FORMATS_ERROR, payload: "" }))
}

export const emptyFormats = () => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_EMPTY_FORMATS, payload: '' });
}



export const getAllDimensions = () => dispatch => {
    dispatch({
        type: HelpersConstants.BB_SHOWCASE_GET_ALL_DIMENSIONS_INIT,
        payload: true
    });
    axios.get(`${ROOT_URL}/get-all-dimensions?isAvailableForShowcase=true`)
        .then(response => {
            if (response.data) {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_DIMENSIONS_SUCCESS, payload: response.data.data });
            }
            else {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_DIMENSIONS_ERROR, payload: "" });
            }
        }).catch(error => dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_DIMENSIONS_ERROR, payload: "" }))
}

export const emptyDimensions = () => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_EMPTY_DIMENSIONS, payload: '' });
}



export const getAllObjectives = () => dispatch => {
    dispatch({
        type: HelpersConstants.BB_SHOWCASE_GET_ALL_OBJECTIVES_INIT,
        payload: true
    });
    axios.get(`${ROOT_URL}/get-all-objective`)
        .then(response => {
            if (response.data) {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_OBJECTIVES_SUCCESS, payload: response.data.data });
            }
            else {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_OBJECTIVES_ERROR, payload: "" });
            }
        }).catch(error => dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_OBJECTIVES_ERROR, payload: "" }))
}

export const emptyObjectives = () => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_EMPTY_OBJECTIVES, payload: '' });
}



export const getAllCountries = () => dispatch => {
    dispatch({
        type: HelpersConstants.BB_SHOWCASE_GET_ALL_COUNTRIES_INIT,
        payload: true
    });
    axios.get(`${ROOT_URL}/get-all-countries`)
        .then(response => {
            if (response.data) {
                let data = response.data.data;
                data.forEach(v => v.id += '');
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_COUNTRIES_SUCCESS, payload: response.data.data });
            }
            else {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_COUNTRIES_ERROR, payload: "" });
            }
        }).catch(error => dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_COUNTRIES_ERROR, payload: "" }))
}

export const emptyCountries = () => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_EMPTY_COUNTRIES, payload: '' });
}


export const getShowcaseCreatives = (formats, dimensions, verticals, objectives, devices, country, accountId, networkId, sortBy, sortType, name, offset) => dispatch => {
    
    dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_INIT, payload: true });

    axios.get(`${ROOT_URL_API}/get-showcase-creatives?offset=${offset}&limit=${limit}&format=${formats.replace(/~/ig, ",")}&dimension=${dimensions}&vertical=${verticals}&objective=${objectives}&device=${devices}&country=${country}&accountId=${accountId}&networkId=${networkId}&name=${name}&sortby=${sortBy}&sorttype=${sortType}`, 
                { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data) {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_TOTAL_COUNT, payload: response.data.total_rows });
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_SUCCESS, payload: response.data.data });
            }
            else {
                dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_ERROR, payload: "" });
            }
        }).catch(error => dispatch({ type: HelpersConstants.BB_SHOWCASE_GET_ALL_CREATIVES_ERROR, payload: "" }))
}

export const emptyShowcaseCreatives = () => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_EMPTY_CREATIVES, payload: '' });
}


/* For dynamic search params */
export const setActiveFormats = (a) => async dispatch => {
    await dispatch({ type: HelpersConstants.BB_SHOWCASE_ACTIVE_FORMATS, payload: a });
}
export const setActiveDevices = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_ACTIVE_DEVICES, payload: a });
}
export const setActiveVerticals = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_ACTIVE_VERTICALS, payload: a });
}
export const setActiveObjectives = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_ACTIVE_OBJECTIVES, payload: a });
}
export const setActiveDimensions = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_ACTIVE_DIMENSIONS, payload: a });
}
export const setSearchName = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_SEARCH_NAME, payload: a });
}
export const setSortBy = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_SORTBY, payload: a });
}
export const setSortType = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_SORTTYPE, payload: a });
}
export const setCountry = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_COUNTRY, payload: a });
}
export const setNetworkId = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_NETWORKID, payload: a });
}
export const setAccountId = (a) => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_ACCOUNTID, payload: a });
}

export const resetFilter = () => dispatch => {
    dispatch({ type: HelpersConstants.BB_SHOWCASE_RESET_FILTER });
}
