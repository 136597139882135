import React from 'react'
import { BuddyTable } from '../../table-helpers/BuddyTable-2';

function FeesHistory(props) {
    const { isSavings } = props;
    let data = []// getUniData(!isSavings) 

    return (
        <>
            <h3 className="bb-sub-header-txt mb-4">{`Volume Discount ${isSavings ? "Savings" : "Margin"} Statements History`}</h3>
                <BuddyTable
                    defaultValue={1}
                    pagesize={1}
                    tableSorting={false}
                    showPagination={false}
                    showRowtable={false}
                    emptyMessage="No Data Found."
                    paginateClassName="col-12"
                    skeletonloading={false}
                    total={1}
                    loading={false}
                    currentPage={1}
                    data={data}
                    className="cursor-pointer col-12 buddy_light p-0"
                    dataFor="invoices"                    
                    columns={[
                        {
                            Header: "Month",
                            accessor: "month",
                            className: "text-center",
                            minWidth: 100,
                            Cell: ({ row }) => (row.original["billing month"])
                        },
                        {
                            Header: "Statement Number",
                            accessor: "statementNumber",
                            className: "text-center",
                            minWidth: 100,
                        },
                        {
                            Header: "Spend Efficiencies",
                            accessor: "totalFees",
                            id: "totalFees",
                            className: "text-center",
                            minWidth: 70,
                            show: isSavings,
                            Cell: ({ row }) => <span>{"$" + row.original.totalFee}</span>

                        },
                        {
                            Header: isSavings ? "Impressions Gained" : "Amount",
                            accessor: "totalFee",
                            className: "text-center",
                            minWidth: 70,
                            Cell: ({ row }) => <span>{isSavings ? row.original.impIncreased : "$" + row.original.totalFee}</span>

                        },
                        {
                            Header: "PDF",
                            accessor: "pdf",
                            className: "text-center",
                            minWidth: 100,
                            Cell: ({ row }) => <a style={{ color: "black" }} href={Number(row.id) <= 2 ? row.original.filePf : undefined} target="_blank">
                                <i className="fas fa-file-pdf"></i>
                            </a>

                        }
                    ]}
                />

        </>
    )
}

export default (FeesHistory);
