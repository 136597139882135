import React from 'react'
import "./commercials.scss"
import { numberWithCommas } from '../../utilities/customFunctions';
import { getModelNum } from '../helper';
import { connect } from 'react-redux';


function EarningsMonth(props) {
    let model = getModelNum(props.user?.commercial_model)
    let isAU = true

    let data = []
    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between header-container'>
                <div className='d-flex flex-wrap col-6 flex-column'>
                    <strong><h5>My {`${model === 2 ? "Volume Rewards" : "Volume Discount Earnings "}`} Statement</h5></strong>
                    {/* <span>Billing Period {`${formatBillingDate(data["start period"])} to ${formatBillingDate(data["end period"])}`}</span> */}
                    <span>Billing Period</span>
                    <span>Statement Reference - {data["statementNumber"]}</span>
                </div>
                <div className="position-relative">
                    <img className="bm-logo-c-deal-slider deal" src={`/images/budenterprise/budenterprise_unified.svg`}
                        alt="" />
                </div>
            </div>
        )
    }

    const renderdoubleInput = (label, input1 = "", input2 = "", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-4 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-8 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 small text-right">{input2}</span> : null}
                </div>
            </div>

        )

    }

    const renderThreeInput = (label, input1 = "", input2 = "", input3 = "", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input2}</span> : null}
                    {input3 ? <span className=" form-control fake-input mb-2 small text-right">{input3}</span> : null}

                </div>
            </div>

        )

    }

    const renderFiveInput = (label, input1 = "", input2 = "", input3 = "", input4="", input5="", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-3 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-9 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input2}</span> : null}
                    {input3 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input3}</span> : null}
                    {input4 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input4}</span> : null}
                    {input5 ? <span className=" form-control fake-input mb-2 small text-right">{input5}</span> : null}
                </div>
            </div>

        )

    }

    return (
        <div className='fee'>
            {renderHeader()}
            <div className='d-flex col-12 flex-wrap mt-5'>
                <div className="d-flex flex-row flex-wrap p-0 col-12 justify-content-between">
                    <label className="mb-1 bb-form-input-label col-3 pt-2 bb-form-dsp-label"></label>
                    <div className="col-9 row">
                        {/* {isAU ? <span className="mb-2  mr-2  bb-font_12px text-center" style={{ width: "150px" }}>Lite</span> : null} */}
                        <span className="mb-2  mr-2  bb-font_12px text-center" style={{ width: "150px" }}>Premium</span>
                        <span className=" mb-2  mr-2 bb-font_12px text-center" style={{ width: "150px" }}>Advanced</span>
                        {/* {isAU ? <span className="mb-2  mr-2  bb-font_12px text-center" style={{ width: "150px" }}>Skins</span> : null} */}
                        <span className=" mb-2 bb-font_12px text-center" style={{ width: "150px" }}>Total</span>                        
                    </div>
                </div>
{/*                     
                    {isAU ? renderFiveInput("Client Facing CPM Rate", `$10`, `$22`, '$26', '$35'): renderdoubleInput("Client Facing CPM Rate", `$${data["premiumCPM"]}`, `$${data["advancedCPM"]}`)}
                    {isAU ?  renderFiveInput("Actual Monthly Spend", `$27,500`, `$38,500`, '$27,500', '$16,500', '$110,000'): renderThreeInput("Actual Monthly Spend", `$${data["premiumSpend"]}`, `$${data["advancedSpend"]}`, `$${numberWithCommas(Number(data["advancedSpend"].replace(/,/g, '')) + Number(data["premiumSpend"].replace(/,/g, '')))}`)}
                    {isAU ?  renderFiveInput("Impressions Purchased", `2,750,000`, `1,750,000`, '1,057,692', '471,429', '6,029,121'): renderThreeInput("Impressions Purchased", `${data["premiumImps"]}`, `${data["advancedImps"]}`, `${numberWithCommas(Number(data["premiumImps"].replace(/,/g, '')) + Number(data["advancedImps"].replace(/,/g, '')))}`)}
                    {isAU ?  renderFiveInput("Refund Percentage", `36.00%`, `25.00%`, '$23.08%', '17.14%','26.09%'): renderThreeInput("Refund Percentage", `${data["totalPercentage"]}`, `${data["clientPercentage"]}`, `${data["valueAfterFee"]}`)}

                    {isAU ?  renderFiveInput("This Month's Refund", `9,900`, `9,625`, '6,346', '2,829', '28,700'): renderThreeInput("This Month's Refund", `$${data["premiumFee"]}`, `$${data["advancedFee"]}`, `$${numberWithCommas(Number(data["premiumFee"].replace(/,/g, '')) + Number(data["advancedFee"].replace(/,/g, '')))}`)}
                     */}
            </div>


        </div>
    )
}

const mapStateToProps = state => ({

    user: state.user


});
const mapDispatchToProps = {


};
export default (connect(mapStateToProps, mapDispatchToProps)(EarningsMonth));