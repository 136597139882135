export const CMPConstants = {
    GET_ALL_CMP_PAGINATE_SUCCESS: 'GET_ALL_CMP_PAGINATE_SUCCESS',
    GET_ALL_CMP_PAGINATE_ERROR: 'GET_ALL_CMP_PAGINATE_ERROR',

    CMP_CURRENT_PAGE: 'CMP_CURRENT_PAGE',
    CMP_LIMIT_PER_PAGE: 'CMP_LIMIT_PER_PAGE',
    CMP_TOTAL_COUNT: 'CMP_TOTAL_COUNT',
    
    GET_CMP_BY_ID: 'GET_CMP_BY_ID',
    EMPTY_CMP_BY_PROFILE: 'EMPTY_CMP_BY_PROFILE',
    GET_CMP_BY_ID_ERROR: 'GET_CMP_BY_ID_ERROR',

    GET_CMP_BY_COUNTRY: 'GET_CMP_BY_COUNTRY',
    GET_CMP_BY_COUNTRY_ERROR: 'GET_CMP_BY_COUNTRY_ERROR',
    EMPTY_CMP_BY_COUNTRY: 'EMPTY_CMP_BY_COUNTRY',

    ADD_CMP_SUCCESS: 'ADD_CMP_SUCCESS',
    ADD_CMP_ERROR: 'ADD_CMP_ERROR',

    EDIT_CMP_SUCCESS: 'EDIT_CMP_SUCCESS',
    EDIT_CMP_ERROR: 'EDIT_CMP_ERROR',
    
    CMP_LOADING_STATUS: 'CMP_LOADING_STATUS',

    EMPTY_CMP: 'EMPTY_CMP',
}