import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import "./styles/scss/bb-all-styles.scss"


import { config } from './helpers'
import { store } from './redux/store'
import * as serviceWorker from './serviceWorker';
import { authUserSignOut } from "./redux/user/user.actions"
import { newNotification } from "./redux/settings/notifications/notifications.actions"
import { clearCache } from "./components/utilities/customFunctions"

import ThemeContainer from './ThemeContainer'
import socketIOClient from "socket.io-client";
import { SocketContext } from './socket'

import Toastify from './components/toastify'
import { newKritikos } from './redux/audiences/curated-ad/c-ad.actions';
const socket = socketIOClient(config.logServer, {
    // withCredentials: true,
    'reconnection': true,
    'reconnectionDelay': 3000,
    'reconnectionAttempts': 4,
    'path': '/dispatch/'
});

socket.on("NewNotification", data => {
    // notify(data?.message, "i")
    store.dispatch(newNotification(data))
})
socket.on("NewKritikos", data => {
    store.dispatch(newKritikos(data))
})
socket.on("WhoAreYou", () => {
    var user = store.getState().user

    if (user && user.authenticated) {
        socket.volatile.emit("UserIsActive", {
            id: user.id, token: user.token,
            name: user.firstName + " " + user.lastName,
            subcode: user?.subcode,
            role: user.role
        });
        // if(user.role === SUPER_ADMIN) store.dispatch(getNotifications(user.id))
    }
    else {
        //socket.disconnect()
        socket.volatile.emit("UserIsNotLoggedIn");
    }
});

socket.on("LogoutAction", () => {
    socket.volatile.emit("UserLogout")
    var user = store.getState().user
    store.dispatch(authUserSignOut(user.role, user.id))
});


socket.on("ClearCacheAction", async () => {
    clearCache()
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <SocketContext.Provider value={socket}>
            <ThemeContainer />
            <Toastify />
        </SocketContext.Provider>
    </Provider>
);

serviceWorker.unregister();
