import React, { Component } from 'react';
import { DateRangePicker } from "react-date-range";
import { pushLocation } from '../../utilities/customRoutes'
import { formatDateDisplay } from '../../utilities/customFunctions'
import classnames from "classnames"
import "./date-picker.scss"
import 'react-date-range/dist/styles.css'; // main style file
import Tooltip from '../../utilities/Tooltip';

class DateRPicker extends Component {
  constructor(props) {
    super(props);
    let startDate = props.nullable ? null : props.initStartDate || new Date()
    let endDate = props.nullable ? null : props.initEndDate || new Date()
    
    this.state = {
      show: false,
      initStartDate: startDate,
      initEndDate: endDate,
      disabled: props.disabled || false,
      minDate: props.minDate || false,
      maxDate: props.maxDate || new Date(),
      showWarning: props.showWarning || false,
      dateRangePicker: {
        selection: {
          startDate: startDate,
          endDate: endDate,
          key: 'selection',
        },
      }
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.initStartDate !== prevProps.initStartDate) {
      this.setState({
        initStartDate: this.props.initStartDate,
        dateRangePicker: {
          selection: {
            startDate: this.props.initStartDate,
            endDate: this.props.initEndDate,
            key: 'selection',
          },
        }
      })
    }
    if (this.props.initEndDate !== prevProps.initEndDate) {
      this.setState({
        initEndDate: this.props.initEndDate,
        dateRangePicker: {
          selection: {
            startDate: this.props.initStartDate,
            endDate: this.props.initEndDate,
            key: 'selection',
          },
        }
      })
    }
    if (this.props.showWarning !== prevProps.showWarning) {      
      this.setState({showWarning: this.props.showWarning})
    }
  }
  handleRoute = () => {
    const { dateRangePicker } = this.state
    const { route, onDateChange } = this.props;
    this.closeDateModal();
    if(onDateChange) {
      onDateChange(dateRangePicker.selection.startDate, dateRangePicker.selection.endDate)
    } else {
      this.setState({initStartDate: dateRangePicker.selection.startDate, initEndDate: dateRangePicker.selection.endDate})
      if (route) {
        pushLocation("?startDate=" + formatDateDisplay(dateRangePicker.selection.startDate) + "&endDate=" + formatDateDisplay(dateRangePicker.selection.endDate) + route);
  
      }
      else {
        pushLocation("?startDate=" + formatDateDisplay(dateRangePicker.selection.startDate) + "&endDate=" + formatDateDisplay(dateRangePicker.selection.endDate));
  
      }
    }
  }
  openDateModal = () => {
    if(!this.state.disabled){
      this.setState({ show: !this.state.show })
    }
  }
  closeDateModal = () => {
    this.setState({ show: false })
  }

  handleRangeChange = (which, payload) => {
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });
  }
  renderInput = () => {
    const { initStartDate, initEndDate, disabled, show, showWarning } = this.state
      
    const { noLabel, buttonClassName, inputClassName = "my-2", dateClassName = ""} = this.props
    return (
      <div className="d-flex flex-wrap justify-content-start">
          {noLabel ? null : <label className="bb-font_14px mr-2 mb-0 align-self-center">Filter Dates</label>}
        <div className={classnames("d-flex", inputClassName)}>
          {<button disabled={disabled} className={classnames("date-picker-bb-button bb-button font-weight-bold align-self-end mt-0", (show ? "date-picker-bb-button__active" : ""), buttonClassName)}
           onClick={this.openDateModal}>
            {showWarning ? <Tooltip light={true} label={""} className="mb-0 cursor-pointer" arrowColor="#4DADC5" tooltipClassName="bb-tooltip-light" iconClassName="bb-color_oops" iconname="fa-exclamation-triangle">
              <div className="text-left bb-body-txt font-weight-normal"><span>Selected period falls outside of campaign dates. We recommend checking if all dates are correct before exporting reports.</span></div>
            </Tooltip> :
              <i className={"fas fa-calendar-alt"} />
            }


            </button>}
          <div className={classnames("date-picker-input d-flex cursor-pointer", dateClassName, disabled ? "disabled" : "")} onClick={this.openDateModal}>
            <input
              type="text"
              placeholder='YYYY-MM-DD'
              readOnly
              className="form-control  mt-0 cursor-pointer"
              value={formatDateDisplay(initStartDate)}
            />
            <label className="bb-font_14px mr-2 mb-0 align-self-center cursor-pointer">to</label>
            <input
              type="text"
              placeholder='YYYY-MM-DD'
              className="form-control mt-0 cursor-pointer"
              readOnly
              value={formatDateDisplay(initEndDate)}
            />
          </div>
          
        </div>
      </div>
    );

  }
  renderDateModal = () => {
    const { show, maxDate, minDate } = this.state;
    return (
      <React.Fragment>
        {show &&
          <div className="date-range-container">
            <DateRangePicker
              onChange={this.handleRangeChange.bind(this, 'dateRangePicker')}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              className={'PreviewArea'}
              ranges={[this.state.dateRangePicker.selection]}
              direction="horizontal"
              color="#ec1555"
              showPreview={false}
              rangeColors={this.props.light ? ["#14A1FF"] : ["#ec1555"]}
              maxDate={maxDate}
              minDate={minDate ? minDate : new Date('2019-01-01')}
            />
            <div className=" text-dark d-flex justify-content-end bg-whitesmoke border-radius-bottom">
              <button className="bb-assist-secondary-button mx-2 my-1" onClick={this.closeDateModal}>Close</button>
              <button className="bb-assist-primary-button mx-2 my-1" onClick={this.handleRoute}>Apply</button>
            </div>
          </div>
          
          }
      </React.Fragment>
    )
  }
  render() {
    const { show } = this.state;
    const { overlayClassName = "date-picker-overlay", containerClassName = "" } = this.props
    return (
      <React.Fragment>
        <div className={classnames("date-picker", containerClassName, this.props.light ? " --light" : "")}>
          {this.renderInput()}
          {this.renderDateModal()}          
        </div>
        {show && <div className={overlayClassName} onClick={this.closeDateModal}></div> }
      </React.Fragment>
    )
  }
}


export default DateRPicker;