import { AllUserConstants } from "./all-users.constants";
import axios from 'axios';
import { toFormData } from '../../../components/utilities/customFunctions'
import { config } from '../../../helpers'
const ROOT_URL = config.apiServer;
const ROOT_URL_API = ROOT_URL + "/api";

/* Add */
const addLoading = (status) => {
    return {
        type: AllUserConstants.BB_ADD_USER_LOADING,
        payload: status
    };
};

export const adduser = (values) => dispatch => {
    dispatch(addLoading(true));
    axios.post(`${ROOT_URL_API}/user`,
        toFormData({
            email: values.email,
            //membership: values.membership.code,
            //subcode: values.membership.subcode,
            roleId: values.roleId.id,
            firstName: values.firstName,
            lastName: values.lastName,
            twoStepAuthentication: values.twoStepAuthentication,
            phoneNumber: values.phoneNumber,
            role: values.role,
            timeZone: values.timeZone.id,
            receiveMarketing: values.receiveMarketing,
            turnOnNotification: values.turnOnNotification,
            profilePhoto: values.profilePhoto,
            companyName: values.companyName,
            publisher: '',
            sites: '',
            networks: values.network,
            accounts: values.account,
            advertisers: 'ALL',
            commission: values.commission,
            reporting_to: values.reporting_to.id || ""
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {
                dispatch({
                    type: AllUserConstants.BB_ADD_USER_SUCCESS,
                    payload: Date.now()
                });
            }
            else {
                dispatch({
                    type: AllUserConstants.BB_ADD_USER_ERROR,
                    payload: {
                        addResp: "error" + Date.now(),
                        msg: response.data.msg
                    }
                });
            }
            dispatch(addLoading(false));
        }).catch(() => {
            dispatch({
                type: AllUserConstants.BB_ADD_USER_ERROR,
                payload: {
                    addResp: "error" + Date.now(),
                    msg: "ERROR"
                }
            });
            dispatch(addLoading(false));
        })
}

/* Edit */
const editLoading = (status) => {
    return {
        type: AllUserConstants.BB_EDIT_USER_LOADING,
        payload: status
    };
};
export const emptyEditUserProfile = () => dispatch => {
    dispatch({
        type: AllUserConstants.EMPTY_EDIT_USER_INFO,
        payload: ''
    });
}
export const getedituserinformation = (id) => dispatch => {
    dispatch(editLoading(true));
    axios.get(`${ROOT_URL_API}/user/${id}`, { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data) {
                if (response.data.success) {
                    dispatch({
                        type: AllUserConstants.GET_EDIT_USER_INFO,
                        payload: response.data.data
                    });
                }
                else {
                    dispatch({
                        type: AllUserConstants.GET_EDIT_USER_INFO,
                        payload: response.data.msg
                    });
                }
            }

            dispatch(editLoading(false));
        }).catch(() => {
            dispatch({
                type: AllUserConstants.GET_EDIT_USER_INFO,
                payload: "ERROR"
            });
            dispatch(editLoading(false));
        })
}
export const edituser = (id, values) => dispatch => {
    dispatch(editLoading(true));
    axios.post(`${ROOT_URL_API}/update-user/${id}`,
        toFormData({
            email: values.email,
            //membership: typeof values.membership === "object" ? values.membership.code : values.membership,
            //subcode: typeof values.membership === "object" ? values.membership.subcode : values.subcode,
            roleId: typeof values.roleId === "object" ? values.roleId.id : values.roleId,
            firstName: values.firstName,
            lastName: values.lastName,
            twoStepAuthentication: values.twoStepAuthentication,
            phoneNumber: values.phoneNumber,
            role: values.role,
            timeZone: typeof values.timeZone === "object" ? values.timeZone.id : values.timeZone,
            receiveMarketing: values.receiveMarketing,
            turnOnNotification: values.turnOnNotification,
            status: typeof values.status === "object" ? values.status.value : values.status,
            profilePhoto: values.profilePhoto,
            companyName: values.companyName || "",
            // eslint-disable-next-line
            companyName: values.companyName,
            publisher: '',
            sites: '',
            networks: values.network,
            accounts: values.account,
            advertisers: 'ALL',
            commission: values.commission,
            reporting_to: values.reporting_to && typeof values.reporting_to === "object" ? values.reporting_to.id : values.reporting_to || ""

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {
                dispatch({
                    type: AllUserConstants.BB_EDIT_USER_SUCCESS,
                    payload: Date.now()
                });
            }
            else {
                dispatch({
                    type: AllUserConstants.BB_EDIT_USER_ERROR,
                    payload: {
                        editResp: "error" + Date.now(),
                        msg: "error"
                    }
                });
            }
            dispatch(editLoading(false));
        }).catch(() => {
            dispatch({
                type: AllUserConstants.BB_EDIT_USER_ERROR,
                payload: {
                    editResp: "error" + Date.now(),
                    msg: "error"
                }
            });
            dispatch(editLoading(false));
        })


}

export const resetPasswordSA = (id) => dispatch => {
    dispatch(editLoading(true));
    axios.post(`${ROOT_URL_API}/reset-user-password`,
        toFormData({
            id_user: id
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {
                dispatch({
                    type: AllUserConstants.RESET_USERS_PASSWORD_SA,
                    payload: response.data.data
                });
            }
            else {
                dispatch({ type: AllUserConstants.RESET_USERS_PASSWORD_SA_ERROR, payload: {} });
            }
            dispatch(editLoading(false));
        }).catch(error => {
            dispatch({ type: AllUserConstants.RESET_USERS_PASSWORD_SA_ERROR, payload: {} });
            dispatch(editLoading(false));
        })

}

/* Profile */
const profileLoading = (status) => {
    return {
        type: AllUserConstants.BB_CHANGE_USER_INFORMATION_LOADING,
        payload: status
    };
};
export const getUserProfile = (id) => dispatch => {
    axios.get(`${ROOT_URL_API}/get-user-profile`, { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data) {
                dispatch({
                    type: AllUserConstants.BB_GET_USER_INFORMATION,
                    payload: response.data.data
                });

            }
        }).catch(error => console.error(error))
}


export const changeUserEnterprise = (values) => dispatch => {
    dispatch(changeuserinformations({...values, twoStepAuthentication: !values.twoStepAuthentication}))

}

export const changeuserinformations = (values) => dispatch => {
    dispatch(profileLoading(true));
    
    axios.post(`${ROOT_URL_API}/update-profile/${values.id}`, toFormData(
        { firstName: values.firstName, lastName: values.lastName,  timeZone: typeof values.timeZone === "object" ? values.timeZone.id : values.timeZone ,phoneNumber: values.phoneNumber, profilePhoto: values.profilePhoto, receiveMarketing: values.receiveMarketing, turnOnNotification: values.turnOnNotification, twoStepAuthentication: values.twoStepAuthentication || false })
        , { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {
                dispatch(profileLoading(false));
                dispatch({
                    type: AllUserConstants.BB_CHANGE_USER_INFORMATION,
                    payload: Date.now()

                });
            }
            else {
                dispatch(profileLoading(false));
                dispatch({
                    type: AllUserConstants.BB_CHANGE_USER_INFORMATION_ERROR,
                    payload: {
                        editResp: "error" + Date.now(),
                        msg: "error"
                    }
                });
            }

        }).catch(() => {
            dispatch(profileLoading(false));
            dispatch({
                type: AllUserConstants.BB_CHANGE_USER_INFORMATION_ERROR,
                payload: {
                    editResp: "error" + Date.now(),
                    msg: "error"
                }
            });
        })

}

export const changeuserpassword = (values) => dispatch => {
    axios.post(`${ROOT_URL_API}/change_password`, { oldPassword: values.oldPassword, newPassword: values.newPassword }, { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data.success) {
                dispatch({
                    type: AllUserConstants.BB_CHANGE_USER_PASSWORD,
                    payload: response.data.data
                });
            }
            else {
                dispatch({
                    type: AllUserConstants.BB_CHANGE_USER_PASSWORD_ERROR,
                    payload: response.data.data
                });
            }

        }
        ).catch(() => {
            dispatch({
                type: AllUserConstants.BB_CHANGE_USER_PASSWORD_ERROR,
                payload: "ERROR"
            });
        })
}

/* All others */

const loading = (status) => {
    return {
        type: AllUserConstants.ALL_USERS_LOADING,
        payload: status
    };
};

export const emptyUsers = () => {
    return (dispatch) => {
        dispatch({
            type: AllUserConstants.EMPTY_USERS,
            payload: ''
        });
    }
}

export const getallusersByRole = (userRole) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.get(`${ROOT_URL_API}/user_name?user_role=${userRole}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(loading(false));
                if (response.data) {
                    dispatch({
                        type: AllUserConstants.GET_ALL_USERS,
                        payload: response.data
                    });
                    /*  dispatch({
                         type: AllUserConstants.ALL_USERS_LIMIT,
                         payload: response.data.total_rows
                     }); */
                }
                else {
                    dispatch({
                        type: AllUserConstants.GET_ALL_USERS_ERROR,
                        payload: "error"
                    });
                }
            }).catch(() => {
                dispatch({
                    type: AllUserConstants.GET_ALL_USERS_ERROR,
                    payload: "error"
                });
                dispatch(loading(false));
            })
    }
}
export const getallusers = ({pageSize = 10, page = 1, search = "", status = "", roleId = "", isOnline = "", sortby = "", sorttype = ""}) => {
    return (dispatch) => {
        dispatch(loading(true));
        dispatch({ type: AllUserConstants.ALL_USERS_CURRENT_PAGE, payload: page });
        dispatch({ type: AllUserConstants.ALL_USERS_LIMIT_PER_PAGE, payload: pageSize });
        dispatch({ type: AllUserConstants.ALL_USERS_SEARCH_VALUE, payload: search });
        dispatch({ type: AllUserConstants.ALL_USERS_STATUS_VALUE, payload: status });
        // eslint-disable-next-line
        axios.get(`${ROOT_URL_API}/user_page?limit=${pageSize}&offset=${page}&name=${search}&status=${status}&role=${roleId == "undefined" ? "" : roleId}&isOnline=${isOnline}&sortby=${sortby}&sorttype=${sorttype}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(loading(false));
                if (response.data) {
                    dispatch({
                        type: AllUserConstants.GET_ALL_USERS,
                        payload: response.data
                    });
                    dispatch({
                        type: AllUserConstants.ALL_USERS_LIMIT,
                        payload: response.data.total_rows
                    });
                }
                else {
                    dispatch({
                        type: AllUserConstants.GET_ALL_USERS_ERROR,
                        payload: "error"
                    });
                }
            }).catch(() => {
                dispatch({
                    type: AllUserConstants.GET_ALL_USERS_ERROR,
                    payload: "error"
                });
                dispatch(loading(false));
            })
    }
}

export const getAllUser = () => {
    return (dispatch) => {
        dispatch(loading(true));

        axios.get(`${ROOT_URL_API}/user`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(loading(false));
                if (response.data) {
                    dispatch({ type: AllUserConstants.GET_ALL_USERS, payload: response.data });
                }
                else {
                    dispatch({ type: AllUserConstants.GET_ALL_USERS_ERROR, payload: "error" });
                }
            }).catch(() => {
                dispatch({ type: AllUserConstants.GET_ALL_USERS_ERROR, payload: "error" });
                dispatch(loading(false));
            })
    }
}
export const deleteUser = (id) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.delete(`${ROOT_URL_API}/user/${id}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: AllUserConstants.DELETE_USER_SUCCESS, payload: response.data.success + Date.now() });
                }
                else {
                    dispatch({ type: AllUserConstants.DELETE_USER_ERROR, payload: false });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: AllUserConstants.DELETE_USER_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}


export const activateUser = (id) => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.put(`${ROOT_URL_API}/user-activate/${id}`, {}, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: AllUserConstants.ACTIVATE_USER_SUCCESS, payload: response.data.success + Date.now() });
                }
                else {
                    dispatch({ type: AllUserConstants.ACTIVATE_USER_ERROR, payload: false });
                }
                dispatch(loading(false));
            }).catch(() => {
                dispatch({ type: AllUserConstants.ACTIVATE_USER_ERROR, payload: false });
                dispatch(loading(false));
            });
    };
}




export const getallCommercialUsers = (netwrok = "") => {
    return (dispatch) => {
        dispatch(loading(true));
        axios.get(`${ROOT_URL_API}/get_commercial_managers?network_id=${netwrok}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(loading(false));
                if (response?.data?.success) {
                    dispatch({
                        type: AllUserConstants.GET_ALL_COMMERCIALS_USERS,
                        payload: response.data?.data
                    });
                    
                }
                else {
                    dispatch({
                        type: AllUserConstants.GET_ALL_COMMERCIALS_USERS_ERROR,
                        payload: "error"
                    });
                }
            }).catch(() => {
                dispatch({
                    type: AllUserConstants.GET_ALL_COMMERCIALS_USERS_ERROR,
                    payload: "error"
                });
                dispatch(loading(false));
            })
    }
}

