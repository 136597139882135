import * as Yup from 'yup'
import { isAfter, isEqual } from 'date-fns'

const MAX_FILE_SIZE = 1048576; // 1 MB 
const extensionLists = { video: ['mp4'], favicon:['jpg', 'bmp', 'png', 'jpeg', 'svg', 'gif', 'ico'], imageWithGif: ['jpg', 'bmp', 'png', 'jpeg', 'svg', 'gif'], image: ['jpg', 'bmp', 'png', 'jpeg', 'svg'], tc: ["pdf", "doc", "docx"], data: ["csv"], zip: ["zip"] };
const hexRegExp = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

function equalToDate(ref, msg) {
    return this.test({
        name: 'equalToDate',
        exclusive: false,
        // eslint-disable-next-line
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path
        },
        test: function (value) {
            return isEqual(value, this.resolve(ref))
        }
    })
};
Yup.addMethod(Yup.date, 'equalToDate', equalToDate);

function isAfterDate(ref, msg) {
    return this.test({
        name: 'isAfterDate',
        exclusive: false,
        // eslint-disable-next-line
        message: msg || '${path} must be the after ${reference}',
        params: {
            reference: ref.path
        },
        test: function (value) {
            if (value)
                return isAfter(value, this.resolve(ref))
            else
                return true
        }
    })
};
Yup.addMethod(Yup.date, 'isAfterDate', isAfterDate);

function getSizeInMB(size) {
    return Math.floor(size / (1024 * 1024));
}
function checkFileSize(msg, size = MAX_FILE_SIZE, multi = false) {
    return this.test({
        name: 'checkFileSize',
        exclusive: false,
        message: multi ? 'File size is too large.' : msg ? msg + '(Max allowed size: ' + getSizeInMB(size) + 'MB)' : 'File size is too large. (Max allowed size: ' + getSizeInMB(size) + 'MB)',
        /* params: {
            reference: ref.path
        }, */
        test: function (value) {
            if (value) {
                if (value.size) {
                    if (!multi) {
                        return value.size <= size;
                    }
                    else {
                        if (value.type && value.type.includes("image")) {
                            return value.size <= MAX_FILE_SIZE;
                        }
                        else if (value.type && value.type.includes("video")) {
                            return value.size <= size;
                        }
                        else {
                            return true;
                        }
                    }
                }
                else {
                    return true;
                }
            } else {
                return true;
            }
        }
    })
}
Yup.addMethod(Yup.mixed, 'checkFileSize', checkFileSize);

function validateUrl(value, ishttps = false) {
    if (ishttps) {
        return /^(?:(?:(?:https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
    }
    else {
        return /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);

    }
}

function validateSocialURL(value, platform) {
    if (platform === "Instagram"){
        return /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/igm.test(value)
    }
    
    else if (platform === "Tiktok"){
        return /(?:(?:http|https):\/\/)?(?:www.)?(?:tiktok.com)\/(@\w+)/igm.test(value)

    }
        
    else if (platform === "Facebook"){
        // eslint-disable-next-line
        return /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/igm.test(value)
    }
    
}

function isValidHttps(msg, ishttps = false) {
    return this.test({
        name: 'isValidHttps',
        exclusive: false,
        message: msg || 'Please enter a valid Landing page URL. e.g https://example.com, http://www.example.com',
        test: function (value) {
            if (value) {
                if (validateUrl(value, ishttps)) {
                    return true;
                }
                else {
                    return false;
                }
            } else {
                return true;

            }
        }
    })
}
Yup.addMethod(Yup.mixed, 'isValidHttps', isValidHttps);

function validateThreshold(value) {
    // eslint-disable-next-line
    if (Number(value) > 90 || Number(value) < 11 || Number(value) % 1 != 0) {
        return false
    }
    else {
        return true;
    }
}
function sValidThreshold() {
    return this.test({
        name: 'sValidThreshold',
        exclusive: false,
        message: 'Please enter number between 11-90 no decimal value',
        test: function (value) {
            if (value) {
                if (validateThreshold(value)) {
                    return true;
                }
                else {
                    return false;
                }
            } else {
                return true;

            }
        }
    })
}
Yup.addMethod(Yup.mixed, 'sValidThreshold', sValidThreshold);

function isValidFileType(fName, fType) {
    return extensionLists[fType].indexOf(fName.split('.').pop()) > -1;
}

function checkFileFormat(msg, type = "image") {
    return this.test({
        name: 'checkFileFormat',
        exclusive: false,
        message: msg || 'File wrong Format',
        test: function (value) {
            if (value && value.name) {
                if (!isValidFileType(value.name.toLowerCase(), type)) {
                    return false;
                }
                else {
                    return true;
                }
            } else {
                return true;
            }
        }
    })
}
Yup.addMethod(Yup.mixed, 'checkFileFormat', checkFileFormat);

function required(msg) {
    return this.test({
        name: 'required',
        exclusive: false,
        message: msg || 'Required',
        test: function (value) {
            if (typeof value === "string") {
                if (value && value.trim()) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                if (value || value === 0) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
    })
}
Yup.addMethod(Yup.mixed, 'required', required);

function isValidHexCode(msg) {
    return this.test({
        name: 'isValidHexCode',
        exclusive: false,
        message: msg || 'Color code is not valid',
        test: function (value) {
            if (value) {
                if (hexRegExp.test(value)) {
                    return true;
                }
                else {
                    return false;
                }
            } else {
                return true;

            }
        }
    })
}
Yup.addMethod(Yup.mixed, 'isValidHexCode', isValidHexCode);


function isMultiCountryAccount(msg) {
    return this.test({
        name: 'isMultiCountryAccount',
        exclusive: false,
        message: msg || 'Selected accounts must be in same country',
        test: function (value) {
            if (value && value.length) {
                let firstValue = value[0]
                if (firstValue) {
                    let firstValueCountry = firstValue.billingCountry
                    let firstValueCurrency = firstValue.currency
                    if (firstValueCountry && firstValueCurrency && value.every(item => item.currency === firstValueCurrency) && value.every(item => item.billingCountry === firstValueCountry)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                } else {
                    return true;

                }
            }
            else{
                return true;
            }

        }
    })
}
Yup.addMethod(Yup.mixed, 'isMultiCountryAccount', isMultiCountryAccount);


function isValidSocialURL(msg, platform = "Instagram") {
    return this.test({
        name: 'isValidHttps',
        exclusive: false,
        message: msg || 'Please enter a valid Instagram URL.',
        test: function (value) {
            if (value) {
                if (validateSocialURL(value, platform)) {
                    return true;
                }
                else {
                    return false;
                }
            } else {
                return true;

            }
        }
    })
}
Yup.addMethod(Yup.mixed, 'isValidSocialURL', isValidSocialURL);

export default Yup;