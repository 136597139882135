import React, { useMemo, useEffect, useRef, useState } from 'react';
import { useTable, useResizeColumns, useFlexLayout, useExpanded, useSortBy, useFilters, useRowSelect } from 'react-table';
import { usePrevious } from '../../utilities/customHooks'

import classnames from 'classnames'
import isEqual from "react-fast-compare";
import { VariableSizeList as WindowList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import WindowScroller from "./WindowScroller"
import Tooltip from '../../utilities/Tooltip';
import ReactDOMServer from 'react-dom/server';

const htmlToText = (value) => {
    return ReactDOMServer.renderToStaticMarkup(value).replace(/<[^>]*>/g, '')
}

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;
  
      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
  
      return (
        <>
          <input type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
);
function ReactTable(props) {

    const { className = "",
        loading = false,
        getTdProps = () => { },
        trClassName = "",
        thClassName = "",
        tdColClassName = "",
        SubComponent,
        subComponentVariable = null,
        subTableColumns = null,
        sorted,
        apiSorting = false,
        tableSorting = false,
        onSortedChange = () => { },
        onSelectChange = () => { },
        onSelectRowChange = null,
        onSelectHeaderChange = null,
        showCheckbox = false,
        isCheckboxDisabled = false,
        disableSelectField = "",
        //showHeaderTitle = true,
        isRowVirtualized = false,
        virtualizedHeight = {row: 35, expanded: 105},
        showFooter = false,
        enableDownload = false,
        sideColumns = null,
        sideColumnSettings = null,
    } = props;


    const columns = React.useMemo(() => props.columns, [props.columns]);
    const data = React.useMemo(() => props.data, [props.data]);
    
    const listRef = useRef(null);
    const [rowSizes, setRowSizes] = useState({});

    function DefaultColumnFilter({
        column: { filterValue, setFilter },
    }) {

        return (
            <input
                className='bb-all-header-filter align-self-end my-2 p-1'
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                placeholder={`Search...`}
            />
        )
    }

    const defaultColumn = useMemo(
        () => ({
            minWidth: 30,
            maxWidth: 400,
            Filter: DefaultColumnFilter
        }),
        []
    )
    const expandRow = (row) => {
        row.toggleRowExpanded(!row.isExpanded)
        if(isRowVirtualized) {
            let i = row.index
            let temp = rowSizes
            temp[i] = temp[i] === virtualizedHeight.expanded ? virtualizedHeight.row : virtualizedHeight.expanded
            setRowSizes(temp)
            if (listRef.current) listRef.current.resetAfterIndex(i);
        }
    };

    if ((SubComponent || subComponentVariable) && columns[0].id !=="expander") {
        columns.unshift({
            Header: "",
            id: 'expander',
            resizable: false,
            className: "text-center",
            width: 30,
            Cell: ({ row }) => (

                <div onClick={() => expandRow(row)} title="Click here to see more information" className="rt-td rt-expandable p-0">
                    <div className={classnames("rt-expander", row.isExpanded ? "-open" : "")}><i className="fas fa-chevron-right text-white"></i></div>
                </div>

            )
        })
    }
    if(showCheckbox){
        columns.unshift({
            id: "selection",
            resizable: false,
            maxWidth: 25,
            Header: ({ rows, toggleRowSelected, isAllRowsSelected, getToggleAllRowsSelectedProps}) => {
                let headerProps = {...getToggleAllRowsSelectedProps()}
                let selectableData = rows
                //selectedFlatRows is not updated yet here so we need to get it manually
                let selectedRows = rows.filter(row => row.isSelected || row.original.isSelected )
                if(disableSelectField) {
                    const modifiedOnChange = (event) => {
                        rows.forEach((row) => {
                            if(onSelectRowChange) !row.original[disableSelectField] && onSelectRowChange(row.original, event.currentTarget.checked);
                            else !row.original[disableSelectField] && toggleRowSelected(row.id, event.currentTarget.checked);
                        });
                    };
                    selectableData = rows.filter(row => !row.original[disableSelectField])
                    //will be disabled if there's no selectable data shown
                    let disabled = selectableData.length === 0
                    headerProps = {
                        ...headerProps,
                        onChange: modifiedOnChange,
                        disabled: disabled,
                        className: disabled ? "d-none" : ""
                    }
                }
                if(onSelectHeaderChange) headerProps.onChange = (event) => { onSelectHeaderChange(event.currentTarget.checked)}
                if(onSelectHeaderChange || disableSelectField) headerProps.checked = (isAllRowsSelected || (selectedRows.length > 0 && selectedRows.length === (selectableData.length)))
                return(
                    <div className="table-select-checkbox">
                        <IndeterminateCheckbox {...headerProps}/>
                    </div>
                )
            },
            Cell: ({ row }) => {
                let isDisabled = false
                if(isCheckboxDisabled && isCheckboxDisabled(row.original)) return <div></div>
                if(disableSelectField) isDisabled = disableSelectField ? row.original[disableSelectField] : false
                let rowProps = {
                    disabled: isDisabled,
                    className :isDisabled ? "d-none" : ""
                }
                if(onSelectRowChange) rowProps.onChange = (event) => { onSelectRowChange(row.original, event.currentTarget.checked)}
                return(
                    <div className="table-select-checkbox text-center">
                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} {...rowProps}/>
                    </div>
                )
            }
            })
    }

    const filterTypes = React.useMemo(
        () => ({
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .includes(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )


    const hiddenColumns = React.useMemo(() => {
        const hiddenColumnIds = [];
        columns.forEach(col => {
            if (col.show === false) hiddenColumnIds.push(col.id)
        });

        return hiddenColumnIds;
    },
    //eslint-disable-next-line
        []
    )
    
    const initialState = {
        hiddenColumns: hiddenColumns
    }
    if(apiSorting || tableSorting) initialState.sortBy = sorted
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        totalColumnsWidth,
        prepareRow,
        selectedFlatRows,
        state: { sortBy }
    } = useTable(
        {
            columns, data, defaultColumn, SubComponent,
            manualSortBy: true, defaultCanSort: false, filterTypes, autoResetExpanded: subComponentVariable ? false : true,
            disableSortRemove: tableSorting,
            initialState: initialState
        },
        useFlexLayout,
        useFilters,
        useSortBy,
        useResizeColumns,
        useExpanded,
        useRowSelect
    )

    const prevProps = usePrevious({ selectedFlatRows, sortBy });
    
    useEffect(() => {


        if (prevProps && prevProps.selectedFlatRows){

            let current = selectedFlatRows.map(row => row.original) || []
            let prev = prevProps.selectedFlatRows.map(row => row.original) || []

            if(!isEqual(current, prev)){
                onSelectChange(current)
            }
            
        }
        //eslint-disable-next-line
    }, [onSelectChange, selectedFlatRows]);


    useEffect(() => {
        if(apiSorting && prevProps && prevProps.sortBy) {
            if(!isEqual(prevProps.sortBy, sortBy)) {
                onSortedChange(sortBy[0]);
            }
        }
        else if (tableSorting && sortBy && sorted ) {
            if (!isEqual(sortBy, sorted)) onSortedChange(sortBy);
        }
        else if (!apiSorting && sortBy && sorted && sorted.length) {
            if (!isEqual(sortBy, sorted)) {
                onSortedChange(sortBy);
            }
            else {
                onSortedChange([{ id: sorted[0].id, desc: !sorted[0].desc }])
            }
        }
        //eslint-disable-next-line
    }, [sorted, sortBy]);

    useEffect(()=>{
        if(isRowVirtualized && data) setRowSizes(Array(data.length).fill(virtualizedHeight.row))
        //eslint-disable-next-line
    }, [data])
    useEffect(() => {
        if(isRowVirtualized && listRef.current) listRef.current.resetAfterIndex(0)
    //eslint-disable-next-line
    }, [rowSizes])
    const Row = ({ index, style }) => {
        let row = rows[index]
        prepareRow(row)
        return( <div key={row.id} style={style}>
            <div {...row.getRowProps()} className={classnames("rt-tr", index % 2 === 0 ? "-odd" : "-even", typeof trClassName === "function" ?  trClassName(row) : trClassName, (row.original?.isSelected || row.isSelected) ? "row-selected": "")}>
                {row.cells.map((cell, idx) => (
                    <div key={row.id+"-"+idx} {...getTdProps(cell, { expandRow })} {...cell.getCellProps()} className={classnames("rt-td", cell.column.className, tdColClassName)}>
                        {cell.render('Cell')}
                    </div>)
                )}
            </div>
            {row.isExpanded ? 
                SubComponent({ row })
                : null}
        </div>)
    };


    const getSize = i => {
        return rowSizes[i] || virtualizedHeight.row;
    };
    const handleListRef = (component) => {
        listRef.current = component;
    };

    const handleScroll = ({ scrollTop }) => {
        if (listRef?.current) listRef.current.scrollTo(scrollTop);
    };
    const renderRowVirtualized = () => {
        return(     
            <React.Fragment>
                <WindowScroller onScroll={handleScroll}>
                    {() => <div />}
                </WindowScroller>
             <AutoSizer disableHeight>
                 {({ width }) => 
                    <WindowList
                        ref={handleListRef}
                        itemCount={rows.length}
                        itemSize={getSize}
                        width={width > totalColumnsWidth ? width : totalColumnsWidth}
                        height={window.innerHeight}
                        className="h-100"
                    >
                        {Row}
                    </WindowList>
                }
            </AutoSizer>
            </React.Fragment>
        )
    }

    const downloadCSV = () => {
        let CSV = "";

        for(let headerGroup of headerGroups) {
            for(let column of headerGroup.headers){
                if(!column.isActionColumn){
                    CSV += '"' + htmlToText(column.render('Header')) + '",';
                }
            }
        }
        CSV += "\r\n";    
        for(let i = 0; i<rows.length; i++) {
            let row = rows[i];
            prepareRow(row)
            for(let cell of row.cells){
                if(!cell?.column?.isActionColumn){
                    const rowHTML = ReactDOMServer.renderToStaticMarkup(cell.render('Cell'))
                    let tempRow = rowHTML.replace(/<[^>]*>/g, '');
                    if(!tempRow){
                        if(rowHTML.includes("fa-check")) tempRow = "Yes"
                        else if(rowHTML.includes("fa-times")) tempRow = "No"
                        else if(rowHTML.includes("fa-chevron")) tempRow = ">"
                    }
                    CSV += '"' + tempRow + '",';
                }
            }
            CSV += "\r\n"; 
            if(subComponentVariable){
                for(let subItemInd = 0; subItemInd < row.original[subComponentVariable]?.length; subItemInd++){
                    let subItem = row.original[subComponentVariable][subItemInd]
                    let multipleFieldsIdx = {}, multipleFieldsLength = 0, subHeader = "", subRow = "", subMultipleRow = "";
                    row.cells.forEach((cell, i) => {
                        if (subItemInd === 0){
                            if(!cell?.column?.isActionColumn) subHeader += '"' + htmlToText(subTableColumns[i].Header) + '",';
                            if(subTableColumns[i]?.isMultiple) {
                                multipleFieldsIdx[i] = Array.from(subTableColumns[i].Cell(subItem, row.original)).map(el => htmlToText(el))
                                if(multipleFieldsLength < multipleFieldsIdx[i].length) multipleFieldsLength = multipleFieldsIdx[i].length
                            }
                        }
                        if(!cell?.column?.isActionColumn){
                            let tempRow = ""
                            if(multipleFieldsIdx[i]) tempRow = multipleFieldsIdx[i][0]
                            else tempRow = htmlToText(subTableColumns[i].accessor ? (subItem[subTableColumns[i].accessor] || "-") : (subTableColumns[i].Cell ? subTableColumns[i].Cell(subItem, row.original) : null))
                            subRow += '"' + tempRow + '",';
                        }
                    })
                    //multipleFieldsLength is needed so we can loop max subfields in the table
                    if(multipleFieldsLength > 1){
                        subMultipleRow = ""
                        for(let i = 1; i < multipleFieldsLength; i++){
                            for(let j = 0; j < row.cells.length; j++){
                                let tempRow = ""
                                if(multipleFieldsIdx[j]) tempRow = multipleFieldsIdx[j][i]
                                subMultipleRow += '"' + tempRow + '",';
                            }
                            subMultipleRow += "\r\n"; 
                        }
                    }

                    CSV += `${subHeader}\r\n${subRow}\r\n${subMultipleRow ? (subMultipleRow + "\r\n") : ""}`;    
                }
            }  
        }

        if (CSV === "") {
            alert("Invalid data");
            return;
        }

        //this trick will generate a hidden temp <a /> tag
        let link = document.createElement("a");
        link.href = "data:text/csv;charset=utf-8," + escape(CSV);
        link.style = "visibility:hidden";
        link.download = "Table Report.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    return (
        <>
        {enableDownload && <div className="d-flex justify-content-between align-items-center mt-3 mb-2">
            <div className='d-flex align-items-center'>
                <label className="bb-color_primary text-uppercase font-weight-bold m-0 mr-2">{props.dataFor}</label>
                <button className="bb-button align-self-end" onClick={() => downloadCSV()}><i className="fas fa-download mr-2" aria-hidden="true"></i>Export</button>
            </div>
            {props.enableDownloadComponent || ""}
        </div>}
        <div className={classnames(className, "ReactTable bb-all-tables")}>
            <div {...getTableProps} className="rt-table">
                <div className="rt-thead -header">
                    {sideColumns && sideColumnSettings?.topField ? <div className={classnames("rt-tr", thClassName)}>
                        <div className={classnames("rt-th", tdColClassName)} style={sideColumnSettings?.headerStyle}></div>
                        {data.map((item, idx) => <div key={idx} className={classnames("rt-th", tdColClassName, idx)} style={sideColumnSettings?.columnStyle}>
                            {item[sideColumnSettings.topField]}
                        </div>)}
                    </div>: headerGroups.map(headerGroup => (
                        <div {...headerGroup.getHeaderGroupProps()} className={classnames("rt-tr", thClassName)}>
                            {headerGroup.headers.map((column, i) => {
                                return (
                                    column.resizable === false ?
                                        <div {...column.getHeaderProps(column.getSortByToggleProps({title: typeof column?.Header === "string" ? column.Header : ""}))} className={classnames("rt-th", column.className, tdColClassName)}>
                                            {column.render('Header')}
                                            {column.tooltipText ? <Tooltip light={true} className="m-0 cursor-pointer bb-table-tooltip" label="" default_id={i} iconname="fa-info-circle">
                                                {column.tooltipText}
                                            </Tooltip> : null}
                                        </div>
                                        :
                                        <div {...column.getHeaderProps()} className={classnames("rt-th rt-resizable-header", (sorted && sorted.length) && column.id === sorted[0].id ? `-sort-${sorted[0].desc ? "desc" : "asc"} -cursor-pointer` : "", (column.canSort && (apiSorting || tableSorting || sorted?.length)) ? "" : "cursor-default", column.showFilter ? "d-inline-table" : "", column.className, tdColClassName)}>
                                            <div {...column.getSortByToggleProps({title: typeof column?.Header === "string" ? column.Header : ""})}className="rt-resizable-header-content d-flex justify-content-center align-items-center">
                                                {column.render('Header')}
                                            </div>
                                            <div {...column.getResizerProps()} className="rt-resizer"></div>
                                            <div>{column.showFilter ? column.render('Filter') : null}</div>
                                            {column.tooltipText ? <Tooltip light={true} className="m-0 cursor-pointer bb-table-tooltip" label="" default_id={i} iconname="fa-info-circle">
                                                {column.tooltipText}
                                            </Tooltip> : null}
                                        </div>
                                )
                            })
                            }
                        </div>
                    ))}
                </div>
                <div className="rt-tbody">
                    <div {...getTableBodyProps()} className="rt-tr-group">
                        
                        {isRowVirtualized ? renderRowVirtualized() : sideColumns ? 
                            sideColumns.map((sideCol, ind) => {
                                let classsRow = classnames("rt-tr", ind % 2 === 0 ? "-odd" : "-even")
                                return <div key={ind} className={classsRow}>
                                    <div className={classnames("font-weight-bold rt-td")} style={sideColumnSettings?.headerStyle}>
                                        {sideCol?.Header}
                                    </div>
                                    {data.map((item, idx) => <div key={idx} className={classnames("rt-td", tdColClassName)} style={sideColumnSettings?.columnStyle}>
                                        {sideCol.Cell ? sideCol.Cell(item) : item[sideColumns[idx].accessor]}
                                    </div>)}
                                </div>
                            }) :
                            rows.map((row, ind) => {
                                prepareRow(row)
                                let classsRow = classnames("rt-tr", ind % 2 === 0 ? "-odd" : "-even", typeof trClassName === "function" ?  trClassName(row) : trClassName, (row.original?.isSelected || row.isSelected) ? "row-selected": "", subComponentVariable ? "subtable-row" : "")
                                return (
                                    <React.Fragment key={row.id}>
                                        <div {...row.getRowProps()} className={classsRow}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <div {...getTdProps(cell)} {...cell.getCellProps()} className={classnames("rt-td", cell.column.className, tdColClassName)}>
                                                        {cell.render('Cell')}
                                                    </div>
                                                )
                                            })}


                                        </div>
                                        {row.isExpanded ?
                                            (subComponentVariable ?
                                                <>
                                                {row.original[subComponentVariable]?.map((subItem, subitemInd) =>
                                                <React.Fragment key={subitemInd}>
                                                    {subitemInd === 0 && <div className={classnames(classsRow, "subtable-expanded border-0 subtable-header-row")}>{row.cells.map((cell, i) => 
                                                        <div {...getTdProps(cell)} {...cell.getCellProps()} className={classnames("rt-td subtable-header", subTableColumns[i]?.headerClassName)}>
                                                            <div className="text-white font-weight-bold">{subTableColumns[i].Header}</div>
                                                        </div>
                                                    )}</div>}
                                                    <div className={classnames(classsRow, "subtable-expanded")}>
                                                        {row.cells.map((cell, i) => 
                                                            <div {...getTdProps(cell)} {...cell.getCellProps()} className={classnames("rt-td", subTableColumns[i]?.className)}>
                                                                {subTableColumns[i].accessor ? (subItem[subTableColumns[i].accessor] || "-") : (subTableColumns[i].Cell ? subTableColumns[i].Cell(subItem, row.original) : null)}
                                                            </div>
                                                        )}
                                                    </div>
                                                    </React.Fragment>
                                                )}
                                                <div className={classnames('pb-2 rt-tr h-auto', ind % 2 === 0 ? "-odd" : "-even")}></div>
                                                </>
                                                 : SubComponent({ row }))
                                            : null
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                    </div>
                </div>
                {showFooter ? <div className='rt-tfoot'>        
                    {footerGroups.map(group => (
                    <div {...group.getFooterGroupProps()} className={classnames("rt-tr")}>
                        {group.headers.map(column => (
                        <div {...column.getFooterProps()} className={classnames("rt-td", column.footerClassName)}>{column.render('Footer')}</div>
                        ))}
                    </div>
                    ))}            
                </div>
                : null
                }
            </div>
            <div className={classnames("-loading", loading ? "-active" : "")}>
                <div className="-loading-inner">Loading...</div>
            </div>
        </div>
        </>
    )
}

export default ReactTable;


