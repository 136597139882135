import React,{ useContext } from 'react';
import { Formik, Form, FastField } from 'formik'

import { FormInput } from '../form-helpers'
import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty'

import { signinUser } from '../../redux/user/user.actions';
import { connect } from 'react-redux';

import { getQueryStringParams, notify } from '../utilities/customFunctions'
import { SocketContext } from '../../socket';
import { useEffect } from 'react';
import { config } from '../../helpers';
import classnames from "classnames"


const LoginSchema = Yup.object().shape({
    password: Yup.string()
        .required('Required'),
    email: Yup.string()
        .email('Invalid email.')
        .required('Required'),
});


function Login(props) {
    
    const socket = useContext(SocketContext)
    const { errorMessage } = props;
    let { search } = useLocation()

    useEffect(() => {
  
        let msg = errorMessage;
        if (msg && msg.includes("~")) {
          msg = msg.substring(0, msg.indexOf("~"));
        }
        msg && notify(`Oops! ${msg}`, "e");

    }, [errorMessage])

    function handleFormSubmit(values, actions) {
        let searchParams = getQueryStringParams(search);
        var from = "";
        if (searchParams && searchParams.from) {
            from = searchParams.from;
        };
        props.signinUser(values, from, socket)
    }

    return (
        <div className="bb-login-form-container d-flex flex-row w-100 h-100">

            <div className="h-100 d-flex align-items-center">
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={LoginSchema}
                    onSubmit={handleFormSubmit}
                    >{({ dirty, errors, isSubmitting }) => (
                        <Form className="bb-login-form d-flex flex-column align-items-start">

                            {/* <img className="logo" src="/images/buddyLogo.svg" alt="" /> */}
                            <div className={classnames("img-fluid logo cursor-pointer", config.isBudEnterprise ? " enterprise": "")} ></div>
                            <span className='bb-text_small invisible spacer'>.</span>
                            <FastField containerClassName="w-100 mb-3 input-container" placeholder="Email" name="email" component={FormInput} type="email" keyPressCheck={false} />
                            <FastField containerClassName="w-100 mb-3 input-container" placeholder="Password" name="password" component={FormInput} type="password" keyPressCheck={false} />

                            <button type="submit" className="text-uppercase bb-bg_primary bb-signin-btn w-100 mb-2" disabled={!isEmpty(errors) || !dirty}>
                                    Sign in</button>
                            <div className="bb-signup d-flex flex-row flex-wrap justify-content-between w-100 align-items-center" >
                                <Link to="/forgotpassword" className="bb-forgot-password hvr-underline-from-center bb-text_small">Reset password</Link>
                                <a href="https://bigads.co/contact-us/#form" target={"_blank"} className="hvr-underline-from-center bb-text_small" rel="noopener noreferrer">Book a demo</a>                                
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );

}

//export default Login;


const mapStateToProps = (state) => {
    return {
        errorMessage: state.user.error,
        loading: state.user.loading
    }
};

export default (connect(mapStateToProps, { signinUser })(Login));

