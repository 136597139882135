import React, { useState } from 'react';
import Modal from "react-modal";

function FeeModal(props) {
    const { model } = props;
    const [isModalOpen, setIsModalOpen] = useState(false)

    const closeModal = () => { setIsModalOpen(false) }

    const renderIframeModal = () => {
        return (
            <Modal
                isOpen={isModalOpen ? true : false}
                onRequestClose={closeModal}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container mx-400"
                bodyOpenClassName="overflow-hidden"
                ariaHideApp={false}
            >
                <div className='col-sm-12 col-md-12 d-flex py-5 flex-column justify-content-center h-300'>
                    {/* <img className="mx-300 m-auto pb-4" src={`${getEnterpriseLogo()}`} alt='' /> */}
                    <>
                    <div className="d-flex flex-row justify-content-between">
                        <h5><strong>Spend Commitment</strong></h5>
                        <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-dark" onClick={(e) => closeModal(e)}>
                        </div>
                    </div>
                        
                        {model === 1 ? 
                            <p className="mt-2">This monthly spend commitment is used to set the CPM rate for the quarter. We will review it at the end of each quarter to assess whether it needs to move into another spend band.</p>
                            : model === 2 ?
                            <p className="mt-2">This monthly spend commitment along with the CPM rates are used to set the <strong>Percentage Rewards</strong> for the quarter. We will review it at the end of each quarter to assess whether it needs to move into another spend band.
                            </p>
                            : 
                            <p className="mt-2">This monthly spend commitment is used to set the <strong>Base CPM Buy Rates</strong> for the quarter. We will review it at the end of each quarter to assess whether it needs to move into another spend band.</p>

                        }
                        
                        <button type="button" onClick={() => closeModal()} className="bb-button align-self-center mt-2" disabled={false}>Ok</button>
                    </>



                </div>
            </Modal>)
    }

    return (
        <>
            {isModalOpen ? renderIframeModal() : null}
            <span className='mt-2 order-2'>
                <i className="fas pl-2 fa-info-circle cursor-pointer " onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsModalOpen(true)
                }}> </i>
            </span>
        </>

    );
}
export default FeeModal;