
import { AllContextualConstants } from './contextual-media.constants';

export const Contextual = (state = {}, action) => {
    switch (action.type) {
        case AllContextualConstants.EMPTY_DEAL_TYPE:
            return { ...state, dealType: "" };
        case AllContextualConstants.GET_DEAL_TYPE:
            return { ...state, dealType: action.payload };
        case AllContextualConstants.DEAL_TYPE_LOADING:
            return { ...state, dealTypeLoading: action.payload };
        case AllContextualConstants.GET_DEAL_TYPE_ERROR:
            return { ...state, dealType: "" };

            case AllContextualConstants.EMPTY_DEAL_TYPE_POP:
            return { ...state, popDealType: "" };
        case AllContextualConstants.GET_DEAL_TYPE_POP:
            return { ...state, popDealType: action.payload };
        case AllContextualConstants.GET_DEAL_TYPE_POP_ERROR:
            return { ...state, popDealType: "" };   

        case AllContextualConstants.EMPTY_DEAL_TYPE_FORMAT_CAROUSEL:
            return { ...state, formatCarousel: "" };
        case AllContextualConstants.GET_DEAL_TYPE_FORMAT_CAROUSEL:
            return { ...state, formatCarousel: action.payload };
        case AllContextualConstants.DEAL_TYPE_FORMAT_CAROUSEL_LOADING:
            return { ...state, formatCarouselLoading: action.payload };
        case AllContextualConstants.GET_DEAL_TYPE_FORMAT_CAROUSEL_ERROR:
            return { ...state, formatCarousel: "" };


        case AllContextualConstants.ADD_SELECTED_DEAL_TYPE_SUCCESS:
            return { ...state, addDealTypeStatus: true, addDealTypeResp: action.payload };
        case AllContextualConstants.ADD_SELECTED_DEAL_TYPE_ERROR:
            return { ...state, addDealTypeStatus: false, addDealTypeResp: action.payload };

        case AllContextualConstants.UPDATE_SELECTED_DEAL_TYPE_SUCCESS:
            return { ...state, editDealTypeStatus: true, editDealTypeResp: action.payload };
        case AllContextualConstants.UPDATE_SELECTED_DEAL_TYPE_ERROR:
            return { ...state, editDealTypeStatus: false, editDealTypeResp: action.payload };

        case AllContextualConstants.UPDATE_SELECTED_DEAL_PLAN_SUCCESS:
            return { ...state, editDealPlanStatus: true, editDealPlanResp: action.payload };
        case AllContextualConstants.UPDATE_SELECTED_DEAL_PLAN_ERROR:
            return { ...state, editDealPlanStatus: false, editDealPlanResp: action.payload };
            
        case AllContextualConstants.GET_CONTEXUAL_MEDIA_AD_BY_ID:
            return { ...state, dealData: action.payload, error: '' }
        case AllContextualConstants.GET_CONTEXUAL_MEDIA_AD_BY_ID_ERROR:
            return { ...state, dealData: false, error: action.payload }

        case AllContextualConstants.SELECTED_CONTEXUAL_MEDIA_LOADING:
            return { ...state, loading: action.payload };

            
        case AllContextualConstants.CHECK_TOKEN:
            return { ...state, checkToken: action.payload, error: '' }
        case AllContextualConstants.CHECK_TOKEN_ERROR:
            return { ...state, checkToken: false, error: action.payload }
        case AllContextualConstants.CHECK_TOKEN_LOADING:
            return { ...state, loadingCheckToken: action.payload };

        case AllContextualConstants.EMPTY_CONTEXUAL_MEDIA_AD_DATA:
            return { ...state, dealData: action.payload };


        case AllContextualConstants.EMPTY_PRE_DEFINE_MEDIAS:
            return { ...state, preDefineMedias: "", preDefineMediasBehave: "" };
        case AllContextualConstants.GET_PRE_DEFINE_MEDIAS:
            return { ...state, preDefineMedias: action.payload.preDefineMedias, preDefineMediasBehave: action.payload.preDefineMediasBehave };
        case AllContextualConstants.GET_PRE_DEFINE_MEDIAS_LOADING:
            return { ...state, preDefineMediasLoading: action.payload };
        case AllContextualConstants.GET_PRE_DEFINE_MEDIAS_ERROR:
            return { ...state, preDefineMedias: "", preDefineMediasBehave: "" };

        case AllContextualConstants.EMPTY_BESPOKE_MEDIAS:
            return { ...state, bespokeMedias: "" };
        case AllContextualConstants.GET_BESPOKE_MEDIAS:
            return { ...state, bespokeMedias: action.payload };
        case AllContextualConstants.GET_BESPOKE_MEDIAS_LOADING:
            return { ...state, bespokeMediasLoading: action.payload };
        case AllContextualConstants.GET_BESPOKE_MEDIAS_ERROR:
            return { ...state, bespokeMedias: "" };

        case AllContextualConstants.ADD_SELECTED_MEDIAS_SUCCESS:
            return { ...state, addSelectedMediasStatus: true, addSelectedMediasResp: action.payload };
        case AllContextualConstants.ADD_SELECTED_MEDIAS_ERROR:
            return { ...state, addSelectedMediasStatus: false, addSelectedMediasResp: action.payload };

        case AllContextualConstants.EMPTY_BESPOKE_CATEGORIES:
            return { ...state, bespokeCategories: "" };
        case AllContextualConstants.GET_BESPOKE_CATEGORIES:
            return { ...state, bespokeCategories: action.payload };
        case AllContextualConstants.GET_BESPOKE_CATEGORIES_LOADING:
            return { ...state, bespokeCategoriesLoading: action.payload };
        case AllContextualConstants.GET_BESPOKE_CATEGORIES_ERROR:
            return { ...state, bespokeCategories: "" };


        case AllContextualConstants.ADD_BESPOKE_MEDIA_SUCCESS:
            return { ...state, addBespokeStatus: true, addBespokeResp: action.payload };
        case AllContextualConstants.ADD_BESPOKE_MEDIA_ERROR:
            return { ...state, addBespokeStatus: false, addBespokeResp: action.payload };

        case AllContextualConstants.UPDATE_BESPOKE_MEDIA_SUCCESS:
            return { ...state, editBespokeStatus: true, editBespokeResp: action.payload };
        case AllContextualConstants.UPDATE_BESPOKE_MEDIA_ERROR:
            return { ...state, editBespokeStatus: false, editBespokeResp: action.payload };


        case AllContextualConstants.UPDATE_BESPOKE_STATUS_SUCCESS:
            return { ...state, updateBespokeStatus: true, updateBespokeResp: action.payload };
        case AllContextualConstants.UPDATE_BESPOKE_STATUS_ERROR:
            return { ...state, updateBespokeStatus: false, updateBespokeResp: action.payload };

        case AllContextualConstants.UPDATE_BESPOKE_STATUS_LOADING:
            return { ...state, bespokeUpdateLoading: action.payload };

        default:
            return state;
    }
}
