import React from "react";
import Pagination from '../../utilities/pagination'
import TableRows from '../../utilities/table-rows'
import TableSkeleton from './TableSkeleton'
import ReactTable from './ReactTable'
import './bb-table.scss'
import classNames from "classnames";

export const BuddyTable = (props) => {
  const {
    paginateClassName = "",
    containerPaginateClassName = "",
    data,
    skeletonloading = false,
    skeletonRows = 10,
    skeletonColumns = 5,
    skeletonPagesize = 10,
    pagesize,
    total,
    currentPage = 1,
    onChangePage,
    columns,
    showPagination = true,
    //showPageSizeOptions = false,
    //sortable = false,
    //resizable = true,
    loading = false,
    //onSortedChange = null,
    //minRows = 0,
    className,
    emptyMessage = false,
    sorted = [],
    //getTdProps,
    //SubComponent,
    showRowtable = true,
    onChangeRow = props.handlePageSize,
    //getTrProps
  } = props;

  return (
    (loading && skeletonloading
      ?
      <TableSkeleton rows={skeletonRows} columns={skeletonColumns} pagesize={skeletonPagesize} className={className}  />
      :
      (data instanceof Array && data.length > 0) ? (
        <React.Fragment>
          <ReactTable
            data={data} 
            columns={columns} 
            className={className} 
            loading={loading} 
            sorted = {sorted || []}
            {...props}
          />
          
         
          {total && showRowtable  ?
            <div className={classNames("d-flex flex-wrap col-12 justify-content-center p-0 m-0", containerPaginateClassName)}>              
              {showPagination && <div className=" p-0 m-0 pl-3">
                <Pagination total={total} pagesize={pagesize} current={currentPage} onChangePage={onChangePage} paginateClassName={paginateClassName} />
              </div>}
              <div className="p-0 m-0 d-flex align-items-center">
                <TableRows onChangeRow={onChangeRow} defaultValue={pagesize} />
              </div>
            </div> : showPagination && <Pagination total={total} pagesize={pagesize} current={currentPage} onChangePage={onChangePage} paginateClassName={paginateClassName} />
          }
        </React.Fragment>
      )
        : (
          <div className="bb-no-datas">
            {emptyMessage ? emptyMessage :
              (data && <React.Fragment>
                No Data Found.
              </React.Fragment>)
            }
          </div >
        )
    )
  )
}
