import React from 'react'
import { FastField, Formik, Form, Field } from 'formik';
import {
    SelectInput,
    NumberFormatInput,
    PercentageInput,
    FormInput,
    CheckBoxInput
} from '../../form-helpers'
import classNames from 'classnames';
import { getCalcModalInitialValues, getDiscountCalcModal, getRefundCalcModal } from './schema';

import Toggle from 'react-toggle';
import BilledByAgencyArrow from './arrow';
import { confirmAlert } from 'react-confirm-alert';


export const renderFastField = (label, placeholder, name, type = "text", disabled = false, textArea = false, isOptional = false) => {
    return (
        <FastField className="mb-1" containerClassName="form-row col-md-12  align-items-center" inputContainerClassName="col-6"
            labelClassName="col-6 text-right mb-1 pt-0" label={label} placeholder={placeholder}
            name={name} component={FormInput} type={type}
            disabled={disabled} isOptional={isOptional}
        />
    )
}


export const renderSelectInput = (name = "", options = "", placeholder = "0", prefixLabel = "$", className = "text-right w-137", label = "", containerClassName = "", outeLabel = "", disabled = false) => {
    return (
        <div className={containerClassName}>
            {outeLabel ? <label className='mb-0'>{outeLabel}</label> : null}
            <Field name={name} component={SelectInput} options={options}
                isEdit={true} placeholder={placeholder} prefixLabel={prefixLabel}
                className={className}
                disabled={disabled}
                label={label} />
        </div>
    )
}


export const renderNumberInput = (name = "", placeholder = "0", prefixLabel = "$", minValue = 0, decimalScale = 0, className = "text-right", label = "", labelClassName = "d-none", containerClassName = "", inputClass = "", contClass = "", pfc="") => {
    return (
        <div className={containerClassName}>
            <FastField name={name} isEdit={true} placeholder={placeholder} prefixLabel={prefixLabel}
                minValue={minValue} decimalScale={decimalScale} component={NumberFormatInput}
                className={className}
                label={label} labelClassName={labelClassName} prefixLabelClassname={pfc}
                inputContainerClassName={inputClass} containerClassName={contClass}
            />
        </div>
    )
}




export function CalcModalForm({ setIsCalcModalOpen, calcFormik, Schema,
    PREMIUM_CPM, ADVANCED_CPM, calculateFees, showCalculation, pm2, ad2, setShowCalculation, calculateSaasFees, modelL, totalSpendSumCheck, country, CMPModel
}) {
    const containerClass = "form-row col-md-12 mb-3";
    const colB = "col-7"
    return (
        <div className="script-modal" onClick={event => event.stopPropagation()}>
            <div className="d-flex flex-row justify-content-between mb-3 modal-header bb-bg_primary">
                <h4 className="text-white"><i className="fas fa-calculator"></i> Usage-Based SaaS Fee Calculator</h4>
                <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-white" onClick={(e) => setIsCalcModalOpen(false)}>
                </div>
            </div>
            <Formik
                innerRef={calcFormik}
                validationSchema={Schema}
                enableReinitialize={true}
                initialValues={{
                    ...getCalcModalInitialValues(pm2, ad2)
                }}
            >{({ setFieldValue, dirty, isSubmitting, values, submitCount, errors, isValidating }) =>
            (
                <Form autoComplete="off" className="form-row bb-form-commercials-calc bb-form bb-form-mw pt-4 bb-form-right-align-2-col px-3">

                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 pt-3 text-right align-self-center">Client Facing CPM Rate</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            {renderSelectInput('premium_spend_2', PREMIUM_CPM, "0", "$", "text-right w-137", "", "mr-3 col-md-6 bb-ad-top", "Premium")}
                            {renderSelectInput('advanced_spend_2', ADVANCED_CPM, "0", "$", "text-right w-137", "", "mr-3 col-md-6 bb-ad-top", "Advanced")}
                        </div>
                    </div>

                    <div className={containerClass}>
                        <label className="mb-1 col-md-4 text-right align-self-center">Predicted Monthly Spend</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            {renderNumberInput("monthly_spend_premium", "0", "$", 0, 0, "text-right", "", "d-none", "mr-3 col-md-6 bb-ad-bottom")}
                            {renderNumberInput("monthly_spend_advanced", "0", "$", 0, 0, "text-right", "", "d-none", "mr-3 col-md-6 bb-ad-bottom")}



                            {/* </div> */}
                        </div>
                        {errors && errors.sumCheck && (
                            <span className="required mt-2 w-100 text-center">{errors.sumCheck}</span>)}
                    </div>

                    <button type="button" className="bb-button roas-button p-2 mb-3 mx-auto w-100"
                        disabled={(isSubmitting || !dirty || errors) && Object.keys(errors).length}
                        onClick={() => calculateFees(values, setFieldValue, setShowCalculation, calculateSaasFees, modelL, totalSpendSumCheck, country, CMPModel)}>Calculate</button>

                    {showCalculation ? <div className='col-md-12 bb-border-yellow rounded py-3 mb-2 bb-animate fadeIn'>


                        {/* name = "", placeholder = "0", prefixLabel = "$", minValue = 0, decimalScale = 0, className = "text-right", label = "", labelClassName = "d-none", containerClassName = "", inputClass = "", contClass = "" */}
                        {renderNumberInput("total_predicted_spend", "0", "$", 0, 0, "bb-form-input-label-2 disabled", "Total Monthly Spend", "mb-1 col-8 pt-2 text-right", "d-flex flex-row", "col-4", "form-row p-0 mb-3 " + colB, "disabled")}


                        <div className="d-flex flex-row">
                            <div className={classNames("form-row p-0 mb-3 ", colB)}>
                                <label className={"mb-1 col-8 pt-2 text-right"}>{"Platform SaaS Fee %"}</label>
                                <div className="d-flex align-self-center text-center col-4">
                                    <div className='mr-3'>
                                        <Field name='bigads_saas_fee_percentage' className="text-right disabled maxWidth-186 w-137 bb-form-input-label-2"
                                            minValue={0} decimalScale={2} placeholder="0%" component={PercentageInput}
                                            label="" labelClassName="d-none"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {renderNumberInput("bigads_saas_fee", "0", "$", 0, 0, "bb-form-input-label-2 disabled", "Total Monthly Platform SaaS Fee", "mb-1 col-8 pt-2 text-right", "d-flex flex-row", "col-4", "form-row p-0 mb-3 " + colB, "disabled")}

                        {renderNumberInput("annual_fee", "0", "$", 0, 0, "bb-form-input-label-2 disabled", "Total Annualised Platform SaaS Fee", "mb-1 col-8 pt-2 text-right", "d-flex flex-row", "col-4", "form-row p-0 mb-3 " + colB, "disabled")}

                    </div> : null}
                </Form>
            )}
            </Formik>


        </div>



    )

}


export function RefundCalcModal({ setIsRefundCalcModalOpen, calcFormik, DiscountSchema,
    PREMIUM_CPM_DISCOUNT_ALL, ADVANCED_CPM_DISCOUNT_ALL, prem_base_set, adv_base_set, prem_spend_set, adv_spend_set, isManagedService,
    totalSpendSumCheck, CMPModel, country,
    handleManageService, calculateRefundRates, calculateRefund, handleClientCPMPercentageChange }) {
    const colB = "col-6"
    return (
        <div key={0} className="script-modal" onClick={event => event.stopPropagation()}>
            <div className="d-flex flex-row justify-content-between modal-header bb-bg_primary">
                <h4 className="text-white"><i className="fas fa-calculator"></i> {"Rewards Calculator"}</h4>
                <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-white" onClick={(e) => setIsRefundCalcModalOpen(false)}>
                </div>
            </div>
            <Formik
                innerRef={calcFormik}
                validationSchema={DiscountSchema}
                enableReinitialize={true}
                initialValues={{
                    ...getRefundCalcModal(prem_base_set, adv_base_set, prem_spend_set, adv_spend_set, isManagedService)
                }}
            >{({ setFieldValue, dirty, isSubmitting, values, submitCount, errors, isValidating }) =>
            (
                <Form autoComplete="off" className="form-row bb-form-commercials-calc bb-form bb-form-mw pt-4 bb-form-right-align-2-col px-3 mr-0">


                    <Field type="hidden" name="managed_service_refund" />
                    <div className="form-row col-md-12 mb-2 ">
                        <label className={classNames("mb-1 col-md-4 text-right align-self-center")}></label>
                        <div className={classNames("d-flex align-self-center mx-2 col-md-6")}>
                            <label className={classNames("text-right align-self-center bb-white-space-nowrap")}>Own Trading Desk</label>
                            <div className="align-self-center mx-1">
                                {<Toggle
                                    key="toggle-measurement"
                                    checked={isManagedService}
                                    className="toggle-status bb-toggle toggle-blue"
                                    icons={false}
                                    onChange={(e) => handleManageService(e.target.checked, setFieldValue, 'managed_service_refund')}
                                />}
                            </div>
                            <label className={classNames("align-self-center bb-white-space-nowrap")}>Big Ads Managed Service</label>
                        </div>
                    </div>

                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 pt-3 text-right align-self-center">Standard CPM Rate</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">

                            {renderSelectInput('prem_spend', PREMIUM_CPM_DISCOUNT_ALL, "0", "$", "text-right w-137", "", "mr-3 col-md-6 bb-ad-top text-center", "Premium", true)}
                            {renderSelectInput('adv_spend', ADVANCED_CPM_DISCOUNT_ALL, "0", "$", "text-right w-137", "", "mr-3 col-md-6 bb-ad-top text-center", "Advanced", true)}


                        </div>
                    </div>

                    <div className="form-row col-md-12 mb-0">

                        <label className="mb-1 col-md-4 text-right align-self-center">Predicted Monthly Spend</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            {renderNumberInput("monthly_spend_prem", "0", "$", 0, 0, "text-right", "", "d-none", "mr-3 col-md-6 bb-ad-side")}

                            {renderNumberInput("monthly_spend_adv", "0", "$", 0, 0, "text-right", "", "d-none", "mr-3 col-md-6 bb-ad-side")}



                        </div>

                    </div>
                    {errors && errors.sumCheck ?
                        <div className="form-row col-md-12 mb-0">
                            <label className="mb-1 col-md-4 text-right align-self-center"></label>
                            <div className='col-md-6 mx-2'><div className="required text-center p-0">{errors.sumCheck}</div></div>
                        </div> : null
                    }
                    <button type="button" className="bb-button roas-button align-self-center bb-discount-calculate-button"
                        disabled={(isSubmitting || !dirty || errors) && Object.keys(errors).length}
                        onClick={() => calculateRefundRates(values, setFieldValue, totalSpendSumCheck, calculateRefund, CMPModel, country)}>Calculate</button>

                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 text-right align-self-center">Discounted CPM Rate</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">

                            {renderNumberInput("adjusted_cpm_prem", "0", "$", 0, 2, "text-right disabled", "", "d-none", "mr-3 col-md-6 bb-ad-side","","", "disabled")}
                            {renderNumberInput("adjusted_cpm_adv", "0", "$", 0, 2, "text-right disabled", "", "d-none", "mr-3 col-md-6 bb-ad-side","","", "disabled")}

                        </div>
                    </div>

                    <>

                        <div className={classNames("form-row col-md-12 mb-0")}>
                            <label className="mb-1 col-md-4 text-right align-self-center">Client Facing CPM Rate</label>
                            <div className="d-flex align-self-center mx-2 col-md-6">

                                <div className='mr-3 col-md-6 bb-ad-side'>

                                    <FastField name='proposed_sell_cpm_prem' component={SelectInput} options={PREMIUM_CPM_DISCOUNT_ALL}
                                        isEdit={true} placeholder="0" prefixLabel="$"
                                        onChange={(op, sfv) => calculateRefund(sfv)}
                                        className="text-right w-132"
                                        inputContainerClassName={'proposed_sell_cpm_prem'}
                                        label="" />
                                </div>
                                <div className='mr-3 col-md-6 bb-ad-side'>
                                    <FastField name='proposed_sell_cpm_adv' component={SelectInput} options={ADVANCED_CPM_DISCOUNT_ALL}
                                        isEdit={true} placeholder="0" prefixLabel="$"
                                        onChange={(op, sfv) => calculateRefund(sfv)}
                                        className="text-right w-132"

                                        label="" />
                                </div>
                            </div>
                        </div>

                        <div className={classNames("form-row col-md-12 mb-0")}>
                            <label className="mb-1 col-md-4 text-right align-self-center">% Increase Of Disc. CPM Rate</label>
                            <div className="d-flex align-self-center mx-2 col-md-6">
                                <div className='mr-3 col-md-6 bb-ad-side'>
                                    <FastField name='proposed_sell_cpm_percentage_prem' placeholder="0%"
                                        className="text-right w-132"
                                        decimalScale={2} component={PercentageInput}
                                        onBlur={e => handleClientCPMPercentageChange("prem", e, setFieldValue)}
                                        label="" labelClassName="d-none" />
                                </div>
                                <div className='mr-3 col-md-6 bb-ad-side'>
                                    <FastField name='proposed_sell_cpm_percentage_adv' placeholder="0%"
                                        className="text-right w-132"
                                        decimalScale={2} component={PercentageInput}
                                        onBlur={e => handleClientCPMPercentageChange("adv", e, setFieldValue)}
                                        label="" labelClassName="d-none" />
                                </div>
                            </div>
                        </div>

                        <div className={classNames("form-row col-md-12 mb-0")}>
                            <label className="mb-1 col-md-4 text-right align-self-center">Rewards Percentage</label>
                            <div className="d-flex align-self-center mx-2 col-md-6">
                                {/* <div className='d-flex'> */}
                                <div className='mr-3 col-md-6 bb-ad-side'>
                                    <FastField name='refund_percentage_prem' placeholder="0%"
                                        className="text-right disabled w-132"
                                        minValue={0} decimalScale={2} component={PercentageInput}
                                        label="" labelClassName="d-none" />
                                </div>
                                <div className='mr-3 col-md-6 bb-ad-side'>
                                    <FastField name='refund_percentage_adv' placeholder="0%"
                                        className="text-right disabled w-132"
                                        minValue={0} decimalScale={2} component={PercentageInput}
                                        label="" labelClassName="d-none" />
                                </div>
                            </div>
                        </div>
                        <div className={classNames("form-row col-md-12 mb-0")}>
                            <label className="mb-1 col-md-4 text-right align-self-center">Rewards CPM Rate</label>
                            <div className="d-flex align-self-center mx-2 col-md-6">

                                {renderNumberInput("refund_cpm_prem", "0", "$", 0, 2, "text-right disabled", "", "d-none", "mr-3 col-md-6 bb-ad-side","","", "disabled")}

                                {renderNumberInput("refund_cpm_adv", "0", "$", 0, 2, "text-right disabled", "", "d-none", "mr-3 col-md-6 bb-ad-side","","", "disabled")}


                            </div>
                        </div>
                        <div className={classNames("form-row col-md-12 mb-0")}>
                            <label className="mb-1 col-md-4 text-right align-self-center">Monthly Rewards</label>
                            <div className="d-flex align-self-center mx-2 col-md-6">
                                {/* <div className='d-flex'> */}

                                {renderNumberInput("monthly_refund_prem", "0", "$", 0, 2, "text-right disabled", "", "d-none", "mr-3 col-md-6 bb-ad-bottom","","", "disabled")}

                                {renderNumberInput("monthly_refund_adv", "0", "$", 0, 2, "text-right disabled", "", "d-none", "mr-3 col-md-6 bb-ad-bottom","","", "disabled")}


                            </div>
                        </div>
                        <div className={classNames("col-md-12  rounded py-3")}>

                            <div className="d-flex flex-row">
                                <FastField name='total_monthly_refund_p' placeholder="0%"
                                    labelClassName={classNames("mb-1 col-8 pt-2 text-right")}
                                    minValue={0} decimalScale={2} component={PercentageInput}
                                    inputContainerClassName={"col-4"} containerClassName={classNames("form-row p-0 mb-3 ", colB)}
                                    className="bb-form-input-label-2 disabled w-132"
                                    label={"Total Rewards Percentage"} />

                            </div>


                            {renderNumberInput("total_monthly_refund", "0", "$", 0, 0, "bb-form-input-label-2 disabled", "Total Monthly Rewards", "mb-1 col-8 pt-2 text-right", "d-flex flex-row", "col-4", "form-row p-0 mb-3 " + colB,"disabled")}

                            {renderNumberInput("total_annualised_refund", "0", "$", 0, 0, "bb-form-input-label-2 disabled", "Total Annualised Rewards", "mb-1 col-8 pt-2 text-right", "d-flex flex-row", "col-4", "form-row p-0 mb-3 " + colB, "disabled")}


                        </div>
                    </>
                </Form>
            )}
            </Formik>
        </div>
    )



}



export function DiscountCalcModal({ setIsDiscountCalcModalOpen, calcFormik,
    DiscountSchema, calculateDiscountFees, isManagedService, handleManageService, handleSellCPMChange, handleSellPercentageChange,
    PREMIUM_CPM_DISCOUNT_ALL, ADVANCED_CPM_DISCOUNT_ALL, showDiscountMargin, setShowDiscountMargin,
    prem_base_set, prem_spend_set, adv_base_set, adv_spend_set, totalSpendSumCheck, country, CMPModel, setShowCalculation, calculateMargin
}) {
    const colB = "col-6"
    return (
        <div className="script-modal" >
            <div className="d-flex flex-row justify-content-between modal-header bb-bg_primary">
                <h4 className="text-white"><i className="fas fa-calculator"></i> {"Discount Calculator"}</h4>
                <div className="bb-modal-close fas fa-times fa-2x cursor-pointer text-white" onClick={(e) => setIsDiscountCalcModalOpen(false)}>
                </div>
            </div>
            <Formik
                innerRef={calcFormik}
                validationSchema={DiscountSchema}
                enableReinitialize={true}
                initialValues={{
                    ...getDiscountCalcModal(prem_base_set, prem_spend_set, adv_base_set, adv_spend_set, isManagedService)
                }}
            >{({ setFieldValue, dirty, isSubmitting, values, submitCount, errors, isValidating }) =>
            (
                <Form autoComplete="off" className="form-row bb-form-commercials-calc bb-form bb-form-mw pt-4 bb-form-right-align-2-col px-3 mr-0">


                    <Field type="hidden" name="managed_service_discount" />
                    <div className="form-row col-md-12 mb-2">
                        <label className={classNames("mb-1 col-md-4 text-right align-self-center")}></label>
                        <div className={classNames("d-flex align-self-center mx-2 col-md-6")}>
                            <label className={classNames("text-right align-self-center bb-white-space-nowrap")}>Own Trading Desk</label>
                            <div className="align-self-center mx-1">
                                {<Toggle
                                    key="toggle-measurement"
                                    checked={isManagedService}
                                    className="toggle-status bb-toggle toggle-blue"
                                    icons={false}
                                    onChange={(e) => handleManageService(e.target.checked, setFieldValue, 'managed_service_discount')}
                                />}
                            </div>
                            <label className={classNames("align-self-center bb-white-space-nowrap")}>Big Ads Managed Service</label>
                        </div>
                    </div>

                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 pt-3 text-right align-self-center">Standard CPM Rate</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            {renderSelectInput('prem_spend', PREMIUM_CPM_DISCOUNT_ALL, "0", "$", "text-right w-137", "", "mr-3 col-md-6 bb-ad-top text-center", "Premium", true)}

                            {renderSelectInput('adv_spend', ADVANCED_CPM_DISCOUNT_ALL, "0", "$", "text-right w-137", "", "mr-3 col-md-6 bb-ad-top text-center", "Advanced", true)}


                        </div>
                    </div>

                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 text-right align-self-center">Predicted Monthly Spend</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='monthly_spend_prem' isEdit={true} placeholder="0" prefixLabel="$"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right"
                                    label="" labelClassName="d-none" />
                            </div>
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='monthly_spend_adv' isEdit={true} placeholder="0" prefixLabel="$"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right"
                                    label="" labelClassName="d-none" />
                            </div>

                        </div>

                    </div>
                    {errors && errors.sumCheck ?
                        <div className="form-row col-md-12 mb-0">
                            <label className="mb-1 col-md-4 text-right align-self-center"></label>
                            <div className='col-md-6 mx-2'><div className="required text-center p-0">{errors.sumCheck}</div></div>
                        </div> : null
                    }
                    <button type="button" className="bb-button roas-button align-self-center bb-discount-calculate-button"
                        disabled={(isSubmitting || !dirty || errors) && Object.keys(errors).length}
                        onClick={() => calculateDiscountFees(values, setFieldValue, totalSpendSumCheck, country, CMPModel, setShowCalculation, showDiscountMargin, calculateMargin)}>Calculate</button>
                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 text-right align-self-center">Impressions Purchased</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='imp_purchased_prem' isEdit={true} placeholder="0"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='imp_purchased_adv' isEdit={true} placeholder="0"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 text-right align-self-center">Volume Discount %</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='vol_discount_percentage_prem' placeholder="0%"
                                    className="text-right disabled w-132"
                                    minValue={0} decimalScale={2} component={PercentageInput}
                                    label="" labelClassName="d-none" />
                            </div>
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='vol_discount_percentage_adv' className="text-right disabled w-132" placeholder="0%0"
                                    minValue={0} decimalScale={2} component={PercentageInput}
                                    label="" labelClassName="d-none" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 text-right align-self-center">Discounted CPM Rate</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='adjusted_cpm_prem' isEdit={true} placeholder="0" prefixLabel="$"
                                    minValue={0} decimalScale={2} component={NumberFormatInput}
                                    className="text-right disabled" prefixLabelClassname="disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='adjusted_cpm_adv' isEdit={true} placeholder="0" prefixLabel="$"
                                    minValue={0} decimalScale={2} component={NumberFormatInput}
                                    className="text-right disabled" prefixLabelClassname="disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 text-right align-self-center">Impressions Increase</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='imp_increase_prem' isEdit={true} placeholder="0"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                            <div className='mr-3 col-md-6 bb-ad-side'>
                                <FastField name='imp_increase_adv' isEdit={true} placeholder="0"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                        </div>
                    </div>
                    <div className="form-row col-md-12 mb-0">
                        <label className="mb-1 col-md-4 text-right align-self-center">Total Impressions (After Discount)</label>
                        <div className="d-flex align-self-center mx-2 col-md-6">
                            <div className={classNames('mr-3 col-md-6', showDiscountMargin ? 'bb-ad-side' : 'bb-ad-bottom')}>
                                <FastField name='adjusted_imp_prem' isEdit={true} placeholder="0"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                            <div className={classNames('mr-3 col-md-6', showDiscountMargin ? 'bb-ad-side' : 'bb-ad-bottom')}>
                                <FastField name='adjusted_imp_adv' isEdit={true} placeholder="0"
                                    minValue={0} decimalScale={0} component={NumberFormatInput}
                                    className="text-right disabled"
                                    label="" labelClassName="d-none" />
                            </div>
                        </div>
                    </div>
                    {
                        <>
                            <div className="form-row col-md-12 mb-0 bb-middle-border position-relative">
                                <label className="mb-1 col-md-4 text-right align-self-center"></label>
                                {/* <div className='col-md-6 mx-2'> <hr className="dotted" /> </div> */}
                                <div className="col-md-6 mx-2 accordion-arrow d-flex flex-row cursor-pointer mb-2 justify-content-end" onClick={() => setShowDiscountMargin(!showDiscountMargin)} >
                                    <span >{showDiscountMargin ? "Close Margin Section" : "Calculate Margin"}  <i className={classNames("fas fa-caret-left", showDiscountMargin ? "rotate" : "")} aria-hidden="true"></i></span>
                                </div>
                                {showDiscountMargin ? <BilledByAgencyArrow /> : null}
                            </div>

                            <div className={classNames("accordion-content form-row col-md-12 mb-0", showDiscountMargin ? "open overflow-visible" : "")}>
                                <label className="mb-1 col-md-4 text-right align-self-center">Client Facing CPM Rate and % Increase</label>
                                <div className="d-flex align-self-center mx-2 col-md-6">
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='proposed_sell_cpm_prem' component={SelectInput} options={PREMIUM_CPM_DISCOUNT_ALL}
                                            isEdit={true} placeholder="0" prefixLabel="$"
                                            onChange={(op, sfv) => handleSellCPMChange("prem", values, op, sfv)}
                                            className="text-right w-132"
                                            inputContainerClassName={'proposed_sell_cpm_prem'}
                                            label="" />
                                        <FastField name='proposed_sell_cpm_percentage_prem' placeholder="0%"
                                            className="text-right w-132"
                                            decimalScale={2} component={PercentageInput}

                                            onBlur={e => handleSellPercentageChange("prem", e, setFieldValue)}
                                            label="" labelClassName="d-none" />
                                    </div>
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='proposed_sell_cpm_adv' component={SelectInput} options={ADVANCED_CPM_DISCOUNT_ALL}
                                            isEdit={true} placeholder="0" prefixLabel="$"
                                            onChange={(op, sfv) => handleSellCPMChange("adv", values, op, sfv)}
                                            className="text-right w-132"

                                            label="" />
                                        <FastField name='proposed_sell_cpm_percentage_adv' placeholder="0%"
                                            className="text-right w-132"
                                            decimalScale={2} component={PercentageInput}
                                            onBlur={e => handleSellPercentageChange("adv", e, setFieldValue)}

                                            label="" labelClassName="d-none" />
                                    </div>
                                </div>
                            </div>

                            <div className={classNames("accordion-content form-row col-md-12 mb-0", showDiscountMargin ? "open" : "")}>
                                <label className="mb-1 col-md-4 text-right align-self-center">Margin CPM Rate</label>
                                <div className="d-flex align-self-center mx-2 col-md-6">
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='margin_prem' isEdit={true} placeholder="0" prefixLabel="$"
                                            minValue={0} decimalScale={2} component={NumberFormatInput}
                                            className="text-right disabled" prefixLabelClassname="disabled"
                                            label="" labelClassName="d-none" />
                                    </div>
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='margin_adv' isEdit={true} placeholder="0" prefixLabel="$"
                                            minValue={0} decimalScale={2} component={NumberFormatInput}
                                            className="text-right disabled" prefixLabelClassname="disabled"
                                            label="" labelClassName="d-none" />
                                    </div>
                                </div>
                            </div>
                            <div className={classNames("accordion-content form-row col-md-12 mb-0", showDiscountMargin ? "open" : "")}>
                                <label className="mb-1 col-md-4 text-right align-self-center">Total Sell Price to Client</label>
                                <div className="d-flex align-self-center mx-2 col-md-6">
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='total_sell_prem' isEdit={true} placeholder="0" prefixLabel="$"
                                            minValue={0} decimalScale={2} component={NumberFormatInput}
                                            className="text-right disabled" prefixLabelClassname="disabled"
                                            label="" labelClassName="d-none" />
                                    </div>
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='total_sell_adv' isEdit={true} placeholder="0" prefixLabel="$"
                                            minValue={0} decimalScale={2} component={NumberFormatInput}
                                            className="text-right disabled" prefixLabelClassname="disabled"
                                            label="" labelClassName="d-none" />
                                    </div>
                                </div>
                            </div>
                            <div className={classNames("accordion-content form-row col-md-12 mb-0", showDiscountMargin ? "open" : "")}>
                                <label className="mb-1 col-md-4 text-right align-self-center">Monthly Margin</label>
                                <div className="d-flex align-self-center mx-2 col-md-6">
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='monthly_margin_prem' isEdit={true} placeholder="0" prefixLabel="$"
                                            minValue={0} decimalScale={2} component={NumberFormatInput}
                                            className="text-right disabled" prefixLabelClassname="disabled"
                                            label="" labelClassName="d-none" />
                                    </div>
                                    <div className='mr-3 col-md-6 bb-ad-side'>
                                        <FastField name='monthly_margin_adv' isEdit={true} placeholder="0" prefixLabel="$"
                                            minValue={0} decimalScale={2} component={NumberFormatInput}
                                            className="text-right disabled" prefixLabelClassname="disabled"
                                            label="" labelClassName="d-none" />
                                    </div>
                                </div>
                            </div>

                            <div className={classNames("accordion-content form-row col-md-12 mb-0", showDiscountMargin ? "open" : "")}>
                                <label className="mb-1 col-md-4 text-right align-self-center">Margin Percentage</label>
                                <div className="d-flex align-self-center mx-2 col-md-6">
                                    <div className='mr-3 col-md-6 bb-ad-bottom'>
                                        <FastField name='margin_percentage_prem' placeholder="0%"
                                            className="text-right disabled w-132"
                                            minValue={0} decimalScale={2} component={PercentageInput}
                                            label="" labelClassName="d-none" />
                                    </div>
                                    <div className='mr-3 col-md-6 bb-ad-bottom'>
                                        <FastField name='margin_percentage_adv' className="text-right disabled w-132" placeholder="0%"
                                            minValue={0} decimalScale={2} component={PercentageInput}
                                            label="" labelClassName="d-none" />
                                    </div>
                                </div>
                            </div>

                            <div className={classNames("col-md-12  rounded py-3 my-2", showDiscountMargin ? "open bb-border-yellow" : "")}>

                                <div className="d-flex flex-row">
                                    <FastField name='total_monthly_margin_p' placeholder="0%"
                                        labelClassName={classNames("mb-1 col-8 pt-2 text-right")}
                                        minValue={0} decimalScale={2} component={PercentageInput}
                                        inputContainerClassName={"col-4"} containerClassName={classNames("form-row p-0 mb-3 ", colB)}
                                        className="bb-form-input-label-2 disabled w-132"
                                        label={"Total Margin Percentage"} />

                                </div>

                                <div className="d-flex flex-row">
                                    <FastField name='total_monthly_margin' minValue={0} decimalScale={0} component={NumberFormatInput}
                                        placeholder="0" prefixLabel="$" labelClassName={classNames("mb-1 col-8 pt-2 text-right")}
                                        inputContainerClassName={"col-4"} containerClassName={classNames("form-row p-0 mb-3 ", colB)}
                                        className="bb-form-input-label-2 disabled" prefixLabelClassname="disabled"
                                        label={"Total Monthly Margin"} />

                                </div>
                                <div className="d-flex flex-row">
                                    <FastField name='total_annualised_margin' minValue={0} decimalScale={0} component={NumberFormatInput}
                                        placeholder="0" prefixLabel="$" labelClassName={classNames("mb-1 col-8 pt-2 text-right")}
                                        inputContainerClassName={"col-4"} containerClassName={classNames("form-row p-0 mb-3 ", colB)}
                                        className="bb-form-input-label-2 disabled" prefixLabelClassname="disabled"
                                        label={"Total Annualised Margin"} />

                                </div>

                            </div>
                        </>
                    }
                </Form>
            )}
            </Formik>


        </div>
    )
}


export const renderIndependent = (values, containerClass, inputClass, labelClass, isEdit) => {
    return (
        <>
            <FastField containerClassName={containerClass}
                inputContainerClassName={inputClass} name="ssp_publisher_enabled"
                labelClassName={classNames(labelClass, "align-self-center")} component={CheckBoxInput} type="checkbox"
                label={"Agree To Use Criteo As SSP"}
                disabled={isEdit}
                checkedValue={values.ssp_publisher_enabled} />
            {values && !values.ssp_publisher_enabled ?
                <>
                    <div className={"form-row col-12"}>
                        <label className={labelClass}></label>
                        <div className={inputClass}>
                            <span className={classNames("required")}>
                                This option requires Criteo as your SSP. If deselected, the Usage-based SaaS option will not function</span>
                        </div>
                    </div>
                </>
                : null}

            <FastField containerClassName={containerClass}
                inputContainerClassName={classNames(inputClass, "d-flex align-items-center")}
                labelClassName={classNames(labelClass, "align-self-center")}
                className="col-3"
                disabled={isEdit}
                label={"Agree to Grant Big Ads Criteo Curation Access"} component={CheckBoxInput}
                name="criteo_set_enabled" type="checkbox"
                checkedValue={values.criteo_set_enabled} />

            {values && !values.criteo_set_enabled ?
                <>
                    <div className={"form-row col-12 mb-2"}>
                        <label className={labelClass}></label>
                        <div className={inputClass}>
                            <span className={classNames("required")}>
                                This option requires Criteo as your SSP. If deselected, the Usage-based SaaS option will not function</span>
                        </div>
                    </div>
                </>
                : null}
        </>

    )
}



export const confirmActionApproved = (handleSubmit, values) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <div className='bb-confirm-box' style={{ maxWidth: "470px" }}>
                    <h4 className="pb-3 ft-title">Are you sure you want to approve the contract ?</h4>
                    <div className="d-flex flex-row justify-content-center">
                        <span className="mr-4 text-danger text-center align-self-center  hvr-underline-from-center" onClick={onClose}>Close</span>
                        <button type="button" className="bb-button ml-4" onClick={() => { handleSubmit(values); onClose() }}>Proceed</button>
                    </div>
                </div>
            )
        }
    })
}