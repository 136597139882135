import React, { useState } from 'react';
import Modal from "react-modal";
import Slider from '../../../utilities/react-slider';

function SupportModal(props) {
    
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [slider, setSlider] = useState(0);

    const closeModal = () => { setIsModalOpen(false) }

    const renderIframeModal = () => {
        return (
            <Modal
                isOpen={isModalOpen ? true : false}
                onRequestClose={closeModal}
                overlayClassName="bb-modal-overlay"
                className="bb-modal-container"
                bodyOpenClassName="overflow-hidden"
                ariaHideApp={false}
            >
                <Slider onRef={ref => setSlider(ref)} touchDisabled={true} slideNo={4}>
                    <div key={0} onClick={event => event.stopPropagation()}>
                        <div className="d-flex flex-row justify-content-end mb-3">
                            <div className="bb-modal-close fas fa-times fa-2x cursor-pointer" onClick={(e) => closeModal()}></div>
                        </div>
                        <div className='col-sm-12 col-md-12 d-flex pl-5 py-5 flex-column justify-content-center'>
                            <div className='d-flex justify-content-between pr-3 mb-2'>
                                <h5 className='font-weight-bold'>Standard Package – Free</h5>
                                <h5 className='font-weight-bold bb-color_primary'>Support Packages</h5>
                            </div>
                            For agencies looking for basic support to establish and operate their in-house trading desks
                            
                            {renderItems([
                                {title: "Training & Onboarding", value: "Basic training on Budenterprise."},
                                {title: "Help Desk Support", value: "Standard email support (Monday–Friday, 9 AM–6 PM)."},
                            ])}
                            {renderListItems([
                                {title: "Response Times", value: "24 hours for standard queries, 48 hours for complex requests."},
                                {title: "Upgrade/Downgrade Flexibility", value: "30-day notice required."},
                            ])}
                        </div>
                    </div>

                    <div key={1} onClick={event => event.stopPropagation()}>
                        <div className="d-flex flex-row justify-content-end mb-3">
                            <div className="bb-modal-close fas fa-times fa-2x cursor-pointer" onClick={(e) => closeModal()}></div>
                        </div>
                        <div className='col-sm-12 col-md-12 d-flex pl-5 py-5 flex-column justify-content-center'>
                            <div className='d-flex justify-content-between pr-3 mb-2'>
                                <h5 className='font-weight-bold'>Foundation Package – $10,000/month</h5>
                                <h5 className='font-weight-bold bb-color_primary'>Support Packages</h5>
                            </div>
                            For agencies looking for essential support to establish and operate their in-house trading desks.
                            
                            {renderItems([
                                {title: "Training & Onboarding", value: "Core training on Budenterprise and programmatic best practices."},
                                {title: "Proposal Support", value: "Assistance in crafting advertiser proposals."},
                                {title: "Mock Proactives", value: "Development of proactive pitch materials."},
                                {title: "Go-To-Market (GTM) Deck Development ", value: "Guidance in structuring strategic presentations."},
                                {title: "Help Desk Support", value: "Standard email support (Monday–Friday, 9 AM–6 PM)."},
                            ])}
                            {renderListItems([
                                {title: "Response Times", value: "24 hours for standard queries, 48 hours for complex requests."},
                                {title: "Upgrade/Downgrade Flexibility", value: "30-day notice required."},
                            ])}
                        </div>
                    </div>
                    <div key={2} onClick={event => event.stopPropagation()}>
                        <div className="d-flex flex-row justify-content-end mb-3">
                            <div className="bb-modal-close fas fa-times fa-2x cursor-pointer" onClick={(e) => closeModal()}></div>
                        </div>
                        <div className='col-sm-12 col-md-12 d-flex pl-5 py-5 flex-column justify-content-center'>
                            <div className='d-flex justify-content-between pr-3 mb-2'>
                                <h5 className='font-weight-bold'>Growth Package – $15,000/month</h5>
                                <h5 className='font-weight-bold bb-color_primary'>Support Packages</h5>
                            </div>
                            For agencies needing advanced operational assistance.
                            
                            {renderItems([
                                {title: "Advanced Training", value: "In-depth programmatic workshops."},
                                {title: "Real-Time Proposal Assistance", value: "Faster support for advertiser pitches."},
                                {title: "Dedicated Mock Proactives", value: "Tailored pitch ideas to help win business."},
                                {title: "Priority Help Desk", value: <>Slack/Teams support with <span className='font-weight-bold'>faster response times</span>.</>},
                                {title: "Operational Backup", value: <><span className='font-weight-bold'>Limited</span> on-demand support during peak trading periods.</>},
                            ], "Everything in Foundation, plus")}
                            {renderListItems([
                                {title: "Response Times", value: "4 hours for critical issues, 12 hours for standard queries."},
                                {title: "Upgrade/Downgrade Flexibility", value: "30-day notice required."},
                            ])}
                        </div>
                    </div>
                    <div key={3} onClick={event => event.stopPropagation()}>
                        <div className="d-flex flex-row justify-content-end mb-3">
                            <div className="bb-modal-close fas fa-times fa-2x cursor-pointer" onClick={(e) => closeModal()}></div>
                        </div>
                        <div className='col-sm-12 col-md-12 d-flex pl-5 py-5 flex-column justify-content-center'>
                            <div className='d-flex justify-content-between pr-3 mb-2'>
                                <h5 className='font-weight-bold'>Elite Package – $20,000/month</h5>
                                <h5 className='font-weight-bold bb-color_primary'>Support Packages</h5>
                            </div>
                            For agencies requiring 24/7 operational redundancy, premium support, and hands-on strategic services.
                            
                            {renderItems([
                                {title: "Fully Managed GTM Decks", value: "Our team creates and refines client presentations."},
                                {title: "White-Glove Proposal Support", value: "Expert assistance in building compelling proposals."},
                                {title: "24/7 Operational Backup", value: "Dedicated specialists ensuring trading desk stability."},
                                {title: "Emergency Trading Desk Support", value: "Immediate intervention during downtime or staffing issues."},
                                {title: "Custom SLA", value: "Tailored response times and guaranteed service availability."},
                            ], "Everything in Growth, plus")}
                            {renderListItems([
                                {title: "Response Times", value: "1 hour for urgent issues, 4 hours for standard queries."},
                                {title: "Upgrade/Downgrade Flexibility", value: "30-day notice required."},
                            ])}
                        </div>
                    </div>
                </Slider>
            </Modal>)
    }
    const renderItems = (items, title = "Includes") => {
        return (<div className='mt-3'>
            <span className='font-weight-bold'>{title}:</span>
            {items.map(item => <div className='mt-1' key={item.title}>
                <i className="fas fa-check mr-1 bb-color_blue-300"></i> <span className='font-weight-bold'>{item.title}</span> – {item.value}
            </div>)}
        </div>)
    }
    const renderListItems = (items, title = "SLA Commitments") => {
        return (<div className='mt-4'>
            <span className='font-weight-bold'>{title}:</span>
            {items.map(item => <div className='mt-1' key={item.title}>
                <i className="fas fa-circle bb-font_10px mr-2"></i> <span className='font-weight-bold'>{item.title}</span>: {item.value}
            </div>)}
        </div>)
    }

    return (
        <>
            {isModalOpen ? renderIframeModal() : null}
            <span className='mt-2 order-2'>
                <i className="fas pl-2 fa-info-circle cursor-pointer " onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsModalOpen(true)
                }}> </i>
            </span>
        </>

    );
}
export default SupportModal;