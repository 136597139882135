
import { CuratedAdConstants } from "./c-ad.constants";
import axios from 'axios';
import { config } from '../../../helpers'
import { toFormData } from '../../../components/utilities/customFunctions'
import { GetCuratedAd } from '../../../components/creative/sales-demo-card'
import { GetCuratedAdCo2 } from '../../../components/creative/sales-demo-card/co2'

const ROOT_URL = config.apiServer;
const ROOT_URL_API = ROOT_URL + "/api";

export const saveSelectedMediaInfo = (values, medias) => dispatch => {
    dispatch(loading(true));

    axios.post(`${ROOT_URL_API}/save-selected-medias`,
        toFormData({

            accountids: typeof values.account == "object" ? values.account.id : values.account,
            name: values.name,
            dspId: typeof values.dsp == "object" ? values.dsp.network_id : values.dsp,
            selectedMedias: medias,
            is_mobile_app: typeof values.is_mobile_app == "object" ? values.is_mobile_app.value : values.is_mobile_app

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: CuratedAdConstants.ADD_SELECTED_MEDIA_DETAILS_SUCCESS, payload: response.data.data.id });
            }
            else {
                dispatch({ type: CuratedAdConstants.ADD_SELECTED_MEDIA_DETAILS_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(loading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.ADD_SELECTED_MEDIA_DETAILS_ERROR, payload: "Error~" + Date.now() });
            dispatch(loading(false));
        })
}


export const updateSelectedMediaInfo = (values, medias, id) => dispatch => {
    dispatch(editLoading(true));

    axios.post(`${ROOT_URL_API}/update-selected-medias/${id}`,
        toFormData({

            name: values.name,
            dspId: typeof values.dsp == "object" ? values.dsp.network_id : values.dsp,
            selectedMedias: medias,
            is_mobile_app: typeof values.is_mobile_app == "object" ? values.is_mobile_app.value : values.is_mobile_app

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: CuratedAdConstants.UPDATE_SELECTED_MEDIA_DETAILS_SUCCESS, payload: response.data.data.id + "~" + Date.now() });
            }
            else {
                dispatch({ type: CuratedAdConstants.UPDATE_SELECTED_MEDIA_DETAILS_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(editLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.UPDATE_SELECTED_MEDIA_DETAILS_ERROR, payload: "Error~" + Date.now() });
            dispatch(editLoading(false));
        })
}


export const getCuratedMediaAdById = (id) => {
    return (dispatch) => {
        dispatch(loading(true));

        if (id === config.DEMO_DEAL_ID || id === "mfd") {
            setTimeout(function () {
                dispatch({ type: CuratedAdConstants.GET_CURATED_MEDIA_AD_BY_ID, payload: GetCuratedAd(id === "mfd" ? false : true) });

                dispatch(loading(false));
            }, 100)
            return false;
        }
        else if (id === config.DEMO_DEAL_CO2_ID) {
            setTimeout(function () {
                dispatch({ type: CuratedAdConstants.GET_CURATED_MEDIA_AD_BY_ID, payload: GetCuratedAdCo2 });

                dispatch(loading(false));
            }, 100)
            return false;
        }
        else {
            axios.get(`${ROOT_URL_API}/get-curated-media-ad?ad_id=${id}`, { headers: { Authorization: config.authToken() } })
                .then(response => {
                    if (response.data && response.data.success) {
                        dispatch({ type: CuratedAdConstants.GET_CURATED_MEDIA_AD_BY_ID, payload: response.data.data });
                    }
                    else {
                        dispatch({ type: CuratedAdConstants.GET_CURATED_MEDIA_AD_BY_ID_ERROR, payload: response.data ? response.data.msg : "Error" });
                    }
                    dispatch(loading(false));
                }).catch(() => {
                    dispatch({ type: CuratedAdConstants.GET_CURATED_MEDIA_AD_BY_ID_ERROR, payload: false });
                    dispatch(loading(false));
                });
        }
    };
}

export const emptyAdData = () => dispatch => {
    dispatch({
        type: CuratedAdConstants.EMPTY_CURATED_MEDIA_AD_DATA,
        payload: ""
    });
}

const loading = (status) => {
    return {
        type: CuratedAdConstants.SELECTED_MEDIA_LOADING,
        payload: status
    };
};

const editLoading = (status) => {
    return {
        type: CuratedAdConstants.SELECTED_MEDIA_EDIT_LOADING,
        payload: status
    };
};


const renameLoading = (status) => {
    return {
        type: CuratedAdConstants.RENAME_LOADING_STATUS,
        payload: status
    };
};
export const renameAdvertisement = (name, id, accountId) => {
    return (dispatch) => {
        dispatch(renameLoading(true));
        axios.post(`${ROOT_URL_API}/rename-my-deal`,
            toFormData({
                id: id,
                name: name,
                accountId: accountId
            }), { headers: { Authorization: config.authToken() } })
            .then(response => {
                dispatch(renameLoading(false));
                if (response.data && response.data.success) {
                    dispatch({
                        type: CuratedAdConstants.RENAME_CURATED_DEAL,
                        payload: response.data.success + "~" + Date.now()
                    });
                }
                else {
                    dispatch({
                        type: CuratedAdConstants.RENAME_CURATED_DEAL_ERROR,
                        payload: response.data.msg + "~" + Date.now()
                    });
                }
            }).catch(error => dispatch({
                type: CuratedAdConstants.RENAME_CURATED_DEAL_ERROR,
                payload: "Error~" + Date.now()
            })
            )
    };
}


const checkDealLoading = (status) => {
    return {
        type: CuratedAdConstants.CHECK_IF_DEAL_EXISTS_LOADING,
        payload: status
    };
};

export const checkIfDealExists = (name, accountId) => {
    return (dispatch) => {
        dispatch(checkDealLoading(true));

        axios.get(`${ROOT_URL_API}/check-if-deal-exists?accountId=${accountId}&name=${name}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch({ type: CuratedAdConstants.CHECK_IF_DEAL_EXISTS, payload: response.data.exists + "" + Date.now() });
                }
                else {
                    dispatch({ type: CuratedAdConstants.CHECK_IF_DEAL_EXISTS_ERROR, payload: false });
                }
                dispatch(checkDealLoading(false));
            }).catch(() => {
                dispatch({ type: CuratedAdConstants.CHECK_IF_DEAL_EXISTS_ERROR, payload: false });
                dispatch(checkDealLoading(false));
            });

    };
}

const transferLoading = (status) => {
    return {
        type: CuratedAdConstants.TRANSFER_CREATIVE_LOADING,
        payload: status
    };
};
const bulkTransferLoading = (status) => {
    return {
        type: CuratedAdConstants.BULK_TRANSFER_CREATIVE_LOADING,
        payload: status
    };
};
export const transferCreative = (values, cre_id) => dispatch => {
    dispatch(transferLoading(true));
    axios.post(`${ROOT_URL_API}/trasnfer-creative-to-deal?accountId=${values.account.id}`,
        toFormData({

            accountId: values.account.id,
            accountName: values.account.name,
            ad_id: values.deal.id,
            refName: values.deal.name,
            oldCreativeId: cre_id

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: CuratedAdConstants.TRANSFER_CREATIVE_SUCCESS, payload: response.data.data });
            }
            else {
                dispatch({ type: CuratedAdConstants.TRANSFER_CREATIVE_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(transferLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.TRANSFER_CREATIVE_ERROR, payload: "Error~" + Date.now() });
            dispatch(transferLoading(false));
        })
}

export const assignCreative = (values, isBulk = false) => dispatch => {
    dispatch(transferLoading(true));
    axios.post(`${ROOT_URL_API}/${isBulk ? "bulk-assign-creative-to-deal" : "assign-creative-to-deal"}`,
        toFormData(values), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: CuratedAdConstants.TRANSFER_CREATIVE_SUCCESS, payload: isBulk ? response.data.transfer_success : response.data.data });
            }
            else {
                dispatch({ type: CuratedAdConstants.TRANSFER_CREATIVE_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(transferLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.TRANSFER_CREATIVE_ERROR, payload: "Error~" + Date.now() });
            dispatch(transferLoading(false));
        })
}
export const bulkTransferCreative = (values, cre_id) => dispatch => {
    dispatch(bulkTransferLoading(true));
    axios.post(`${ROOT_URL_API}/bulk_transfer_creative_to_deal?accountId=${values.account.id}`,
        toFormData({
            accountId: values.account.id,
            accountName: values.account.name,
            ad_id: values.deal.id,
            refName: values.deal.name,
            oldCreativeIds: cre_id
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: CuratedAdConstants.BULK_TRANSFER_CREATIVE_SUCCESS, payload: response.data });
            }
            else {
                dispatch({ type: CuratedAdConstants.BULK_TRANSFER_CREATIVE_ERROR, payload: response.data + "~" + Date.now() });
            }

            dispatch(bulkTransferLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.BULK_TRANSFER_CREATIVE_ERROR, payload: "Error~" + Date.now() });
            dispatch(bulkTransferLoading(false));
        })
}

const transferDealLoading = (status) => {
    return {
        type: CuratedAdConstants.TRANSFER_DEAL_LOADING,
        payload: status
    };
};
export const transferDeal = (values, id) => dispatch => {
    dispatch(transferDealLoading(true));

    axios.post(`${ROOT_URL_API}/transfer-deal`,
        toFormData({

            accountId: values.account.id,
            accountName: values.account.name,
            ad_id: id,

        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {

                dispatch({ type: CuratedAdConstants.TRANSFER_DEAL_SUCCESS, payload: "~" + Date.now() });
            }
            else {
                dispatch({ type: CuratedAdConstants.TRANSFER_DEAL_ERROR, payload: response.data.msg + "~" + Date.now() });
            }

            dispatch(transferDealLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.TRANSFER_DEAL_ERROR, payload: "Error~" + Date.now() });
            dispatch(transferDealLoading(false));
        })
}
const shareDealLoading = (status) => {
    return {
        type: CuratedAdConstants.SHARE_DEAL_LOADING,
        payload: status
    };
};
export const shareDeal = (users, id) => dispatch => {
    dispatch(shareDealLoading(true));
    axios.post(`${ROOT_URL_API}/give_access_to_users`,
        toFormData({
            users: users,
            id: id,
        }), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) {
                dispatch({ type: CuratedAdConstants.SHARE_DEAL_SUCCESS, payload: "~" + Date.now() });
            }
            else {
                dispatch({ type: CuratedAdConstants.SHARE_DEAL_ERROR, payload: response.data.msg + "~" + Date.now() });
            }
            dispatch(shareDealLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.SHARE_DEAL_ERROR, payload: "Error~" + Date.now() });
            dispatch(shareDealLoading(false));
        })
}

const performanceFeedbackLoading = (status) => {
    return {
        type: CuratedAdConstants.PERFORMANCE_FEEDBACK_LOADING,
        payload: status
    };
};

export const getPerformanceFeedbackById = (id) => {
    return (dispatch) => {
        dispatch(performanceFeedbackLoading(true));
        axios.get(`${ROOT_URL_API}/performance_feedback?deal_id=${id}`, { headers: { Authorization: config.authToken() } })
            .then(response => {
                if (response.data && response.data.success) dispatch({ type: CuratedAdConstants.GET_PERFORMANCE_FEEDBACK_BY_ID, payload: response.data.exists + "" + Date.now() });
                else dispatch({ type: CuratedAdConstants.GET_PERFORMANCE_FEEDBACK_BY_ID_ERROR, payload: false });
                dispatch(performanceFeedbackLoading(false));
            }).catch(() => {
                dispatch({ type: CuratedAdConstants.GET_PERFORMANCE_FEEDBACK_BY_ID_ERROR, payload: false });
                dispatch(performanceFeedbackLoading(false));
            });

    };
}

const submitFeedbackLoading = (status) => {
    return {
        type: CuratedAdConstants.SUBMIT_FEEDBACK_LOADING,
        payload: status
    };
};
export const submitFeedback = (values) => dispatch => {
    dispatch(submitFeedbackLoading(true));
    axios.post(`${ROOT_URL_API}/performance_feedback`,
        toFormData(values), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) dispatch({ type: CuratedAdConstants.SUBMIT_FEEDBACK_SUCCESS, payload: "~" + Date.now() });
            else dispatch({ type: CuratedAdConstants.SUBMIT_FEEDBACK_ERROR, payload: response.data.msg + "~" + Date.now() });
            dispatch(submitFeedbackLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.SUBMIT_FEEDBACK_ERROR, payload: "Error~" + Date.now() });
            dispatch(submitFeedbackLoading(false));
        })
}

const automateFeedbackLoading = (status) => {
    return {
        type: CuratedAdConstants.AUTOMATE_FEEDBACK_LOADING,
        payload: status
    };
};
export const automateFeedback = (values) => dispatch => {
    dispatch(automateFeedbackLoading(true));
    axios.post(`${ROOT_URL_API}/automate_performance_feedback `,
        toFormData(values), { headers: { Authorization: config.authToken() } })
        .then(response => {
            if (response.data && response.data.success) dispatch({ type: CuratedAdConstants.AUTOMATE_FEEDBACK_SUCCESS, payload: "~" + Date.now() });
            else dispatch({ type: CuratedAdConstants.AUTOMATE_FEEDBACK_ERROR, payload: response.data.msg + "~" + Date.now() });
            dispatch(automateFeedbackLoading(false));
        }).catch(error => {
            dispatch({ type: CuratedAdConstants.AUTOMATE_FEEDBACK_ERROR, payload: "Error~" + Date.now() });
            dispatch(automateFeedbackLoading(false));
        })
}
export const newKritikos = data => {
    return (dispatch) => {
        dispatch({ type: CuratedAdConstants.NEW_KRITIKOS, payload: data });
    }
}
export const calculateKritikos = (creative_id, html_tag, deal_id) => { 
    return (dispatch) => {
        dispatch({
            type: CuratedAdConstants.CALCULATE_KRITIKOS,
            payload: { dealID: deal_id, creativeID: creative_id }
        });
        axios.post(`${ROOT_URL_API}/calculate_kritikos_score`,
            toFormData({
                creative_id: creative_id,
                html_tag: html_tag
            }), { headers: { Authorization: config.authToken() } })
            .then(response => { })
            .catch(error => { })
    }
}