export const CommercialsConstants = {
    COMMERCIALS_CURRENT_PAGE: "COMMERCIALS_CURRENT_PAGE",
    COMMERCIALS_LIMIT_PER_PAGE: "COMMERCIALS_LIMIT_PER_PAGE",

    GET_COMMERCIALS_ERROR: "GET_COMMERCIALS_ERROR",
    GET_COMMERCIALS_SUCCESS: "GET_COMMERCIALS_SUCCESS",
    COMMERCIALS_LIMIT: "COMMERCIALS_LIMIT",
    COMMERCIALS_LOADING: "PERFORMANCE_FEEDBACK_LOADING",


    GET_COMMERCIALS_AGREEMENT_ERROR: "GET_COMMERCIALS_AGREEMENT_ERROR",
    GET_COMMERCIALS_AGREEMENT: "GET_COMMERCIALS_AGREEMENT",

    GET_COMMERCIALS_AGREEMENT_TCS: "GET_COMMERCIALS_AGREEMENT_TCS", 
    GET_COMMERCIALS_AGREEMENT_TCS_ERROR: "GET_COMMERCIALS_AGREEMENT_TCS_ERROR",

    SAVE_COMMERCIALS_AGREEMENT: "SAVE_COMMERCIALS_AGREEMENT", 
    SAVE_COMMERCIALS_AGREEMENT_ERROR: "SAVE_COMMERCIALS_AGREEMENT_ERROR",

    GET_COMMERCIALS_AGREEMENT_PROFILE: "GET_COMMERCIALS_AGREEMENT_PROFILE",
    GET_COMMERCIALS_AGREEMENT_PROFILE_ERROR: "GET_COMMERCIALS_AGREEMENT_PROFILE_ERROR",
    EMPTY_COMMERCIALS_AGREEMENT:"EMPTY_COMMERCIALS_AGREEMENT",

    COMMERCIALS_TCS_LOADING: "COMMERCIALS_TCS_LOADING"
};
