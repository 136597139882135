/* User level codes */

export const SUPER_ADMIN = 1;
export const NETWORK_MANAGER = 2; //Agency Group Admin
export const ACCOUNT_MANAGER = 3;
export const BIG_MOBILE_SALESPERSON = 5; //Salesperson
export const AFFILIATE = 8; //Salesperson outside Big Mobile
export const SALES_DIRECTOR = 9; //Salesperson director
export const C_LEVEL = 10; //CEO, CFO and CTO
export const ESG_TEAM = 11; //ESG TEAM outside
export const COMMERCIAL_MANAGER = 12; //Commercial


export const SALES = [BIG_MOBILE_SALESPERSON, AFFILIATE];
export const SALES_MANAGER = [SALES_DIRECTOR, C_LEVEL]
export const ALL_SALES = [...SALES, ...SALES_MANAGER];
/* User with sub codes */
export const CREATIVE_MANAGER = SUPER_ADMIN + 100;
export const ADMIN = SUPER_ADMIN + 101;
export const INTERNAL_USERS = [...ALL_SALES, SUPER_ADMIN]
export const EXTERNAL_USERS = [NETWORK_MANAGER, ACCOUNT_MANAGER, COMMERCIAL_MANAGER]

export const getRoleName = (role) => {
    switch (role) {
        case 1:
        case "1":
            return "Super Admin";
        case 2:
        case "2":
            return "Network Manager";
        case 3:
        case "3":
            return "Account Manager";
        case 5:
        case "5":
            return "Salesperson";
        case 8:
        case "8":
            return "Affiliate";
        case 9:
        case "9":
            return "Sales Director";
        case 10:
        case "10":
            return "C Level";
        case 11:
        case "11":
            return "ESG Team";
        case 12:
        case "12":
            return "Commercial Manager";
        default:
            return "No Role Found";
    }
}
