import { CMPConstants } from "./cmp.constants";

export const cmp = (state = {}, action) => {
    switch (action.type) {

        case CMPConstants.GET_ALL_CMP_PAGINATE_SUCCESS: return { ...state, ...action.payload }
        case CMPConstants.GET_ALL_CMP_PAGINATE_ERROR: return { ...state, ...action.payload }

        case CMPConstants.EMPTY_CMP_BY_PROFILE: return { ...state, profile: "" }
        case CMPConstants.GET_CMP_BY_ID: return { ...state, profile: action.payload }
        case CMPConstants.GET_CMP_BY_ID_ERROR: return { ...state, profile: action.payload }


        case CMPConstants.EMPTY_CMP_BY_COUNTRY: return { ...state, CMPModel: "" }
        case CMPConstants.GET_CMP_BY_COUNTRY: return { ...state, CMPModel: action.payload }
        case CMPConstants.GET_CMP_BY_COUNTRY_ERROR: return { ...state, CMPModel: action.payload }

        case CMPConstants.CMP_CURRENT_PAGE: return { ...state, page: action.payload }
        case CMPConstants.CMP_LIMIT_PER_PAGE: return { ...state, limit: action.payload }
        case CMPConstants.CMP_TOTAL_COUNT: return { ...state, total: action.payload }
        case CMPConstants.CMP_LOADING_STATUS: return { ...state, loading: action.payload }

        case CMPConstants.ADD_CMP_SUCCESS: return { ...state, addNewStatus: true, addNewResp: action.payload }
        case CMPConstants.ADD_CMP_ERROR: return { ...state, addNewStatus: false, addNewResp: action.payload }
        case CMPConstants.EDIT_CMP_SUCCESS: return { ...state, editStatus: true, editResp: action.payload }
        case CMPConstants.EDIT_CMP_ERROR: return { ...state, editStatus: false, editResp: action.payload }

        default:
            return state;
    }
};
