import React from 'react'
import { BuddyTable } from '../../table-helpers/BuddyTable-2';

function FeesHistory(props) {
    let data = []
    return (
        <>
            <h3 className="bb-sub-header-txt mb-4">Past Usage-Based SaaS Platform Fee Invoices</h3>
                <BuddyTable
                    defaultValue={1}
                    pagesize={1}
                    tableSorting={false}
                    showPagination={false}
                    showRowtable={false}
                    emptyMessage="No Data Found."
                    paginateClassName="col-12"
                    skeletonloading={false}
                    total={1}
                    loading={false}
                    currentPage={1}
                    data={data}
                    className="cursor-pointer col-12 buddy_light p-0"
                    dataFor="invoices"
                    columns={[
                        {
                            Header: "Month",
                            accessor: "month",
                            className: "text-center",
                            minWidth: 100,
                            Cell: ({ row }) => (row.original["billing month"])
                        },
                        {
                            Header: "Invoice Number",
                            accessor: "invoiceNumber",
                            className: "text-center",
                            minWidth: 100,
                        },
                        {
                            Header: "Amount",
                            accessor: "totalFee",
                            className: "text-center",
                            minWidth: 70,
                            Cell: ({ row }) => <span>${row.original.totalFeeWithSupport || row.original.totalFee}</span>

                        },
                        {
                            Header: "PDF",
                            accessor: "pdf",
                            className: "text-center",
                            minWidth: 100,
                            Cell: ({ row }) => <a style={{ color: "black" }} href={Number(row.id) <= 2 ? row.original.filePf : undefined} target="_blank">
                                <i className="fas fa-file-pdf"></i>
                            </a>

                        }
                    ]}
                />

        </>
    )
}

export default (FeesHistory);
