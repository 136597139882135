import React, { useEffect } from 'react'
import styled from 'styled-components'
import classnames from 'classnames';
import { isInterstitial } from '../../utilities/customFunctions'

import './index.scss'
import { airtimePreview } from '../../audiences/contextual/build-ads/yourself/builder/PRIMO-Creative/render-helpers';

const FakeText = styled.div`
    height: ${props => props.height}px;
    background-color: lightgrey;
`;

const SmallGutter = styled.div`
  margin-left: -4px !important;
  margin-right: ${props => props.marginRight || "11px"} !important;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
`;

const SmallGutterCol = styled.div`
  width: ${props => props.width};
  padding-left: 4px !important;
    padding-right: 4px !important;
`;

export default function PrimoPreviewLive(props) {
  const {isAirtime = false} = props;

  useEffect(() => {
    if(isAirtime){
      let tag = props.previewCreative?.html_tag || props.previewCreative?.scripts?.secureScript ||  props.previewCreative?.dimension?.previewTag
      if(tag){
        airtimePreview(tag, props.xl ? true : false)
      }
    }
  })

  const { isDesktop, width, height, dimension, BgColor = "" } = props;
  // eslint-disable-next-line
  const isScaledDown = width == 320;

  let isInterstitialCreative = false;

  let fullscreen = isInterstitial(dimension);

  const renderArticle = (short = true, desktop = false) => {
    return (
      !desktop ?
        <div className={classnames("col-12 noselect")}>
          <h2>Mobile</h2>
          <div className="placeholder fa-pull-left"></div>
          {short ?
            <p>...... ........ ... ....... ... ........ . ...... ..... ........ ... ...... ... ......... .. ....... ... ... .. .... ............</p>
            :
            <>
              <p>... ..... .. ...... ..... .. ... .. .... ...... .. ...... .......... .......... ... ... .... .. ..... .. ...... ... ..... .. ... ........ . ..... .. ... ..... ......... .. ... ........</p>
              <p>.. . ...... .. ... ........ ........ .. ....... .. ....... .. ... .... .. ...... ... ........ ....... .. ... .........</p>
            </>
          }
        </div>
        :
        <div className={classnames("col-12 noselect")}>
          <h2>Mobile</h2>
          <div className="d-flex flex-row">
            {short ?
              <p>...... ........ ... ....... ... ........ . ...... ..... ........ ... ...... ... ......... .. ....... ... ... .. .... ............</p>
              :
              <>
                <p>... ..... .. ...... ..... .. ... .. .... ...... .. ...... .......... .......... ... ... .... .. ..... .. ...... ... ..... .. ... ........ . ..... .. ... ..... ......... .. ... ........</p>
                <p>.. . ...... .. ... ........ ........ .. ....... .. ....... .. ... .... .. ...... ... ........ ....... .. ... .........</p>
              </>
            }
          </div>
        </div>

    )
  }

  const renderSmallGutter = (fakeTextHeight = 16, widths = [21, 39, 40], marginRight = "11px") => {
    return (
      <SmallGutter className="row" marginRight={marginRight}>
        <SmallGutterCol width={widths[0] + "%"}>
          <FakeText height={fakeTextHeight} />
        </SmallGutterCol>
        <SmallGutterCol width={widths[1] + "%"}>
          <FakeText height={fakeTextHeight} />
        </SmallGutterCol>
        <SmallGutterCol width={widths[2] + "%"}>
          <FakeText height={fakeTextHeight} />
        </SmallGutterCol>
      </SmallGutter>
    )
  }

  const renderBlock = (className = "", thCl = "", marginRight = "") => {
    return (
      <div className={`${className} p-0`}>
        <div className="row m-0">
          <div className={`col-12 w-100 pl-0 ${thCl}`}>
            <FakeText height="180" />
          </div>
        </div>
        {renderSmallGutter(16, [20, 31, 49], marginRight)}
        {renderSmallGutter(16, [30, 39, 31], marginRight)}
        {renderSmallGutter(16, [40, 31, 29], marginRight)}
      </div>
    )
  }

  const renderAd = (isMobile = true, width = 0) => {
    return (
      isMobile ?
        <div id="bm-creative-container" style={{ background: BgColor }} className={classnames(fullscreen ? "primo-fullscreen-container" : "", "bm-ad col-12")}>
          <div id="bm-preview-iframe-container" className={classnames(isScaledDown ? "scale-down" : "", fullscreen ? "fullscreen-child" : "")}></div>
        </div>
        :
        <>

          <div id="bm-creative-container" style={{ width: width ? width + "px" : "100%" }} className={classnames("bm-ad-desktop mx-auto", width && width > 320 ? "mb-4" : "")}>
            <div id="bm-preview-iframe-container"></div>
          </div>
        </>
    )
  }

  const renderMobile = (isInterstitialCreative = false) => {
    return (
      <React.Fragment>
        <div className={classnames("bm-creative-preview-ad-content")}>
          {renderArticle()}
          {!isInterstitialCreative ? renderAd() : null}
          {renderArticle(false)}
        </div>
      </React.Fragment>
    )
  }

  const renderDesktop = (width = 0, height = 0) => {
    let adPos = "center";
    if (width && width > 320) {
      adPos = "top";
    }

    return (
      <React.Fragment>
        <div className={"bm-creative-preview-ad-content bm-desktop-container row my-4"}>
          {adPos === "top" && renderAd(false, width)}
          {adPos !== "top"
            ? <>
              <div className="row noselect w-100 mb-4">
                <div className="col-12 noselect p-0">
                  <FakeText height="180" />
                </div>
              </div>

              <div className="row noselect w-100 mb-4">
                {height > 300 ?
                  <div className="d-flex flex-column flex-grow-1">
                    {renderBlock("flex-grow-1")}
                    {renderBlock("flex-grow-1")}
                  </div>
                  : <>
                    {renderBlock("flex-grow-1")}
                    {renderBlock("flex-grow-1")}
                  </>
                }
                {adPos === "center" && renderAd(false, width)}
              </div>
            </>
            :
            <>
              <div className="row noselect w-100 mb-4">
                {renderBlock("col-6")}
                {renderBlock("col-6", "pr-0", "-4px")}
              </div>
            </>
          }
          <div className="row noselect w-100 mb-4">
            {renderBlock("col-4")}
            {renderBlock("col-4")}
            {renderBlock("col-4", "pr-0", "-4px")}
          </div>
          <div className="row noselect w-100 mb-4">
            {renderBlock("col-4")}
            {renderBlock("col-4")}
            {renderBlock("col-4", "pr-0", "-4px")}
          </div>

          {adPos === "bottom" && renderAd(false, width)}

        </div>
      </React.Fragment>
    )
  }

  return (

    <>
      {fullscreen ? <div id="close-button" className="close-button-container" onClick={() => {
        let el = document.getElementById("bm-creative-container")
        let elB = document.getElementById("close-button")
        if (el && elB) {
          el.style.visibility = "hidden"
          elB.style.visibility = "hidden"
        }
      }} ><div className="close-button">×</div></div> : null}
      {isInterstitialCreative && <div id="bm-creative-container" className="bm-ad position-fixed" style={{ zIndex: 1 }}>
        <div id="bm-preview-iframe-container" className={isScaledDown ? "scale-down" : ""}></div>
      </div>
      }

      <div id="bm-creative-preview"
        className={classnames("bm-creative-preview", isDesktop ? "align-self-center iframe-desktop-primo" : "", props.xl ? "xl": "")}>
        <div className={classnames("bm-creative-preview-header noselect pointer-auto")}
          style={{ background: "#1b2134" }}>
          <img src={"/images/logo_demo_preview-live.png"} alt="logo" className="img-fluid img-responsive preview-logo" />
        </div>
        {
          (
            isDesktop ? renderDesktop(width, height)
              : renderMobile(isInterstitialCreative)
          )
        }
      </div>
    </>

  )
}
