import React, { Component } from 'react'
import classnames from 'classnames'
import "./accordion.scss"

export default class Accordion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }
    toggle() {
        const { isOpen } = this.state
        this.setState({ isOpen: !isOpen })
    }
    render() {
        const { label = "ADVANCED SETTINGS" } = this.props
        const { isOpen } = this.state

        return (
            <React.Fragment>
                    <div className="accordion-arrow d-flex flex-row" onClick={() => this.toggle()}>
                        <span className={classnames("right", isOpen ? "bottom" : "")}></span>
                        <h4 className="bb-color-grey m-1 ml-2">{label}</h4>
                    </div>
                <hr className="creative-dot-yellow mr-3"></hr>
                <div className={classnames("accordion-content", isOpen ? "open" : "")}>
                    {this.props.children}
                </div>
            </React.Fragment>
        )
    }
}
