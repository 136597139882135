
import React from 'react';
import MediaUpload from '../../../extras/media-upload'
import { FastField } from 'formik';
import { renderDimensionalGuide } from '../../../../../render-helpers/format-guide';
import Tooltip from '../../../../../../utilities/Tooltip';
import Accordion from '../../../../../../utilities/Accordion/index';
import { NumberFormatInput, FormToggle, ColorInput, SelectInput } from '../../../../../../form-helpers'
import _ from 'lodash';
import { SUPER_ADMIN } from '../../../../../../../helpers/roles';


export const airtimePreview = (tag, xl = false) => {
    var mainDiv = document.getElementById("bm-preview-iframe-container");
    let browserWidth = document.getElementsByClassName("bm-creative-preview")[0].clientWidth;
    let dynHeight = document.getElementsByClassName("bm-creative-preview")[0].clientHeight;
    let htmlCode = getGeneratedPageURL(tag);
    let iframeContent = `
    <iframe  width=${browserWidth}  onload="loadedIframe(${xl})"
    height=${dynHeight} 
      id="bm-creative"  name="native" class="pointer-auto mx-auto" scrolling='no' frameborder="0" src='${htmlCode}' >  
    </iframe>             
    `
    mainDiv.innerHTML = iframeContent;

}



export const getGeneratedPageURL = (src) => {
    const getBlobURL = (code, type) => {
        const blob = new Blob([code], { type })
        return URL.createObjectURL(blob)
    }
    let host_bb = window.location.protocol + "//" + window.location.host + '';

    const source = ` 
        <html>
        <head>
            <base href=${host_bb}>
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
            <style type='text/css'>
            .ay-watermark{
                display:none;
            }
            
            body{
                width: 100% !important;
            }
            </style>
            
        </head>
        <body style='margin:0px;'> 
       
        ${src}
        
        </body>
    </html>
    `

    return getBlobURL(source, 'text/html')
}


const calculateStartEnd = (setChanges, key, param, values, v, isStart = true, position = false) => {
    let pParam = position ? param.includes("X") ? "x" : "y" : false
    let pos = {}
    let inside, outside = ""
    if (isStart) {
        outside = Number(values[`${key}_${param}`]) || 0
        if (param.includes("opacity")) {
            inside = Number(v)
        }
        else if (param.includes("scale")) {
            outside = outside === 0 ? 0.01 : outside
            inside = Number(v) / outside
        }
        else {
            inside = Number(v) - outside
        }

    }
    else {
        outside = Number(v)
        inside = Number(values[`${key}_end_${param}`]) || 0
        if (param.includes("opacity")) { }
        else if (param.includes("scale")) {
            outside = outside === 0 ? 0.01 : outside
            inside = inside / outside
        }
        else {
            inside = inside - outside

        }
    }

    pos = position ? pParam === "x" ? { x: outside, y: Number(values[`${key}_posY`]) || 0 } : { x: Number(values[`${key}_posX`]) || 0, y: outside } : {}

    setChanges(key, { [param]: inside }, "quickAnimStartup", true, true)
    setChanges(key, position ? { ...pos } : outside, position ? "pos" : param, true, true)

}

const renderInput = (setChanges, keys, i, param, label = "", min = 0, max = 0, suffixLabel = "", prefixLabel = "", position = false, values, allowNegative = false, prefixIconClassname = "") => {

    return (
        <div className='row col-md-12 mb-3'>
            <div className="d-flex flex-wrap pl-0 align-self-start col-5">
                <FastField
                    className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row col-12 p-0"
                    inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2 col-3"
                    label={label}
                    name={keys[i] + `_end_${param}`}
                    onBlur={(v) => { calculateStartEnd(setChanges, keys[i], param, values, v, true, position) }}
                    type="number"
                    minValue={min}
                    maxValue={max}
                    component={NumberFormatInput}
                    thousandSeparator={false}
                    allowNegative={allowNegative}
                    light={true}
                    suffixLabel={suffixLabel}
                    prefixLabel={prefixLabel}
                    tooltip={`Min: ${min + 1} Max: ${max - 1}`}
                    prefixIconClassname={prefixIconClassname}
                />


            </div>
            <div className="d-flex flex-wrap pl-0 align-self-start col-5">
                <FastField
                    className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row col-12 p-0"
                    inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2 col-3"
                    label={label}
                    name={keys[i] + `_${param}`}
                    onBlur={(v) => { calculateStartEnd(setChanges, keys[i], param, values, v, false, position) }}
                    type="number"
                    minValue={min}
                    maxValue={max}
                    thousandSeparator={false}
                    component={NumberFormatInput}
                    allowNegative={allowNegative}
                    light={true}
                    suffixLabel={suffixLabel}
                    prefixLabel={prefixLabel}
                    tooltip={`Min: ${min + 1} Max: ${max - 1}`}
                    prefixIconClassname={prefixIconClassname}
                />
            </div>
        </div>
    )

}

const setDefaultValues = (value, sfv, setChanges, key) => {
    let param = value.toLowerCase()
    switch (param) {
        case "position":
            sfv(`${key}_posX`, 0)
            sfv(`${key}_end_posX`, 0)
            sfv(`${key}_posY`, 0)
            sfv(`${key}_end_posY`, 0)
            setChanges(key, { "posX": 0 }, "quickAnimStartup", true, true)
            setChanges(key, { "posY": 0 }, "quickAnimStartup", true, true)
            setChanges(key, { "x": 0 }, "pos", true, true)
            setChanges(key, { "y": 0 }, "pos", true, true)
            break;
        case "rotation":
            sfv(`${key}_rot`, 0)
            sfv(`${key}_end_rot`, 0)
            setChanges(key, { "rot": 0 }, "quickAnimStartup", true, true)
            setChanges(key, 0, "rot", true, true)
            break;
        case "scale":
            sfv(`${key}_scale`, 1)
            sfv(`${key}_end_scale`, 1)
            setChanges(key, { "scale": 1 }, "quickAnimStartup", true, true)
            setChanges(key, 1, "scale", true, true)
            break;
        case "opacity":
            sfv(`${key}_opacity`, 100)
            sfv(`${key}_end_opacity`, 100)
            setChanges(key, { "opacity": 100 }, "quickAnimStartup", true, true)
            setChanges(key, 100, "opacity", true, true)
            break;
        default:
            break;
    }
}

const getbitfield = (values, string = false) => {
    if (string) {
        return values.map(u => u.value)
    }
    else if (values && values.length === 1) {
        return values[0].value
    }
    else if (values && values.length === 2) {
        return values[0].value | values[1].value
    }
    else return ""
}

const renderLayer = (template, values, setChanges, keys, i, setFieldValue) => {
    let bitfields = values[`${keys[i]}_bitfield`] ? getbitfield(values[`${keys[i]}_bitfield`], true) : ''
    return (
        <div className="d-flex flex-column bb-text_small">

            <div className="d-flex  flex-wrap justify-content-start">
                <FastField
                    className="mb-1" containerClassName="row col-md-12 mb-5"
                    inputContainerClassName="col-sm-12 col-md-7 pl-0" labelClassName="col-sm-12 col-md-7 pl-0"
                    label="Properties to animate"
                    name={`${keys[i]}_bitfield`}
                    menuPlacement={"top"}
                    component={SelectInput}
                    bottomClassName="col-8 pl-0"
                    multiLimit={2}
                    options={template.assets[keys[i]].options}
                    onChange={(v, sfv, j, opt) => {
                        if (opt?.action === "remove-value" && opt?.removedValue) {
                            setDefaultValues(opt?.removedValue?.label, sfv, setChanges, keys[i])
                        }
                        setChanges(keys[i], { "bitfield": getbitfield(v) }, "quickAnimStartup", false, false)
                    }}
                    isMulti
                    bottomText={`You can add ${2 - values[`${keys[i]}_bitfield`]?.length || 0} more animation(s)`}
                />
                {bitfields ? <div className='row col-md-12 mb-3'>
                    <span className='col-5 d-flex flex-wrap pl-0 align-self-start bb-color_disabled'>Start Point</span>
                    <span className='col-5 d-flex flex-wrap pl-0 align-self-start bb-color_disabled'>End Point</span>

                </div> : null}

                {bitfields.includes('4') || bitfields.includes(4) ? renderInput(setChanges, keys, i, "posY", "Top", -601, 600, "px", "↧", true, values, true) : null}
                {bitfields.includes('4') || bitfields.includes(4) ? renderInput(setChanges, keys, i, "posX", "Left", -301, 300, "px", "↦", true, values, true) : null}
                {bitfields.includes('1') || bitfields.includes(1) ? renderInput(setChanges, keys, i, "scale", "Scale", -1, 11, "", "", false, values, false, "fas fa-expand-alt bb-font_12px") : null}
                {bitfields.includes('2') || bitfields.includes(2) ? renderInput(setChanges, keys, i, "rot", "Rotation", -361, 361, "°", "", false, values, true, "fas fa-redo-alt bb-font_12px") : null}
                {bitfields.includes('16') || bitfields.includes(16) ? renderInput(setChanges, keys, i, "opacity", "Opacity", -1, 101, "%", "", false, values, false, "fas fa-chess-board bb-font_12px") : null}


                <hr className='bb-form-line-divide_grey w-100' />
                <span className='w-100 mb-2 bb-color_disabled'>Time settings</span>
                <div className="row col-md-12 mb-3">
                    <div className="d-flex flex-wrap pl-0 align-self-start col-5 p-0">
                        <FastField
                            className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row col-12 p-0"
                            inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2 col-3"
                            label="Delay"
                            name={keys[i] + "_delay"}
                            onBlur={(v) => { 
                                let delay = Number(v)
                                let duration = Number(values[keys[i] + "_duration"])
                               
                                if(delay + duration > 6){
                                    duration = 6 - delay
                                    setFieldValue(keys[i] + "_duration", duration)
                                    setChanges(keys[i], { "duration": Number(duration) }, "quickAnimStartup", true, true)   
                                }
                                                              
                                setChanges(keys[i], { "delay": Number(v) }, "quickAnimStartup", true, true) 
                            
                            }}
                            type="number"
                            minValue={-1}
                            maxValue={6}
                            component={NumberFormatInput}
                            allowNegative={false}
                            light={true}
                            suffixLabel="s"
                            prefixLabel=""
                            tooltip={`Min: ${0} Max: ${5}`}
                        />
                    </div>
                    <div className="d-flex flex-wrap pl-0 align-self-start col-5 p-0">
                        <FastField
                            className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row col-12 p-0"
                            inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2 col-3"
                            label="Duration"
                            name={keys[i] + "_duration"}
                            onBlur={(v) => { 
                                
                                let duration = Number(v)
                                let delay = Number(values[keys[i] + "_delay"])

                                if(delay + duration > 6){
                                    delay = 6 - duration
                                    setFieldValue(keys[i] + "_delay", delay)
                                    setChanges(keys[i], { "delay": Number(delay) }, "quickAnimStartup", true, true)   
                                }
                                setChanges(keys[i], { "duration": Number(v) }, "quickAnimStartup", true, true) 
                            
                            }}
                            type="number"
                            minValue={0}
                            maxValue={7}
                            component={NumberFormatInput}
                            allowNegative={false}
                            light={true}
                            suffixLabel="s"
                            prefixLabel=""
                            tooltip={`Min: ${1} Max: ${6}`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}


export const renderForm = (template, setFieldValue, values, initPreview, inputChange, setChanges, isEdit = false, dimension, positionChange, userRole) => {
    var keys = Object.keys(template.assets)
    let hasCubeSettings = template.hasCubeSettings || false;
    let hasVideoSettings = template.hasVideoSettings && (userRole === SUPER_ADMIN || false);
    let hasAnimationSettings = template.hasAnimationSettings || false
    let hasAirtimeSettings = template.hasAirtimeSettings && (userRole === SUPER_ADMIN || false)
    return (
        <>
            <React.Fragment>
                {_.times(keys.length, i => {
                    if (template.assets[keys[i]].type === "image" || template.assets[keys[i]].type === "video") {
                        return (
                            <div key={i} className="col-12 d-flex flex-wrap pl-0 align-self-start">
                                <div className="w-100 d-flex flex-row flex-wrap">
                                    <MediaUpload
                                        hasVideoSettings={template.assets[keys[i]].hasVideoSettings}
                                        isEdit={isEdit}
                                        onRemoveChange={isEdit}
                                        aspectRatio={template.assets[keys[i]].aspectRatio}
                                        imageWithGif={template.assets[keys[i]].imageWithGif}
                                        strictSize={template.assets[keys[i]].maxFileSize || "65KB"}
                                        headName={template.assets[keys[i]].title}
                                        isStrictDimension={template.assets[keys[i]].restrict || false}
                                        isVideo={template.assets[keys[i]].type === "video" ? true : false}
                                        name={keys[i]} onChange={(v) => initPreview(v, keys[i], true)}
                                        isOptional={!template.assets[keys[i]].required}
                                        setFieldValue={setFieldValue} values={values}
                                        onBlurTextArea={(v, k) => inputChange(v, values, k)}
                                        impressionName={template.assets[keys[i]].hasThirdPartyTracker ? `${keys[i]}tpTracker` : false}
                                        landingName={template.assets[keys[i]].hasLandingPage ? `${keys[i]}lpUrl` : false}
                                        adWidth={template.assets[keys[i]].adWidth || dimension.adWidth}
                                        adHeight={template.assets[keys[i]].adHeight || dimension.adHeight} />
                                    {
                                        template.assets[keys[i]].hasPosition ? //&& values[keys[i]] ?
                                            <div className="d-flex flex-column bb-text_small">
                                                <Tooltip light={true}
                                                    tooltipClassName={"my-4"}
                                                    className={"cursor-pointer mb-1 pt-2 w-mx-content"} label={"Position"} htmlFor={""} iconname="fa-info-circle fa-1dot5x">
                                                    {renderDimensionalGuide(template.LeftToolTip, template.TopToolTip)}
                                                </Tooltip>
                                                <div className="d-flex flex-row justify-content-start">
                                                    <div className="d-flex flex-wrap pl-0 align-self-start">
                                                        <FastField
                                                            className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row"
                                                            inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2"
                                                            label={template.assets[keys[i] + "_x"].title}
                                                            name={keys[i] + "_x"}
                                                            onBlur={(v) => positionChange(keys[i], v, values)}
                                                            type="number"
                                                            minValue={template.assets[keys[i] + "_x"].min || false}
                                                            maxValue={template.assets[keys[i] + "_x"].max || false}
                                                            component={NumberFormatInput}
                                                            allowNegative={true}
                                                            tooltip={template.assets[keys[i] + "_x"].tooltip || false}
                                                            light={true}
                                                            suffixLabel="px"
                                                            prefixLabel="↦"
                                                        />
                                                    </div>
                                                    <div className="d-flex flex-wrap pl-0 align-self-start">
                                                        <FastField
                                                            className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row"
                                                            inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2"
                                                            label={template.assets[keys[i] + "_y"].title}
                                                            name={keys[i] + "_y"}
                                                            onBlur={(v) => positionChange(keys[i], v, values)}
                                                            type="number"
                                                            minValue={template.assets[keys[i] + "_y"].min || false}
                                                            maxValue={template.assets[keys[i] + "_y"].max || false}
                                                            component={NumberFormatInput}
                                                            allowNegative={true}
                                                            tooltip={template.assets[keys[i] + "_y"].tooltip || false}
                                                            light={true}
                                                            suffixLabel="px"
                                                            prefixLabel="↧"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {
                                        template.assets[keys[i]].isLayer ?
                                            renderLayer(template, values, setChanges, keys, i, setFieldValue)
                                            : null
                                    }


                                </div>
                                <hr className="creative-dot-yellow"></hr>
                            </div>)
                    }
                    else if (template.assets[keys[i]].type === "number") {
                        return (<div key={i} className="col-12 d-flex flex-wrap pl-0 align-self-start">
                            <FastField
                                className="mb-1" containerClassName="row col-md-12 mb-3"
                                inputContainerClassName="col-sm-12 col-md-4" labelClassName="mb-1 col-sm-12 col-md-3  p-0 pt-2"
                                label={template.assets[keys[i]].title}
                                name={keys[i]}
                                onBlur={(v) => inputChange(v, values, keys[i])}
                                type="number"
                                minValue={template.assets[keys[i]].min || false}
                                maxValue={template.assets[keys[i]].max || false}
                                component={NumberFormatInput}
                                allowNegative={true}
                                tooltip={template.assets[keys[i]].tooltip || false}
                                light={true}
                            />
                        </div>)
                    }
                    else if (template.assets[keys[i]].type === "color") {
                        return (<div key={i} className="col-12 d-flex flex-wrap pl-0 align-self-start mw-610px">

                            <FastField className="mb-1" containerClassName="row col-md-12 mb-3"
                                inputContainerClassName="col-sm-12 col-md-7" labelClassName="mb-1 col-5 p-0 pt-2"
                                label={template.assets[keys[i]].title}
                                name={keys[i]}
                                onChange={(v) => setChanges("settings", v, keys[i])}
                                placeholder="Hex value"
                                component={ColorInput} />
                        </div>
                        )
                    }
                    else if (template.assets[keys[i]].type === "dropDown") {
                        return (<div key={i} className="col-12 d-flex flex-wrap pl-0 align-self-start mw-610px">
                            <FastField
                                className="mb-1" containerClassName="row col-md-12 mb-3"
                                inputContainerClassName="col-sm-12 col-md-7" labelClassName="mb-1 p-0 pt-2 col-5"
                                label={template.assets[keys[i]].title}
                                name={keys[i]}
                                menuPlacement={"top"}
                                component={SelectInput}
                                options={template.assets[keys[i]].options}
                                onChange={(v) => setChanges("effect", v.value, "active", false)}

                            />

                        </div>
                        )
                    }


                    else {
                        return null;
                    }
                }
                )}


                {hasAnimationSettings ?
                    <Accordion>

                        <h4 className="bb-color-grey mb-3 col-12 pl-0">INTRO-ANIMATION</h4>
                        <div className="d-flex flex-column bb-text_small">
                            <div className="d-flex flex-row flex-wrap justify-content-start mb-3">
                                <div className="d-flex flex-wrap pl-0 align-self-center col-12 mb-2 bb-text_bold">
                                    Scaling
                                </div>
                                <div className="d-flex flex-wrap pl-0 align-self-start">
                                    <FastField
                                        className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row"
                                        inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2"
                                        name={"introAnimScale"}
                                        onBlur={(v) => {
                                            setChanges("settings", v, "introAnimScale", false, false)
                                        }}
                                        type="number"
                                        label="Scale"
                                        // suffixLabel="px"
                                        minValue={-100}
                                        maxValue={100}
                                        component={NumberFormatInput}
                                        allowNegative={true}
                                        light={true}
                                    />
                                </div>
                            </div>

                            <div className="d-flex flex-row flex-wrap justify-content-start mb-3">
                                <div className="d-flex flex-wrap pl-0 align-self-center col-12 mb-2 bb-text_bold">
                                    Position
                                </div>
                                <div className="d-flex flex-wrap pl-0 align-self-start col-4">
                                    <FastField
                                        className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row"
                                        inputContainerClassName="col-sm-12 col-md-8 d-flex pl-4" labelClassName="mb-1 p-0 pt-2"
                                        name={"introAnimPos_x"}
                                        onBlur={(v) => {
                                            setChanges("settings", { "x": values["introAnimPos_x"], "y": values["introAnimPos_y"], "z": values["introAnimPos_z"] }, "introAnimPos", false, false)
                                        }}
                                        type="number"
                                        minValue={-1000}
                                        maxValue={1000}
                                        component={NumberFormatInput}
                                        allowNegative={true}
                                        light={true}
                                        suffixLabel="px"
                                        label="Left"
                                        prefixLabel="↦"
                                    />
                                </div>
                                <div className="d-flex flex-wrap pl-0 align-self-start col-4">
                                    <FastField
                                        className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row"
                                        inputContainerClassName="col-sm-12 col-md-8 d-flex pl-4" labelClassName="mb-1 p-0 pt-2"
                                        // label={"Position"}
                                        name={"introAnimPos_y"}
                                        onBlur={(v) => {
                                            setChanges("settings", { "x": values["introAnimPos_x"], "y": values["introAnimPos_y"], "z": values["introAnimPos_z"] }, "introAnimPos", false, false)
                                        }}
                                        type="number"
                                        minValue={-1000}
                                        maxValue={1000}
                                        component={NumberFormatInput}
                                        allowNegative={true}
                                        light={true}
                                        suffixLabel="px"
                                        label="Top"
                                        prefixLabel="↧"
                                    />
                                </div>
                                <div className="d-flex flex-wrap pl-0 align-self-start col-4">
                                    <FastField
                                        className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row"
                                        inputContainerClassName="col-sm-12 col-md-8 d-flex pl-4" labelClassName="mb-1 p-0 pt-2"
                                        // label={"Position"}
                                        name={"introAnimPos_z"}
                                        onBlur={(v) => {
                                            setChanges("settings", { "x": values["introAnimPos_x"], "y": values["introAnimPos_y"], "z": values["introAnimPos_z"] }, "introAnimPos", false, false)
                                        }}
                                        type="number"
                                        minValue={-1000}
                                        maxValue={1000}
                                        component={NumberFormatInput}
                                        allowNegative={true}
                                        light={true}
                                        suffixLabel="px"
                                        prefixLabel="↦"
                                        prefixIconClassname="rotate-45deg"
                                        label="Depth"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row flex-wrap justify-content-start mb-3">
                                <div className="d-flex flex-wrap pl-0 align-self-center col-12 mb-2 bb-text_bold">
                                    Rotation
                                </div>
                                <div className="d-flex flex-wrap pl-0 align-self-start">
                                    <FastField
                                        className="mb-1 bb-text_small text-center" containerClassName="d-flex flex-row"
                                        inputContainerClassName="col-sm-12 col-md-6 d-flex" labelClassName="mb-1 p-0 pt-2"
                                        name={"introAnimRot"}
                                        onBlur={(v) => {
                                            setChanges("settings", v, "introAnimRot", false, false)
                                        }}
                                        type="number"
                                        minValue={-1000}
                                        maxValue={1000}
                                        component={NumberFormatInput}
                                        allowNegative={true}
                                        light={true}
                                        label="Angle"
                                        suffixLabel="°"
                                    />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                    : null

                }


                {hasVideoSettings ?
                    <>
                        {/* <hr className='bb-form-line-divide_grey w-100 mb-5' /> */}
                        <h4 className="bb-color-grey mb-3 col-12 pl-0 mt-4">VIDEO SETTINGS</h4>
                        <div className="col-12 mb-3 pl-0">

                            {_.times(keys.length, i => {
                                if (template.assets[keys[i]].type === "radio" && template.assets[keys[i]].settingsFor === "video") {
                                    return (
                                        <FastField key={keys[i] + i} name={keys[i]} className="toggle-status toggle-blue" label={template.assets[keys[i]].title}
                                            defaultChecked={false}
                                            containerClassName="form-row col-md-12 my-3 mx-610"
                                            inputContainerClassName="col-sm-12 col-md-7 pl-3"
                                            labelClassName="bb-form-input-label col-sm-12  col-md-5"
                                            component={FormToggle}
                                            isAdmin={true}
                                            //showPreview={(v) => initPreview(v, keys[i])}
                                            showPreview={(v) => setChanges("video", v[keys[i]], keys[i])}
                                            values={values}
                                        />
                                    )
                                }
                            })
                            }

                        </div>
                    </>
                    : null

                }
                {hasAirtimeSettings ?
                    <>
                        {/* <hr className='bb-form-line-divide_grey w-100 mb-5' /> */}
                        <h4 className="bb-color-grey mb-3 col-12 pl-0 mt-4">SETTINGS</h4>
                        <div className="col-12 mb-3 pl-0">
                            {_.times(keys.length, i => {
                                if (template.assets[keys[i]].type === "dropdown" && template.assets[keys[i]].settingsFor === "skip") {
                                    return (
                                        <FastField key={keys[i] + i} name={keys[i]} label={template.assets[keys[i]].title}

                                            containerClassName="row col-md-12 mb-3"
                                            inputContainerClassName="col-sm-12 col-md-7" labelClassName="mb-1 p-0 pt-2 col-5"
                                            component={SelectInput}
                                            isAdmin={true}
                                            options={template.assets[keys[i]].options}
                                            menuPlacement={"top"}
                                            onChange={(v) => { setChanges("settings", v?.value || 'skip.png', keys[i]) }}
                                        />
                                    )
                                }
                                else if (template.assets[keys[i]].type === "radio" && template.assets[keys[i]].settingsFor === "skip") {
                                    return (
                                        <FastField key={keys[i] + i} name={keys[i]} className="toggle-status toggle-blue" label={template.assets[keys[i]].title}
                                            defaultChecked={true}
                                            containerClassName="form-row col-md-12 my-3 mx-610"
                                            inputContainerClassName="col-sm-12 col-md-7 pl-3"
                                            labelClassName="bb-form-input-label col-sm-12  col-md-5"
                                            component={FormToggle}
                                            isAdmin={true}
                                            //showPreview={(v) => initPreview(v, keys[i])}
                                            showPreview={(v) => { let val = v[keys[i]] ? false : true; setChanges("settings", val, keys[i]) }}
                                            values={values}
                                        />
                                    )
                                }


                            }
                            )
                            }

                        </div>
                    </>
                    : null

                }

                {hasCubeSettings ?
                    <>
                        <hr className='bb-form-line-divide_grey w-100 mb-5' />
                        <h4 className="bb-color-grey mb-3 col-12 pl-0">CUBE SETTINGS</h4>
                        <div className="col-12 mb-3 pl-0">

                            {_.times(keys.length, i => {
                                if (template.assets[keys[i]].type === "radio" && template.assets[keys[i]].settingsFor === "cube") {
                                    return (
                                        <FastField key={keys[i] + i} name={keys[i]} className="toggle-status toggle-blue" label={template.assets[keys[i]].title}
                                            defaultChecked={true}
                                            containerClassName="form-row col-md-12 my-3 mx-610"
                                            inputContainerClassName="col-sm-12 col-md-7 pl-3"
                                            labelClassName="bb-form-input-label col-sm-12  col-md-5"
                                            component={FormToggle}
                                            //showPreview={(v) => initPreview(v, keys[i])}
                                            showPreview={(v) => inputChange(v[keys[i]], values, keys[i])}
                                            values={values}
                                        />
                                    )
                                }
                            })
                            }

                        </div>
                    </>
                    : null

                }
            </React.Fragment>

        </>
    );
}