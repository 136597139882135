import React from 'react'
import "../earnings/commercials.scss"



function FeesMonth(props) {
    let isAU = true;
    
    let data = [] //APEXTableData[0]
    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between header-container'>
                <div className='d-flex flex-wrap col-6'>
                    <strong><h5>{"Usage-Based SaaS Platform Fees Invoice" }</h5></strong>
                    <span>Billing Period</span>
                    {/* <span>Billing Period {`${formatBillingDate(data["start period"])} to ${formatBillingDate(data["end period"])}`}</span> */}
                    <span>Statement Reference - {data["invoiceNumber"]}</span>
                </div>
                <div className="position-relative">
                    <img className="bm-logo-c-deal-slider deal" src={`/images/budenterprise/budenterprise_independent.svg`}
                        alt="" />
                </div>
            </div>
        )
    }

    const renderdoubleInput = (label, input1 = "", input2 = "", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-4 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-8 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1 || 0}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 small text-right">{input2}</span> : null}
                </div>
            </div>

        )

    }

    const renderFourInput = (label, input1 = "", input2 = "", input3 = "", input4="", className = "") => {
        return (
            <div className={"d-flex flex-row flex-wrap p-0 col-12 justify-content-between " + className}>
                <label className="mb-1 bb-form-input-label col-4 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-8 row ">
                    <span className="form-control fake-input mb-2  mr-2 small text-right">{input1}</span>
                    {input2 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input2}</span> : null}
                    {input3 ? <span className=" form-control fake-input mb-2 mr-2 small text-right">{input3}</span> : null}
                    {input4 ? <span className=" form-control fake-input mb-2 small text-right">{input4}</span> : null}
                </div>
            </div>

        )

    }

    const renderInput = (label, input) => {
        return (
            <div className="d-flex flex-row flex-wrap p-0 col-12 justify-content-between">
                <label className="mb-1 bb-form-input-label col-4 text-right pt-2 bb-form-dsp-label">{label}</label>
                <div className="col-8 row">
                    <span className="form-control fake-input mb-2  small text-right">{input}</span>
                </div>
            </div>
        )
    }

    const fakeData = { liteImps: "0",
                        premiumImps: "0",
                       advancedImps: "0",
                       skinImps: "0",

                       totalSpend: "0",

                       liteSpend: "0",
                       premiumSpend: "0",
                       advancedSpend: "0",
                       skinSpend: "0",
                       totalFee: "0",
                       totalPercentage: "0",
                       totalFeeWithSupport: "0",
                       supportFee: "0"
    }
    return (
        <div className='fee'>
            {renderHeader()}
            <div className='d-flex col-12 flex-wrap mt-5'>
                <div className="d-flex flex-row flex-wrap p-0 col-12 justify-content-between">
                    <label className="mb-1 bb-form-input-label col-auto pt-2 bb-form-dsp-label"></label>
                    <div className="col-md-8 row ">
                        {/* {isAU ? <span className="mb-2  mr-2  bb-font_12px fake-input small">Lite Format @ ${'12.00'}</span> : null } */}                        
                        <span className="mb-2  mr-2  bb-font_12px fake-input small">Premium Format @ ${isAU ? '22.00' : '18.00'}</span>
                        <span className=" mb-2 mr-2 bb-font_12px fake-input small">Advanced Format @ ${isAU ? '25.00' : '21.00'}</span>                        
                        {/* {isAU ? <span className=" mb-2 bb-font_12px fake-input small">Skin Format @ ${'35.00'}</span> : null} */}
                    </div>
                </div>

                {/* {isAU ? renderFourInput("Ads IMPs Served",fakeData["liteImps"], fakeData["premiumImps"], fakeData["advancedImps"], fakeData["skinImps"])
                : renderdoubleInput("Ads IMPs Served",data["premiumImps"], data["advancedImps"])    
                }
                {isAU ? renderFourInput("Spend by Ad Format",fakeData["liteSpend"], fakeData["premiumSpend"], fakeData["advancedSpend"], fakeData["skinSpend"])
                : renderdoubleInput("Spend by Ad Format", `$${data["premiumSpend"]}`, `$${data["advancedSpend"]}`)} */}

                { renderdoubleInput("Ads IMPs Served", fakeData["premiumImps"], fakeData["advancedImps"])    
                }
                {renderdoubleInput("Spend by Ad Format", `$${fakeData["premiumSpend"]}`, `$${fakeData["advancedSpend"]}`)} 

                {renderInput("Billings Through Platform", `$${fakeData["totalSpend"]}`)}

                <div className='border d-flex flex-wrap mt-2'>
                    {renderdoubleInput("Big Ads Usage-Based Saas %", isAU ? fakeData["totalPercentage"] : data["totalPercentage"]) }
                    {renderdoubleInput("Big Ads Usage-Based Saas Fees", `$${ isAU ? fakeData["totalFee"] : data["totalFee"]}`)}
                    {renderdoubleInput("Support - Foundation Package", `$${fakeData["supportFee"]}`)}
                    {renderdoubleInput("Total Big Ads Usage-Based Saas Fees", `$${isAU ? fakeData["totalFeeWithSupport"] : data["totalFeeWithSupport"]}`, false, "fee-grey")}
                </div> 

              {/*   <div className="d-flex flex-row justify-content-end w-100 m-3 ">
                    <button type="button" onClick={() => { pushLocation("/fees") }} className="bb-button" disabled={false}>Close</button>
                </div> */}

            </div>


        </div>
    )
}

export default (FeesMonth);
